import { Interaction } from "../../../interactions/domain/interaction";
import ButtonNeoGen from "../../../layout/button-neogen";
import ModalDialog from "../../../layout/modal-dialog";
import { NotesTable } from "./notesTable";

const handleOpenLink = (route: string) => {
    const newTab = window.open("", "_blank");
    // @ts-ignore
    newTab.location.href = route;
};

export const CompanyNotesModal = ({
    companyName,
    companyId,
    close,
    notes,
}: {
    companyName: string;
    companyId: number;
    close: () => void;
    notes: Interaction[];
}) => {
    return (
        <ModalDialog
            size="xl"
            showCancel={false}
            showOk={false}
            title={`Notes assigned to you in ${companyName}`}
            close={close}
            show={true}
        >
            <NotesTable notes={notes} />
            <div className="w-full flex justify-end">
                <ButtonNeoGen onClick={() => handleOpenLink(`/companies/${companyId}?tab=notes`)}>
                    View company notes
                </ButtonNeoGen>
            </div>
        </ModalDialog>
    );
};
