/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { DocumentPlusIcon, FolderIcon } from "@heroicons/react/24/outline";

const projects = [
    { id: 1, name: "Workflow Inc. / Website Redesign", url: "#" },
    // More projects...
];
const recent = [projects[0]];
type QuickAction = {
    name: string;
    icon?: any;
    shortcut?: string;
    url?: string;
};

const quickActions: QuickAction[] = [
    { name: "Accepted file", icon: DocumentPlusIcon },
    { name: "In progress", icon: DocumentPlusIcon },
    { name: "Reject files", icon: DocumentPlusIcon },
    { name: "Incomplete", icon: DocumentPlusIcon },
    { name: "Complete for review", icon: DocumentPlusIcon },
    { name: "Accepted by Clear ERC", icon: DocumentPlusIcon },
];

function classNames(...classes: (string | boolean)[]) {
    return classes.filter(Boolean).join(" ");
}

type SelectStageProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    saveStage: (stage: string) => void;
    stage?: string | null;
};

export default function SelectStageCPA({ open, setOpen, stage, saveStage }: SelectStageProps) {
    const [query, setQuery] = useState("");

    const filteredProjects =
        query === ""
            ? []
            : projects.filter((project) => {
                  return project.name.toLowerCase().includes(query.toLowerCase());
              });
    if (!open) return null;
    return (
        <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery("")} appear>
            <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-500 divide-opacity-10 overflow-hidden rounded-xl bg-white bg-opacity-80 shadow-2xl ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter transition-all">
                            <Combobox onChange={(item: any) => saveStage(item.name)}>
                                <div className="relative">
                                    <MagnifyingGlassIcon
                                        className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-900 text-opacity-40"
                                        aria-hidden="true"
                                    />
                                    <Combobox.Input
                                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 focus:ring-0 sm:text-sm"
                                        placeholder="Search..."
                                        onChange={(event) => setQuery(event.target.value)}
                                    />
                                </div>

                                {(query === "" || filteredProjects.length > 0) && (
                                    <Combobox.Options
                                        static
                                        className="max-h-80 scroll-py-2 divide-y divide-gray-500 divide-opacity-10 overflow-y-auto"
                                    >
                                        {query === "" && (
                                            <li className="p-2">
                                                <h2 className="sr-only">Quick actions</h2>
                                                <ul className="text-sm text-gray-700">
                                                    {quickActions.map((action, idx) => (
                                                        <Combobox.Option
                                                            key={idx}
                                                            value={action}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    "flex cursor-default select-none items-center rounded-md px-3 py-2",
                                                                    active && "bg-gray-900 bg-opacity-5 text-gray-900",
                                                                )
                                                            }
                                                        >
                                                            {({ active }) => (
                                                                <>
                                                                    <action.icon
                                                                        className={classNames(
                                                                            "h-6 w-6 flex-none text-gray-900 text-opacity-40",
                                                                            active && "text-opacity-100",
                                                                        )}
                                                                        aria-hidden="true"
                                                                    />
                                                                    <span className="ml-3 flex-auto truncate">
                                                                        {action.name ?? ""}
                                                                    </span>
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    ))}
                                                </ul>
                                            </li>
                                        )}
                                    </Combobox.Options>
                                )}

                                {query !== "" && filteredProjects.length === 0 && (
                                    <div className="px-6 py-14 text-center sm:px-14">
                                        <FolderIcon
                                            className="mx-auto h-6 w-6 text-gray-900 text-opacity-40"
                                            aria-hidden="true"
                                        />
                                        <p className="mt-4 text-sm text-gray-900">
                                            We couldn&apos;t find any projects with that term. Please try again.
                                        </p>
                                    </div>
                                )}
                            </Combobox>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
