import { ClockIcon, CheckIcon, ArrowRightIcon, PaperClipIcon, LockClosedIcon } from "@heroicons/react/24/outline";

export const CPAStats = ({ companies }: { companies: any }) => {
    const stats = [
        {
            id: 1,
            color: "bg-[#61baf3] dark:bg-teal-700",
            name: "New file",
            stat: (companies || []).filter((c: any) => c?.processflowProgress?.status === "Accepted file").length,
            icon: PaperClipIcon,
        },
        {
            id: 2,
            color: "bg-[#ed9609] dark:bg-orange-700",
            name: "In progress",
            stat: (companies || []).filter((c: any) => c?.processflowProgress?.status === "In progress").length,
            icon: ClockIcon,
        },
        {
            id: 3,
            color: "bg-[#ed411f] dark:bg-green-700",
            name: "Rejected file",
            stat: (companies || []).filter((c: any) => c?.processflowProgress?.status === "Reject files").length,
            icon: LockClosedIcon,
        },
        {
            id: 4,
            color: "bg-[#ed411f] dark:bg-red-700",
            name: "Incomplete",
            stat: (companies || []).filter((c: any) => c?.processflowProgress?.status === "Incomplete").length,
            icon: ClockIcon,
        },
        {
            id: 4,
            color: "bg-[#9ee058] dark:bg-red-700",
            name: "Completed",
            stat: (companies || []).filter((c: any) => c?.processflowProgress?.status === "Complete for review").length,
            icon: CheckIcon,
        },
        {
            id: 4,
            color: "bg-[#9ee058] dark:bg-red-700",
            name: "Accepted by Clear ERC",
            stat: (companies || []).filter((c: any) => c?.processflowProgress?.status === "Accepted by Clear ERC")
                .length,
            icon: CheckIcon,
        },
    ];
    return (
        <div>
            <dl className="my-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
                {stats.map((item) => (
                    <div
                        key={item.id}
                        className="
            relative overflow-hidden bg-gradient-to-br
            to-white from-slate-100 dark:from-slate-900 dark:to-gray-800
            border-2 border-white rounded-xl p-4 dark:bg-gray-800
            dark:border-gray-700 dark:shadow-inner px-5 shadow
            hover:shadow-none
            hover:to-slate-200 dark:hover:to-slate-700
            hover:from-slate-100 dark:hover:from-slate-700
            transition duration-200 ease-in-out
            "
                    >
                        <dt>
                            <div className={"absolute rounded-md " + item.color + " p-3"}>
                                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 truncate text-sm font-medium text-gray-400">{item.name}</p>
                        </dt>
                        <dd className="ml-16 flex items-baseline">
                            <p className="text-2xl font-semibold text-gray-500 dark:text-green-500">{item.stat}</p>
                        </dd>
                    </div>
                ))}
            </dl>
        </div>
    );
};
