import { orderBy } from "lodash";

export const states = orderBy(
    [
        {
            id: 1,
            name: "New York",
            code: "NY",
        },
        {
            id: 2,
            name: "California",
            code: "CA",
        },
        {
            id: 3,
            name: "Illinois",
            code: "IL",
        },
        {
            id: 4,
            name: "Florida",
            code: "FL",
        },
        {
            id: 5,
            name: "Texas",
            code: "TX",
        },
        {
            id: 6,
            name: "Pennsylvania",
            code: "PA",
        },
        {
            id: 7,
            name: "Georgia",
            code: "GA",
        },
        {
            id: 8,
            name: "District of Columbia",
            code: "DC",
        },
        {
            id: 9,
            name: "Massachusetts",
            code: "MA",
        },
        {
            id: 10,
            name: "Arizona",
            code: "AZ",
        },
        {
            id: 11,
            name: "Michigan",
            code: "MI",
        },
        {
            id: 12,
            name: "Washington",
            code: "WA",
        },
        {
            id: 13,
            name: "Minnesota",
            code: "MN",
        },
        {
            id: 14,
            name: "Colorado",
            code: "CO",
        },
        {
            id: 15,
            name: "Nevada",
            code: "NV",
        },
        {
            id: 16,
            name: "Maryland",
            code: "MD",
        },
        {
            id: 17,
            name: "Missouri",
            code: "MO",
        },
        {
            id: 18,
            name: "Oregon",
            code: "OR",
        },
        {
            id: 19,
            name: "Puerto Rico",
            code: "PR",
        },
        {
            id: 20,
            name: "Indiana",
            code: "IN",
        },
        {
            id: 21,
            name: "Ohio",
            code: "OH",
        },
        {
            id: 22,
            name: "Virginia",
            code: "VA",
        },
        {
            id: 23,
            name: "North Carolina",
            code: "NC",
        },
        {
            id: 24,
            name: "Wisconsin",
            code: "WI",
        },
        {
            id: 25,
            name: "Rhode Island",
            code: "RI",
        },
        {
            id: 26,
            name: "Tennessee",
            code: "TN",
        },
        {
            id: 27,
            name: "Utah",
            code: "UT",
        },
        {
            id: 28,
            name: "Oklahoma",
            code: "OK",
        },
        {
            id: 29,
            name: "Connecticut",
            code: "CT",
        },
        {
            id: 30,
            name: "Kentucky",
            code: "KY",
        },
        {
            id: 31,
            name: "Louisiana",
            code: "LA",
        },
        {
            id: 32,
            name: "Nebraska",
            code: "NE",
        },
        {
            id: 33,
            name: "Alabama",
            code: "AL",
        },
        {
            id: 34,
            name: "New Mexico",
            code: "NM",
        },
        {
            id: 35,
            name: "South Carolina",
            code: "SC",
        },
        {
            id: 36,
            name: "Iowa",
            code: "IA",
        },
        {
            id: 37,
            name: "Kansas",
            code: "KS",
        },
        {
            id: 38,
            name: "Arkansas",
            code: "AR",
        },
        {
            id: 39,
            name: "Idaho",
            code: "ID",
        },
        {
            id: 40,
            name: "New Jersey",
            code: "NJ",
        },
        {
            id: 41,
            name: "Hawaii",
            code: "HI",
        },
        {
            id: 42,
            name: "Mississippi",
            code: "MS",
        },
        {
            id: 43,
            name: "Alaska",
            code: "AK",
        },
        {
            id: 44,
            name: "New Hampshire",
            code: "NH",
        },
        {
            id: 45,
            name: "North Dakota",
            code: "ND",
        },
        {
            id: 46,
            name: "Maine",
            code: "ME",
        },
        {
            id: 47,
            name: "South Dakota",
            code: "SD",
        },
        {
            id: 48,
            name: "West Virginia",
            code: "WV",
        },
        {
            id: 49,
            name: "Montana",
            code: "MT",
        },
        {
            id: 50,
            name: "Delaware",
            code: "DE",
        },
        {
            id: 51,
            name: "Vermont",
            code: "VT",
        },
        {
            id: 52,
            name: "Wyoming",
            code: "WY",
        },
    ],
    (s) => s.name.toLowerCase(),
);
