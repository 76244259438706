import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { paymentSchema } from "../domain/payment";

export const getPayment = async ({ authToken, id }: { authToken: string; id: string }) => {
    const response = await makeRequest({
        method: "get",
        path: `/payments/${id}`,
        authToken,
    });
    const payment = parseSchema(paymentSchema, response.data);
    return payment;
};
