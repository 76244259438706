import { useState } from "react";
import ButtonNeoGen from "../../layout/button-neogen";
import ModalDialog from "../../layout/modal-dialog";
import SureAdvisorLogo from "../pages/sure-advisors-logo.png";

export const SignContractsModal = ({ onClose }: { onClose: () => void }) => {
    const [steps, setSteps] = useState([
        { id: "Step 1", name: "Sign Up", href: "#", status: "current" },
        { id: "Step 2", name: "Affiliate Contract", href: "#", status: "upcoming" },
        { id: "Step 3", name: "W9 Form", href: "#", status: "upcoming" },
    ]);

    return (
        <ModalDialog
            showCancel={false}
            size="xl"
            showOk={false}
            title=""
            show={true}
            close={() => onClose()}
            hideTopRightCloseButton={true}
        >
            <nav aria-label="Progress">
                <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
                    {steps.map((step) => (
                        <li key={step.name} className="md:flex-1">
                            {step.status === "complete" ? (
                                <a
                                    href={step.href}
                                    className="group flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                >
                                    <span className="text-sm font-medium text-indigo-600 group-hover:text-indigo-800">
                                        {step.id}
                                    </span>
                                    <span className="text-sm font-medium">{step.name}</span>
                                </a>
                            ) : step.status === "current" ? (
                                <a
                                    href={step.href}
                                    aria-current="step"
                                    className="flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                >
                                    <span className="text-sm font-medium text-indigo-600">{step.id}</span>
                                    <span className="text-sm font-medium">{step.name}</span>
                                </a>
                            ) : (
                                <a
                                    href={step.href}
                                    className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                >
                                    <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                                        {step.id}
                                    </span>
                                    <span className="text-sm font-medium">{step.name}</span>
                                </a>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>

            {steps[0].status === "current" ? (
                <div className="text-center mt-5">
                    <img src={SureAdvisorLogo} width={200} style={{ margin: "auto", borderRadius: 10 }} />
                    <h2 className="mt-4">Welcome to the SureAdvisor Affiliate Program</h2>

                    <p className="w-2/3 m-auto mt-4">
                        In the next two steps, you will be required to sign our SureAdvisor Affiliate Agreement, and
                        well as a W9 form. You can skip this step for now, but your access to the affiliate platform
                        will be limited until you complete these steps.
                    </p>
                    <div className="mt-12 flex gap-3 justify-end">
                        <ButtonNeoGen type="outline-primary" onClick={() => onClose()}>
                            Skip for now
                        </ButtonNeoGen>
                        <ButtonNeoGen
                            onClick={() => {
                                const newSteps = [
                                    { id: "Step 1", name: "Sign Up", href: "#", status: "complete" },
                                    { id: "Step 2", name: "Affiliate Contract", href: "#", status: "current" },
                                    { id: "Step 3", name: "W9 Form", href: "#", status: "upcoming" },
                                ];
                                setSteps(newSteps);
                            }}
                        >
                            Next
                        </ButtonNeoGen>
                    </div>
                </div>
            ) : steps[1].status === "current" ? (
                <div className="text-center mt-5">
                    <img src={SureAdvisorLogo} width={200} style={{ margin: "auto", borderRadius: 10 }} />
                    <h2 className="mt-4">Affiliate Agreement</h2>

                    <p className="w-2/3 m-auto mt-4">Contract Here</p>
                    <div className="mt-12 flex gap-3 justify-end">
                        <ButtonNeoGen type="outline-primary" onClick={() => onClose()}>
                            Skip for now
                        </ButtonNeoGen>
                        <ButtonNeoGen
                            onClick={() => {
                                const newSteps = [
                                    { id: "Step 1", name: "Sign Up", href: "#", status: "complete" },
                                    { id: "Step 2", name: "Affiliate Contract", href: "#", status: "complete" },
                                    { id: "Step 3", name: "W9 Form", href: "#", status: "current" },
                                ];
                                setSteps(newSteps);
                            }}
                        >
                            Next
                        </ButtonNeoGen>
                    </div>
                </div>
            ) : (
                <div className="text-center mt-5">
                    <img src={SureAdvisorLogo} width={200} style={{ margin: "auto", borderRadius: 10 }} />
                    <h2 className="mt-4">W9 Form</h2>

                    <p className="w-2/3 m-auto mt-4">W9 Here</p>
                    <div className="mt-12 flex gap-3 justify-end">
                        <ButtonNeoGen type="outline-primary" onClick={() => onClose()}>
                            Skip for now
                        </ButtonNeoGen>
                        <ButtonNeoGen
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Finish
                        </ButtonNeoGen>
                    </div>
                </div>
            )}
        </ModalDialog>
    );
};
