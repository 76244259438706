import SignatureCanvas from "react-signature-canvas";
import "./affiliate-contracts.css";
import ButtonNeoGen from "../../layout/button-neogen";
import { useEffect, useRef, useState } from "react";
import { usePromise } from "../../jason-proof-of-concept/shared/hooks";
import { getAffiliateContract } from "../actions/get-affiliate-contract";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { format } from "date-fns";
import { useMutation } from "@tanstack/react-query";
import { AffiliateContract } from "../domain/affiliate-contract";
import { updateAffiliateContract } from "../actions/update-affiliate-contract";
import userAtom from "../../atoms/userAtom";
import { useRecoilState } from "recoil";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import ReactDOM from "react-dom";

const auditTrail = (contract: any) => {
    if (!contract) return [];
    const auditTrail = [];

    auditTrail.push({ date: contract.createdAt, log: "Contract created" });
    if (contract.userSignedContractAt) {
        auditTrail.push({
            date: contract.userSignedContractAt,
            log: "Contract signed by affiliate",
        });
    }

    if (contract.userEmailedSignedContractAt) {
        auditTrail.push({
            date: contract.userEmailedSignedContractAt,
            log: "Contract emailed to affiliate",
        });
    }

    if (contract.clearErcUserEmailedSignedContractAt) {
        auditTrail.push({
            date: contract.clearErcUserEmailedSignedContractAt,
            log: "Contract emailed to ClearERC",
        });
    }

    return auditTrail.sort((a, b) => b.date - a.date);
};

export const AffiliateContractsPage = ({
    setShowContractBlocking,
}: {
    setShowContractBlocking: (b: boolean) => void;
}) => {
    const authToken = getAuthTokenNoThrow();
    const [user] = useRecoilState<ClearERCUser>(userAtom);
    const contractQuery = usePromise(async () => {
        const contract = await getAffiliateContract({
            authToken,
            userId: user.id || "",
        });
        return contract;
    }, []);

    const affiliateContract = contractQuery.result;
    const [showSignatureCanvas, setShowSignatureCanvas] = useState(!affiliateContract?.userSignedContractAt);

    useEffect(() => {
        setShowSignatureCanvas(!affiliateContract?.userSignedContractAt);
    }, [affiliateContract]);

    const signatureCanvasRef = useRef(null);

    const updateAffiliateContractMutation = useMutation({
        mutationFn: async ({
            authToken,
            id,
            data,
        }: {
            authToken?: string;
            id?: string;
            data: Partial<AffiliateContract>;
        }) => {
            return await updateAffiliateContract({ authToken, id, data });
        },
    });

    const HeaderComponent = (
        <div className="mb-3 mt-8">
            <h3 className="font-bold">Affiliate</h3>
        </div>
    );

    const NameComponent = (
        <div className="my-3">
            <p className="font-bold">
                Name: {user.firstName} {user.lastName}
            </p>
            <p className="font-bold">Email: {user.email}</p>
        </div>
    );

    const handleCaptureSignature = async () => {
        const now = new Date();
        // @ts-ignore
        const signatureDataUrl = signatureCanvasRef.current.toDataURL();
        const img = new Image();
        img.src = signatureDataUrl;

        const headerContainer = document.createElement("div");
        ReactDOM.render(HeaderComponent, headerContainer);

        const nameContainer = document.createElement("div");
        ReactDOM.render(NameComponent, nameContainer);

        const contractElement = document.getElementById("contract");
        if (contractElement) {
            while (headerContainer.firstChild) {
                contractElement.appendChild(headerContainer.firstChild);
            }
        }

        contractElement?.appendChild(img);

        if (contractElement) {
            while (nameContainer.firstChild) {
                contractElement.appendChild(nameContainer.firstChild);
            }
        }

        setShowSignatureCanvas(false);

        const c = document.getElementById("contract");
        const htmlString = c?.outerHTML;

        const contract = await updateAffiliateContractMutation.mutateAsync({
            authToken,
            id: affiliateContract?.id,
            data: {
                userSignedContractAt: now,
                contract: htmlString,
            },
        });

        contractQuery.execute();

        setShowContractBlocking(false);
    };

    const [isEmpty, setIsEmpty] = useState(true);
    return (
        <>
            {!affiliateContract?.contract || affiliateContract.contract?.length === 0 ? (
                <div>Error fetching contract</div>
            ) : (
                <>
                    <div
                        id="contract"
                        dangerouslySetInnerHTML={{ __html: affiliateContract?.contract?.replace(/^"|"$/g, "") }}
                    />
                    {showSignatureCanvas && (
                        <>
                            <div className="mt-4">
                                <h2>Your signature</h2>
                                <div className="border-gray-500 w-[500px] border-2">
                                    <SignatureCanvas
                                        ref={signatureCanvasRef}
                                        penColor="black"
                                        canvasProps={{ width: 500, height: 200, className: "signature-canvas" }}
                                        onBegin={() => setIsEmpty(false)}
                                    />
                                </div>
                                <ButtonNeoGen
                                    type="outline"
                                    className="mr-2"
                                    onClick={() => {
                                        // @ts-ignore
                                        signatureCanvasRef.current?.clear();
                                        setIsEmpty(true);
                                    }}
                                >
                                    Clear
                                </ButtonNeoGen>
                                <ButtonNeoGen
                                    onClick={handleCaptureSignature}
                                    // @ts-ignore
                                    disabled={isEmpty}
                                    className="mt-2"
                                >
                                    Save signature
                                </ButtonNeoGen>
                            </div>
                        </>
                    )}

                    <div className="mt-3">
                        <h2>Audit trail</h2>
                        <ol>
                            {auditTrail(affiliateContract)?.map((item, index) => (
                                <li key={index}>
                                    <span>{format(new Date(item.date), "dd/LL/yyyy kk:mm")}</span>:{" "}
                                    <span>{item.log}</span>
                                </li>
                            ))}
                        </ol>
                    </div>
                </>
            )}
        </>
    );
};
