import { BuildingOfficeIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useQuery } from "@tanstack/react-query";
import { NavLink } from "react-router-dom";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import authService from "../../services/auth.service";
import utmService from "../../services/utm-link.service";
import Loader2 from "../utilities/Loader2";
import ButtonNeoGen from "../../layout/button-neogen";
import Swal from "sweetalert2";
import ModalDialog from "../../layout/modal-dialog";
import { UTMLink } from "../../typings/api/utm-links";
import SwalNeogenFire from "../../layout/swal-neogen";
import SelectNeoGen from "../../layout/select-neogen";
import { AffiliateContractsPage } from "../../affiliate-contracts/pages/affiliate-contracts-page";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { getAffiliateContract } from "../../affiliate-contracts/actions/get-affiliate-contract";
import { usePromise } from "../../jason-proof-of-concept/shared/hooks";
import { RoleGroup } from "../../role-groups/domain/role-group";
import { Select } from "../../layout/form/select-input";
import PrintPre from "../../layout/print-pre";
import packageJson from "../../../package.json";
import OptionsDropDown from "../../layout/options-dropdown";
export default function DashboardPageHeader({
    darkMode,
    userRoles,
    activeRole,
    setActiveRole,
}: {
    darkMode?: boolean;
    userRoles?: any;
    activeRole?: undefined | null | RoleGroup;
    setActiveRole: (r: RoleGroup) => void;
}) {
    const today = new Date().getHours();
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);
    const [showLeadsModal, setShowLeadsModal] = useState(false);
    const [utmLink, setUtmLink] = useState<UTMLink | undefined>(undefined);
    const [showContractModal, setShowContractModal] = useState(false);
    const [showContractBlocking, setShowContractBlocking] = useState(false);
    const authToken = getAuthTokenNoThrow();
    const isAffiliate = !!user.roleGroups?.find((rg) => rg.name === "Affiliate");
    const contractQuery = usePromise(async () => {
        if (!isAffiliate) return;
        const contract = await getAffiliateContract({
            authToken,
            userId: user.id || "",
        });
        return contract;
    }, []);
    const url = "/processflow-wizard/7";

    const affiliateContract = contractQuery.result;

    useEffect(() => {
        if (isAffiliate) {
            if (affiliateContract && !affiliateContract?.userSignedContractAt) {
                setShowContractBlocking(true);
                setShowContractModal(true);
            } else {
                setShowContractBlocking(false);
            }
        }
    }, [affiliateContract, isAffiliate]);

    // const getData = () => {
    //     fetch("/meta.json", {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Accept: "application/json",
    //         },
    //     })
    //         .then(function (response) {
    //             return response.json();
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         })
    //         .then(function (myJson) {
    //             setMeta(myJson);
    //         });
    // };
    // useEffect(() => {
    //     getData();
    // }, []);

    const utmQuery = useQuery(["utmLinks"], async () => {
        const response = await utmService.getAll();
        if (response) {
            return response.data;
        }
    });

    let greeting;

    if (today < 12) {
        greeting = "Good morning";
    } else if (today < 18) {
        greeting = "Good afternoon";
    } else {
        greeting = "Good evening";
    }

    return (
        <>
            <div className="bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl  dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner min-h-32 grid grid-cols-1 content-center shadow">
                <div className="px-3 my-auto">
                    <div className="py-2 md:flex gap-5 md:items-center md:justify-between ">
                        <div className="flex-1 min-w-0 self-start my-auto ml-0 pl-0.5">
                            <div className="flex items-center">
                                <div className="hidden h-12 w-12 mr-5 flex-shrink-0 sm:block pt-0 pl-0.5">
                                    <Avatar
                                        size="3rem"
                                        color={"#4338ca"}
                                        name={user.firstName as string}
                                        round={true}
                                        email={user.email}
                                    />
                                </div>
                                <div className="">
                                    <div className="flex items-center ">
                                        <h1 className="ml-0 text-xl font-light leading-7 text-gray-700 dark:text-gray-200 tracking-wide  sm:leading-9 sm:truncate -mt-1">
                                            {greeting}, {user.firstName}
                                        </h1>
                                    </div>
                                    <dl className="mt-2 flex flex-col sm:ml-0 sm:mt-1 sm:flex-row sm:flex-wrap">
                                        {user && (
                                            <>
                                                <dt className="sr-only">Your name</dt>
                                                <dd className="flex <PrintPre>items-center text-sm text-gray-500 font-medium capitalize sm:mr-6 mt-0">
                                                    <BuildingOfficeIcon
                                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                    {user.name || "No company"}
                                                </dd>
                                            </>
                                        )}

                                        <dt className="sr-only">System Version</dt>
                                        <dd className="mt-0 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize pt-0">
                                            <CheckCircleIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                                aria-hidden="true"
                                            />
                                            <span>Version {packageJson?.version}</span>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div className="my-auto self-end min-w-9 flex flex-col basis-1/8">
                            <div className="my-auto text-left md:mt-0 ">
                                <div className="static block my-1 mr-0 overflow-visible  ">
                                    {activeRole?.name === "SureAdvisor Affiliate" && (
                                        <div className="flex justify-end">
                                            <OptionsDropDown
                                                options={[
                                                    {
                                                        label: "View Affiliate Contract",
                                                        action: () => {
                                                            console.log("View Affiliate Contract");
                                                        },
                                                    },
                                                    {
                                                        label: "View W9 Form",
                                                        action: () => {
                                                            console.log("View W9 Form");
                                                        },
                                                    },
                                                    {
                                                        label: "View Training",
                                                        action: () => {
                                                            console.log("View Training");
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    )}
                                    {activeRole?.name === "Affiliate" ? (
                                        <div>
                                            {utmQuery.isLoading ? (
                                                <Loader2 />
                                            ) : (
                                                <div className="mr-0">
                                                    <div className="flex flex-row-reverse gap-5 w-full  h-full flex-shrink mr-5">
                                                        <ButtonNeoGen
                                                            disabled={!utmLink}
                                                            className="mt-auto"
                                                            size="sm"
                                                            type="info"
                                                            onClick={() => {
                                                                navigator.clipboard
                                                                    .writeText(
                                                                        (window.location.hostname ===
                                                                        "login.clearerc.com"
                                                                            ? "https://login.clearerc.com/sign-up/9/"
                                                                            : "https://login.ercrepair.com/sign-up/27/") +
                                                                            utmLink?.code,
                                                                    )
                                                                    .catch((err) => {
                                                                        alert(err);
                                                                    })
                                                                    .then(() => {
                                                                        Swal.fire({
                                                                            title: "Copied!",
                                                                            html:
                                                                                "iFrame Link:<br /><br /> <b class='text-blue-500'>" +
                                                                                (window.location.hostname ===
                                                                                "login.clearerc.com"
                                                                                    ? "https://login.clearerc.com/sign-up/9/"
                                                                                    : "https://login.ercrepair.com/sign-up/27/") +
                                                                                utmLink?.code +
                                                                                "</b><br /> <br />  Copied to Clipboard",
                                                                            icon: "success",
                                                                            showCancelButton: false,
                                                                            showConfirmButton: false,
                                                                            timer: 2000,
                                                                        });
                                                                    });
                                                            }}
                                                        >
                                                            <span className="fal fa-copy mr-3"></span>
                                                            Copy iFrame Link
                                                        </ButtonNeoGen>
                                                        <ButtonNeoGen
                                                            disabled={!utmLink}
                                                            className={"mt-auto w-full!"}
                                                            size="sm"
                                                            type="secondary"
                                                            onClick={() => {
                                                                navigator.clipboard
                                                                    .writeText(
                                                                        (window.location.hostname ===
                                                                        "login.clearerc.com"
                                                                            ? "https://www.clearerc.com/refund/?utm="
                                                                            : "https://www.ercrepair.com/repair/?utm=") +
                                                                            utmLink?.code,
                                                                    )
                                                                    .catch((err) => {
                                                                        alert(err);
                                                                    })
                                                                    .then(() => {
                                                                        SwalNeogenFire({
                                                                            darkMode: true,
                                                                            title: "Copied!",
                                                                            html:
                                                                                "Landing Page Link:<br /><br /> <b class='text-blue-500'>" +
                                                                                (window.location.hostname ===
                                                                                "login.clearerc.com"
                                                                                    ? "https://www.clearerc.com/refund/?utm="
                                                                                    : "https://www.ercrepair.com/repair/?utm=") +
                                                                                utmLink?.code +
                                                                                "</b><br /> <br />  Copied to Clipboard",
                                                                            icon: "success",
                                                                            showCancelButton: false,
                                                                            showConfirmButton: false,
                                                                            timer: 4000,
                                                                        });
                                                                    });
                                                            }}
                                                        >
                                                            <span className="fal fa-copy mr-3"></span>
                                                            Copy Signup Link
                                                        </ButtonNeoGen>
                                                        <SelectNeoGen
                                                            className="py-auto"
                                                            placeholder="Select UTM Code"
                                                            options={utmQuery.data?.map((utm: UTMLink) => ({
                                                                name:
                                                                    utm.name ??
                                                                    "..." + utm.code?.substring(utm.code.length - 16),
                                                                id: utm.id,
                                                            }))}
                                                            onChange={(e: any) => {
                                                                setUtmLink(utmQuery?.data?.find((q) => q.id === e));
                                                            }}
                                                            value={utmLink?.id ?? ""}
                                                        />
                                                    </div>
                                                    <div className="flex flex-row-reverse mt-5 gap-5 text-right">
                                                        <ButtonNeoGen
                                                            onClick={() => setShowLeadsModal(true)}
                                                            type="primary"
                                                            className="break-inside-avoid break-inside-avoid-colum break-before-all"
                                                        >
                                                            <span className="fa-solid fa-comments-dollar mr-3 "></span>
                                                            <span className="break-inside-avoid min-w-max">
                                                                My Leads
                                                            </span>
                                                        </ButtonNeoGen>

                                                        <ButtonNeoGen
                                                            onClick={() => {
                                                                contractQuery.execute();
                                                                setShowContractModal(true);
                                                            }}
                                                        >
                                                            <span className="fa-solid fa-note mr-3 "></span>
                                                            <span className="break-inside-avoid min-w-max">
                                                                My Contract
                                                            </span>
                                                        </ButtonNeoGen>
                                                    </div>
                                                    <div className="w-full my-auto min-w-lg">
                                                        <div className="text-right"></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <></>
                                        // <NavLink
                                        //     to={
                                        //         window.location.hostname === "login.clearerc.com"
                                        //             ? "/processflow-wizard/7"
                                        //             : "/processflow-wizard/28"
                                        //     }
                                        //     className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white dark:bg-indigo-700  bg-indigo-600 dark:text-gray-200 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        // >
                                        //     <span className="fa fa-folder-open mr-3"></span>
                                        //     Open Application(s)
                                        // </NavLink>
                                    )}
                                </div>
                                <div
                                    className={`my-0 ${activeRole?.name === "Affiliate" ? "w-1/4 ml-auto" : "w-full"}`}
                                >
                                    {userRoles?.length > 1 && (
                                        <Select
                                            label="View dashboard as"
                                            value={activeRole?.id || null}
                                            onChange={(v) =>
                                                setActiveRole(userRoles?.find((r: RoleGroup) => r.id === v))
                                            }
                                            options={(userRoles || [])?.map((r: RoleGroup) => ({
                                                label: r.name === "Doc collector" ? "Account manager" : r.name,
                                                value: r.id,
                                            }))}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showLeadsModal && (
                <ModalDialog
                    show={showLeadsModal}
                    close={() => setShowLeadsModal(false)}
                    title="My Leads"
                    showOk={false}
                    showCancel={false}
                    size="xl"
                >
                    <iframe
                        src={`https://partnerapi.clearerc.com/dashboard/?uid=${user.id}&mode=${
                            darkMode ? "dark" : "light"
                        }`}
                        title="My Leads"
                        width="100%"
                        height="600"
                    ></iframe>
                </ModalDialog>
            )}

            {showContractModal && (
                <ModalDialog
                    show={showContractModal}
                    close={() => (showContractBlocking ? undefined : setShowContractModal(false))}
                    title="My Affiliate Contract"
                    showOk={false}
                    showCancel={false}
                    size="xl"
                >
                    <AffiliateContractsPage setShowContractBlocking={setShowContractBlocking} />
                </ModalDialog>
            )}
        </>
    );
}
