import { Interaction } from "../../../interactions/domain/interaction";
import ButtonNeoGen from "../../../layout/button-neogen";
import { THead, Table, Tr, Th, TBody, Td } from "../../../layout/table";

export const NotesTable = ({ notes }: { notes: Interaction[] | undefined }) => {
    const handleOpenLink = (route: string) => {
        const newTab = window.open("", "_blank");
        // @ts-ignore
        newTab.location.href = route;
    };

    function formatTextWithLineBreaks(inputText: string) {
        // Replace newline characters with <br> tags
        const formattedText = inputText.replace(/\n/g, "<br/>");
        return formattedText;
    }

    return (
        <>
            {notes ? (
                <Table>
                    <THead>
                        <Tr>
                            <Th>Created Date</Th>
                            <Th>Assigned By</Th>
                            <Th>Follow Up Date</Th>
                            <Th>Note</Th>
                        </Tr>
                    </THead>
                    <TBody>
                        {notes.map((c) => {
                            const formattedNote = formatTextWithLineBreaks(c.activity || "");
                            return (
                                <Tr key={c.id}>
                                    <Td>{c.dateCreated?.toDateString()}</Td>
                                    <Td>
                                        {[c.interactionFromUser?.firstName, c.interactionFromUser?.lastName].join(" ")}
                                    </Td>
                                    <Td>{c.followupDatetime?.toDateString() || "-"}</Td>
                                    <Td>
                                        <div dangerouslySetInnerHTML={{ __html: formattedNote }} />
                                    </Td>
                                </Tr>
                            );
                        })}
                    </TBody>
                </Table>
            ) : (
                "No notes assigned to you yet."
            )}
        </>
    );
};
