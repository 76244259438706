import { useQuery } from "@tanstack/react-query";
import React from "react";
import pcmpCensusService from "../../services/pcmp-census.service";
import Loader3 from "../utilities/Loader3";
import PageDescription from "../../layout/page-description";
import PrintPre from "../../layout/print-pre";
import TableNeogen from "../../layout/table-neogen";
import ButtonNeoGen from "../../layout/button-neogen";
import SelectNeoGen from "../../layout/select-neogen";
import companyService from "../../services/company.service";
import UserCompanyPicker from "../../layout/user-company-picker";
import { Company } from "../../companies/domain/company";
import { Button } from "react-bootstrap";
import { set } from "lodash";
import AddEditCensusEntry from "./modals/add-edit-census-entry";
// import { Company } from "../../typings/api";

function PcmpCensus({ company }: { company: Company | -1 }) {
    const [showAddEditCensusEntry, setShowAddEditCensusEntry] = React.useState(false);

    const pcmpCensusQuery = useQuery(["pcmpCensusMapped", company === -1 ? company : company.id], async () => {
        let response;
        if (company === -1) {
            response = await pcmpCensusService.getAll();
        } else {
            response = await pcmpCensusService.getFilteredWhere({ companyId: company.id });
        }
        if (response) {
            return response.data.map((r: any) => {
                r["401kContributionTotalPp"] = r.f_401kContributionTotalPp;
                console.log(r);
                const newR = {
                    id: r.id,
                    companyId: r.companyId,
                    firstName: r.firstName,
                    lastName: r.lastName,
                    "2020W4Box2cTwoJobs": r.f_2020W4Box2cTwoJobs,
                    "2020W4FederalDependentsAmount": r.f_2020W4FederalDependentsAmount,
                    "401kContributionTotalPp": r.f_401kContributionTotalPp,
                    additionalPreTaxDeductionsTotalPp: r.additionalPreTaxDeductionsTotalPp,
                    businessPhone: r.businessPhone,
                    carrier: r.carrier,

                    companyDepartment: r.companyDepartment,
                    companyDepartmentCode: r.companyDepartmentCode,
                    dob: r.dob,
                    effectiveDate: r.effectiveDate,
                    email: r.email,
                    employeeContributionMajorMedicalTotalPp: r.employeeContributionMajorMedicalTotalPp,
                    employeeUsername: r.employeeUsername,
                    employerLocationDivision: r.employerLocationDivision,
                    exemptFromSs: r.exemptFromSs,
                    exemptionAmountClaimed: r.exemptionAmountClaimed,
                    externalId: r.externalId,
                    federal_2020W4Used: r.federal_2020W4Used,
                    federalExemptionsClaimed: r.federalExemptionsClaimed,
                    federalMaritalFilingStatus: r.federalMaritalFilingStatus,
                    gender: r.gender,
                    grossWagesPerPayPeriod: r.grossWagesPerPayPeriod,
                    groupName: r.groupName,
                    hireDate: r.hireDate,
                    homePhone: r.homePhone,
                    medicalTier: r.medicalTier,
                    paySchedule: r.paySchedule,
                    postTaxDeductionsTotalPp: r.postTaxDeductionsTotalPp,
                    prAllowancesBasedOnDeductions: r.prAllowancesBasedOnDeductions,
                    prNonResident: r.prNonResident,
                    prVeteranExemption: r.prVeteranExemption,
                    prYoungAdult: r.prYoungAdult,
                    relationshipStatus: r.relationshipStatus,
                    spouseWorks: r.spouseWorks,
                    ssn: r.ssn,
                    stateDependentExemptionsClaimed: r.stateDependentExemptionsClaimed,
                    stateEmployeeFilesIn: r.stateEmployeeFilesIn,
                    stateExemptionsClaimed: r.stateExemptionsClaimed,
                    stateMaritalFilingStatus: r.stateMaritalFilingStatus,
                    streetAddress: r.streetAddress,
                    streetAddress_2: r.streetAddress_2,
                    city: r.city,
                    stateOfResidence: r.stateOfResidence,
                    subscriberId: r.subscriberId,
                    witholdingRate: r.witholdingRate,

                    dateAdded: r.dateAdded,
                    zip: r.zip,
                };
                return newR;
            });
        }
    });
    if (pcmpCensusQuery.isLoading) {
        return (
            <div>
                <Loader3 />
            </div>
        );
    }
    return (
        <>
            <div className="overflow-scroll rounded-lg">
                <div className="mb-5 flex gap-5">
                    <ButtonNeoGen
                        onClick={() => {
                            setShowAddEditCensusEntry(true);
                        }}
                        icon="fas fa-plus"
                    >
                        Add Row
                    </ButtonNeoGen>
                </div>
                Company: {company === -1 ? "All Companies" : company.name}
                <TableNeogen entries={pcmpCensusQuery.data ?? []} />
                <AddEditCensusEntry show={showAddEditCensusEntry} close={() => setShowAddEditCensusEntry(false)} />
            </div>
        </>
    );
}

export default PcmpCensus;
