import { z } from "zod";
import { useAuth } from "../../../../auth/use-auth";
import ButtonNeoGen from "../../../../layout/button-neogen";
import { CurrencyField } from "../../../../layout/form/currency-field";
import { DateTimeField } from "../../../../layout/form/date-field";
import { TextAreaField } from "../../../../layout/form/text-area-field";
import { TextField } from "../../../../layout/form/text-field";
import { Payment } from "../../../../payments/domain/payment";
import { useCreatePaymentMutation } from "../../../../payments/hooks/use-create-payment";
import { getAuthTokenNoThrow } from "../../../../services/auth-header";
import { useForm } from "../../../../hooks/useForm";
import { Form } from "../../../../layout/form/form";
import { Field } from "../../../../layout/form/field";
import ModalDialog from "../../../../layout/modal-dialog";
import { PayoutRow } from "../paymasterContent";
import { ClearERCUser } from "../../../../typings/api/clear-erc-user";

const schema = z.object({
    amount: z.number(),
    date: z.date(),
    notes: z.string().nullish(),
    from: z.string().nullish(),
    to: z.string().nullish(),
    forCompanyId: z.number().nullish(),
    description: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const CreatePaymasterPaymentModal = ({
    userToPay,
    currentUser,
    onClose,
    onPaymentCaptured,
}: {
    userToPay: PayoutRow;
    currentUser: ClearERCUser;
    onClose: () => any;
    onPaymentCaptured: (payment: Payment) => void;
}) => {
    const auth = useAuth();
    const authedUser = auth.expectUser();
    const authToken = getAuthTokenNoThrow() || "no-auth-token";

    const form = useForm({
        schema,
        defaultValues: {
            from: "Paymaster",
            to: `${userToPay.userName} (${userToPay.companyName})`,
            date: new Date(),
            amount: userToPay.payoutOutstanding,
        },
    });

    const formData = form.getValues();

    const createPaymentMutation = useCreatePaymentMutation();

    const handleSubmit = async (data: Data) => {
        const payment = await createPaymentMutation.mutateAsync({
            authToken,
            data: {
                createdById: authedUser.id,
                toUserId: userToPay.userId,
                companyId: userToPay.companyId,
                toClearErc: userToPay.companyName === "ERC Repair" ? true : false,
                ...data,
                notes: data.notes || "",
            },
        });
        onPaymentCaptured(payment);
    };

    return (
        <ModalDialog show title={"Record Payout"} close={onClose} showOk={false} showCancel={false} size="sm">
            <p className="text-center mb-6 text-gray-400">Create a payout from cheque sent by the IRS.</p>
            <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={createPaymentMutation.error as any}>
                <div className="flex flex-row">
                    <div className="flex-1">
                        <Field label="From">{formData.from}</Field>
                    </div>
                    <div className="flex-1">
                        <Field label="To">{formData.to}</Field>
                    </div>
                </div>
                <DateTimeField label="Date" dateFormat="yyyy/MM/dd hh:mm" {...form.getFieldProps("date")} />
                <CurrencyField label="Amount" {...form.getFieldProps("amount")} />
                <TextField label="Description" {...form.getFieldProps("description")} required={false} />
                <TextAreaField label="Notes" {...form.getFieldProps("notes")} />
                <div className="flex justify-end gap-4">
                    <ButtonNeoGen type="outline" disabled={createPaymentMutation.isLoading} onClick={() => onClose()}>
                        Cancel
                    </ButtonNeoGen>
                    <ButtonNeoGen type="submit" disabled={createPaymentMutation.isLoading}>
                        <i className="fa fa-check text-green-500 mr-2" />
                        Record payment
                    </ButtonNeoGen>
                </div>
            </Form>
        </ModalDialog>
    );
};
