import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import DashboardCustomers from "../../layout/dashboard-customers";
import PageDescription from "../../layout/page-description";
import SearchField from "../../layout/search-field";
import SelectNeoGen from "../../layout/select-neogen";
import ButtonNeoGen from "../../layout/button-neogen";
import { getProcessflowProgresses } from "../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { getDataFromProgressData } from "../../jason-proof-of-concept/wizard/wizard-container";
import {
    affiliateCommissionMultiplier,
    getEstimatedPayoutFromData,
    getW2Employees,
} from "../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { CustomerRow } from "./props";
import { NewCompanyModal } from "../../companies/components/modals/new-company-modal";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import Toggle from "../../layout/toggle";
import { RoleGroup } from "../../role-groups/domain/role-group";
import { roleGroups } from "../../services/role-group.service";
import { Company } from "../../companies/domain/company";
import { ProcessflowProgress } from "../../jason-proof-of-concept/processflow-progresses/domain/processflow-progress";
import { getPaginatedCompanies } from "../../companies/actions/get-paginated-companies";
import { debounce, uniqBy } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

export type customerSortType =
    | "createdAtDesc"
    | "createdAtAsc"
    | "nameDesc"
    | "nameAsc"
    | "emailDesc"
    | "emailAsc"
    | "statusDesc"
    | "statusAsc";

export default function MyCustomers({
    users,
    activeRole,
    rows,
}: {
    users: ClearERCUser[];
    activeRole: undefined | null | RoleGroup;
    rows?: any;
}): JSX.Element {
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [sortBy, setSortBy] = useState<customerSortType>("nameAsc");
    const [asCards, setAsCards] = useState(1);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const navigate = useNavigate();
    const [user] = useRecoilState(userAtom);
    const userId = user.id;
    const isSuperUser = !!user.roleGroups?.find((rg) => rg.id === roleGroups.SuperUser);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (term) => {
                setDebouncedSearch(term);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );

    useEffect(() => {
        updateDebouncedSearch(search);
    }, [search, updateDebouncedSearch]);

    const query = useInfiniteQuery({
        queryKey: ["user-customer-data", { debouncedSearch }],
        keepPreviousData: true,
        getNextPageParam: (lastPage: {
            hasNextPage: boolean;
            pageNumber: number;
            count: number;
            companies: Company[];
            progresses: ProcessflowProgress[];
            affiliates: User[];
        }) => {
            return lastPage.hasNextPage ? lastPage.pageNumber + 1 : lastPage.pageNumber;
        },
        queryFn: async ({ pageParam = 0 }) => {
            const limit = 30;
            const offset = pageParam * limit;
            const response = await getPaginatedCompanies({
                authToken,
                filters: {
                    limit,
                    offset,
                    where: {
                        ...(debouncedSearch.length > 0 ? { name: { like: `%${debouncedSearch}%` } } : {}),
                        and: [
                            {
                                status: { neq: "archived" },
                            },
                            {
                                status: { neq: "hidden" },
                            },
                        ],
                    },
                },
            });
            const companies = response.data;
            const ownerIds = companies.map((c) => c.ownedById).filter((o) => !!o) as string[];
            // Get the affiliates for those owners
            const affiliateIds = companies
                .map((c) => c.ownedBy?.affiliateUserId)
                .filter((userId) => !!userId) as string[];
            const progresses = await getProcessflowProgresses({ authToken, filters: { group: 7, userIds: ownerIds } });
            const affiliates = users.filter((af) => affiliateIds.includes(af.id || "")) as User[];
            return {
                count: response.count || 0,
                companies: response.data,
                progresses,
                affiliates,
                hasNextPage: true,
                pageNumber: pageParam,
            };
        },
    });

    const pages = useMemo(() => query.data?.pages || [], [query.data?.pages]);
    const processflowProgresses = useMemo(
        () =>
            uniqBy(
                pages.reduce<ProcessflowProgress[]>((acc, page) => [...acc, ...page.progresses], []),
                (progress) => progress.id,
            ),
        [pages],
    );
    const companies = useMemo(
        () =>
            uniqBy(
                pages.reduce<Company[]>((acc, page) => [...acc, ...page.companies], []),
                (company) => company.id,
            ),
        [pages],
    );
    const affiliates = useMemo(
        () =>
            uniqBy(
                pages.reduce<User[]>((acc, page) => [...acc, ...page.affiliates], []),
                (affiliate) => affiliate.id,
            ),
        [pages],
    );

    const allRows = useMemo(
        () =>
            companies.map((company) => {
                const processflowProgress = processflowProgresses.find(
                    (p) => company.ownedById && company.ownedById === p.userId,
                );
                const data = getDataFromProgressData(processflowProgress?.data || ({} as any));
                const dealValue = processflowProgress?.data ? getEstimatedPayoutFromData({ data }) : 0;
                const estimatedCommission = dealValue * affiliateCommissionMultiplier;
                const status = processflowProgress?.status ?? "";
                const progressPercentage = Math.round((processflowProgress?.data?.percentageComplete || 0) * 100);
                const w2Employees = getW2Employees({ data });
                // const status = getStatus({ data });
                const affiliate = affiliates.find(
                    (a) => company.ownedBy?.affiliateUserId && company.ownedBy?.affiliateUserId === a.id,
                );
                const row: CustomerRow = {
                    processflowProgress,
                    progressPercentage,
                    dealValue,
                    estimatedCommission,
                    company,
                    w2Employees,
                    affiliate: affiliate as User,
                    status,
                };

                return row;
            }),
        [affiliates, companies, processflowProgresses],
    );

    return (
        <>
            <PageDescription title="My Clients">
                <div className="flex flex-col">
                    <div className="flex-grow pt-5 md:pt-0 grid grid-cols-1 gap-5 md:grid-cols-3 md:gap-5 items-center">
                        <SearchField search={search} setSearch={setSearch} />
                        <SelectNeoGen
                            label="Sort companies by"
                            options={[
                                { id: "nameAsc", name: "Name A → Z" },
                                { id: "nameDesc", name: "Name Z → A" },
                                { id: "emailAsc", name: "Email A → Z" },
                                { id: "emailDesc", name: "Email Z → A" },
                                { id: "statusAsc", name: "Status A → Z" },
                                { id: "statusDesc", name: "Status Z → A" },
                                { id: "createdAtAsc", name: "First created" },
                                { id: "createdAtDesc", name: "Last created" },
                            ]}
                            value={sortBy}
                            onChange={(v) => setSortBy(v as customerSortType)}
                            className="mb-3 md:mb-6"
                        />
                        <ButtonNeoGen
                            onClick={() => {
                                setShowAddUserModal(true);
                            }}
                        >
                            Add client
                        </ButtonNeoGen>
                    </div>
                    <div className="w-1/3 ml-auto">
                        <Toggle
                            options={["List View", "Card View"]}
                            setSelectedIndex={setAsCards}
                            selectedIndex={asCards}
                        />
                    </div>
                </div>
            </PageDescription>
            <InfiniteScroll
                dataLength={companies.length}
                next={() => {
                    console.log("next");
                    return query.fetchNextPage();
                }}
                hasMore={false}
                loader={<h4>Loading...</h4>}
                height={600}
                scrollThreshold={"200px"}
                // endMessage={
                //     <p style={{ textAlign: "center" }}>
                //         <b>End</b>
                //     </p>
                // }
            >
                <DashboardCustomers
                    search={""}
                    sortBy={sortBy}
                    rows={rows || allRows}
                    asCards={asCards === 1}
                    activeRole={activeRole}
                />
            </InfiniteScroll>
            {showAddUserModal && (
                <NewCompanyModal
                    roleId={6} // ERC client
                    onClose={() => setShowAddUserModal(false)}
                    onCompanyCreated={({ company }) => {
                        query.refetch();
                        navigate(`/companies/${company.id}`);
                    }}
                />
            )}
        </>
    );
}
