import { ClockIcon, BanknotesIcon, CalculatorIcon, ArrowTrendingUpIcon } from "@heroicons/react/24/outline";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import processflowProgressService from "../../services/processflow-progress.service";
import {
    affiliateCommissionMultiplier,
    clearErcFeesMultiplier,
    getEstimatedPayoutFromData,
} from "../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { ClearERCUser } from "../../typings/api/clear-erc-user";

const stats = [
    {
        id: 1,
        color: "bg-[#61baf3] dark:bg-teal-700",
        name: "Paid Commission",
        stat: "$0.00",
        icon: BanknotesIcon,
        changeType: "",
        change: "",
    },
    { id: 2, color: "bg-[#9ee058] dark:bg-green-900", name: "Unpaid Commission", stat: "$0.00", icon: ClockIcon },
    {
        id: 3,
        color: "bg-[#ed9609] dark:bg-orange-700",
        name: "Total Income",
        stat: "$0.00",
        icon: CalculatorIcon,
    },
    {
        id: 4,
        color: "bg-[#ed411f] dark:bg-red-700",
        name: "Estimated Pipeline",
        stat: "$499,251.67",
        icon: ArrowTrendingUpIcon,
    },
];

export default function AffiliateStats({ users }: { users: ClearERCUser[] | undefined }) {
    const [estimated, setEstimated] = useState(0);
    const [user] = useRecoilState(userAtom);
    const isSuperUser = !!user?.roleGroups?.find((rg) => rg.id === 4);
    const multiplier = isSuperUser ? clearErcFeesMultiplier : clearErcFeesMultiplier * affiliateCommissionMultiplier;

    const processflowDataQuery = useQuery(
        ["processflowData"],
        async () => {
            const response = await processflowProgressService.getAll();
            if (response) {
                return response.data;
            }
            return [];
        },
        {
            cacheTime: 0,
            staleTime: 0,
            refetchOnMount: true,
            refetchOnWindowFocus: true,
            refetchOnReconnect: true,
        },
    );

    useEffect(() => {
        setEstimated(
            (users || [])
                .filter((user) => user.clientStatus !== 10)
                .map((user) => {
                    // TODO: don't check 9 or 11
                    user.average2020 = JSON.parse(
                        processflowDataQuery.data?.find((u) => u.userId === user.id)?.data ?? "{}",
                    )?.["entriesByField"]?.["Average full time W2 Employees in 2020?"]?.fieldValue;

                    user.average2021 = JSON.parse(
                        processflowDataQuery.data?.find((u) => u.userId === user.id)?.data ?? "{}",
                    )?.["entriesByField"]?.["Average full time W2 Employees in 2021?"]?.fieldValue;
                    const total = getEstimatedPayoutFromData({
                        data: {
                            "Average full time W2 Employees in 2020?": user.average2020 ?? 0,
                            "Average full time W2 Employees in 2021?": user.average2021 ?? 0,
                        },
                    });
                    return total;
                })
                .reduce((partialSum, a) => partialSum + a, 0) ?? 0,
        );
    }, [processflowDataQuery.data, users]);

    return (
        <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
                {stats.map((item) => (
                    <Link
                        to="/dashboard"
                        key={item.id}
                        className="
                        relative overflow-hidden bg-gradient-to-br
                        to-white from-slate-100 dark:from-slate-900 dark:to-gray-800
                        border-2 border-white rounded-xl p-4 dark:bg-gray-800
                        dark:border-gray-700 dark:shadow-inner px-5 shadow
                        hover:shadow-none
                        hover:to-slate-200 dark:hover:to-slate-700
                        hover:from-slate-100 dark:hover:from-slate-700
                        transition duration-200 ease-in-out
                        "
                    >
                        <dt>
                            <div className={"absolute rounded-md " + item.color + " p-3"}>
                                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 truncate text-sm font-medium text-gray-400">{item.name}</p>
                        </dt>
                        <dd className="ml-16 flex items-baseline">
                            <p className="text-2xl font-semibold text-gray-500 dark:text-green-500">
                                {item.id === 4
                                    ? Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                      }).format(estimated * multiplier ?? 0)
                                    : item.stat}
                            </p>
                        </dd>
                    </Link>
                ))}
            </dl>
        </div>
    );
}
