import React, { useCallback, useEffect } from "react";
import ModalDialog from "../../../layout/modal-dialog";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { PcmpCensusType } from "../../../services/pcmp-census.service";
import InputControlled from "../../../layout/input-controlled";
import SelectNeoGen from "../../../layout/select-neogen";
import { states } from "../../../states";
import ZipCodeInput from "../../../layout/form/zipcode-input";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { Label } from "../../../layout/label";
import { CurrencyInput } from "../../../layout/form/currency-input";
import { NumberInput } from "../../../layout/form/number-input";

function EmployeeInfo({ existingData, setData }: { existingData: any; setData: (data: any) => void }) {
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [ssn, setSSN] = React.useState("");
    const [streetAddress, setStreetAddress] = React.useState("");
    const [streetAddress_2, setStreetAddress_2] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [zip, setZip] = React.useState("");
    const [homePhone, setHomePhone] = React.useState("");
    const [dob, setDob] = React.useState<Date | null>(null);
    const [dataSet, setDataSet] = React.useState(false);
    const [gender, setGender] = React.useState<undefined | "M" | "F">(undefined);
    const [exemptFromSS, setExemptFromSS] = React.useState<"Y" | "N">("N");
    const [employeeContributionMajorMedicalTotalPp, setEmployeeContributionMajorMedicalTotalPp] = React.useState("N");
    useEffect(() => {
        if (existingData && !dataSet) {
            setFirstName(existingData.firstName);
            setLastName(existingData.lastName);
            setEmail(existingData.email);
            setSSN(existingData.ssn);
            setDob(existingData.dob);
            setGender(existingData.gender);
            setDataSet(true);
            setStreetAddress(existingData.streetAddress);
            setStreetAddress_2(existingData.streetAddress_2);
            setCity(existingData.city);
            setState(existingData.state);
            setZip(existingData.zip);
            setHomePhone(existingData.homePhone);
            setExemptFromSS(existingData.exemptFromSS);
            setEmployeeContributionMajorMedicalTotalPp(existingData.employeeContributionMajorMedicalTotalPp);
            return;
        }
        setData({
            firstName,
            lastName,
            email,
            ssn,
            gender,
            dob,
            streetAddress,
            streetAddress_2,
            city,
            state,
            zip,
            homePhone,
            exemptFromSS,
            employeeContributionMajorMedicalTotalPp,
        });
    }, [
        city,
        dataSet,
        dob,
        email,
        employeeContributionMajorMedicalTotalPp,
        exemptFromSS,
        existingData,
        firstName,
        gender,
        homePhone,
        lastName,
        setData,
        ssn,
        state,
        streetAddress,
        streetAddress_2,
        zip,
    ]);
    return (
        <>
            <div className="grid grid-cols-3 gap-x-5">
                <InputControlled
                    required={true}
                    value={firstName}
                    label="First Name"
                    onChange={(v) => setFirstName(v)}
                />
                <InputControlled required={true} value={lastName} label="Last Name" onChange={(v) => setLastName(v)} />
                <InputControlled type="email" value={email} label="Email" onChange={(v) => setEmail(v)} />
            </div>
            <div className="grid grid-cols-3 gap-x-5">
                <InputControlled
                    required={true}
                    value={ssn}
                    label="Social Security Number"
                    onChange={(v) => setSSN(v)}
                />

                <div className="mb-5">
                    <SelectNeoGen
                        value={gender}
                        onChange={(v: any) => setGender(v as "M" | "F")}
                        label="Gender"
                        options={[
                            { id: "M", name: "Male" },
                            { id: "F", name: "Female" },
                        ]}
                    />
                </div>

                <div className="mb-5">
                    <Label text="Date of Birth" isRequired={false} />
                    <Datepicker
                        asSingle={true}
                        displayFormat="MM/DD/YYYY"
                        value={{ startDate: dob, endDate: dob }}
                        onChange={function (value: DateValueType, e?: HTMLInputElement | null | undefined): void {
                            console.log(value);
                            if (value?.startDate) {
                                const startDate = value.startDate.toString();
                                const dateParts = startDate?.split("-");
                                const jsDate = new Date(Date.parse(startDate.replace(/-/g, "/")));
                                setDob(jsDate);
                                console.log(jsDate);
                            }
                        }}
                    />
                </div>
            </div>
            <InputControlled
                value={streetAddress}
                label="Street Address Line 1"
                onChange={(v) => setStreetAddress(v)}
            />
            <InputControlled
                value={streetAddress_2}
                label="Street Address Line 2"
                onChange={(v) => setStreetAddress_2(v)}
            />
            <ZipCodeInput
                value={zip}
                setValue={(z: any) => {
                    setZip(z);
                    console.log(z);
                }}
                label="Zipcode"
                required={false}
                name={undefined}
                disabled={undefined}
                errors={undefined}
                setCity={setCity}
                setState={setState}
            />
            <InputControlled value={homePhone} label="Home Phone" onChange={(v) => setHomePhone(v)} />
            <div className="grid grid-cols-2 gap-x-5">
                <div className="mb-5">
                    <SelectNeoGen
                        value={exemptFromSS}
                        onChange={(v: any) => setExemptFromSS(v as "Y" | "N")}
                        label="Exempt from Social Security"
                        options={[
                            { id: "Y", name: "Yes" },
                            { id: "N", name: "No" },
                        ]}
                    />
                </div>
                <div className="mb-5">
                    <SelectNeoGen
                        required={true}
                        value={employeeContributionMajorMedicalTotalPp}
                        onChange={(v: any) => setEmployeeContributionMajorMedicalTotalPp(v as "Y" | "N")}
                        label="Employee Contribution Major Medical Total PP"
                        options={[
                            { id: "Y", name: "Yes" },
                            { id: "N", name: "No" },
                        ]}
                    />
                </div>
            </div>
        </>
    );
}
function EmploymentInfo({ existingData, setData }: { existingData: any; setData: (data: any) => void }) {
    const [stateEmployeeFilesIn, setStateEmployeeFilesIn] = React.useState("");
    const [workPhone, setWorkPhone] = React.useState("");
    const [grossWagesPayPeriod, setGrossWagesPayPeriod] = React.useState(0);
    const [payPeriod, setPayPeriod] = React.useState("Weekly");
    const [hireDate, setHireDate] = React.useState<Date | null>(null);
    const [dataSet, setDataSet] = React.useState(false);
    const [groupName, setGroupName] = React.useState("");
    const [employerLocationDivision, setEmployerLocationDivision] = React.useState("");
    const [companyDepartment, setCompanyDepartment] = React.useState("");
    const [companyDepartmentCode, setCompanyDepartmentCode] = React.useState("");
    useEffect(() => {
        if (existingData && !dataSet) {
            setHireDate(existingData.hireDate);
            setStateEmployeeFilesIn(existingData.stateEmployeeFilesIn);
            setPayPeriod(existingData.payPeriod);
            setGrossWagesPayPeriod(existingData.grossWagesPayPeriod);
            setWorkPhone(existingData.workPhone);
            setGroupName(existingData.groupName);
            setEmployerLocationDivision(existingData.employerLocationDivision);
            setCompanyDepartment(existingData.companyDepartment);
            setCompanyDepartmentCode(existingData.companyDepartmentCode);
            setDataSet(true);
            return;
        }
        setData({
            hireDate,
            stateEmployeeFilesIn,
            payPeriod,
            grossWagesPayPeriod,
            workPhone,
            groupName,
            employerLocationDivision,
            companyDepartment,
            companyDepartmentCode,
        });
    }, [
        companyDepartment,
        companyDepartmentCode,
        dataSet,
        employerLocationDivision,
        existingData,
        grossWagesPayPeriod,
        groupName,
        hireDate,
        payPeriod,
        setData,
        stateEmployeeFilesIn,
        workPhone,
    ]);
    return (
        <>
            <div className="mb-5">
                <SelectNeoGen
                    value={stateEmployeeFilesIn}
                    onChange={(v: any) => setStateEmployeeFilesIn(v as string)}
                    label="State Employee Files In"
                    options={states}
                />
            </div>
            <div className="mb-5">
                <SelectNeoGen
                    value={payPeriod}
                    onChange={(v: any) => setPayPeriod(v as string)}
                    label="PayPeriod"
                    options={[
                        { id: "Weekly", name: "Weekly" },
                        { id: "Bi-Weekly", name: "Bi-Weekly" },
                        { id: "Monthly", name: "Monthly" },
                    ]}
                />
            </div>
            <div className="mb-5">
                <Label text="Gross Wages per Pay Period" isRequired={false} />
                <CurrencyInput
                    value={grossWagesPayPeriod}
                    // label="Gross Wages/Pay Period"
                    onChange={(v) => {
                        console.log(v.currentTarget.value.replace(/[^.0-9]/g, ""));
                        setGrossWagesPayPeriod(Number(v.currentTarget.value.replace(/[^.0-9]/g, "")));
                    }}
                />
            </div>
            <div className="mb-5">
                <Label text="Hire Date" isRequired={false} />
                <Datepicker
                    asSingle={true}
                    displayFormat="MM/DD/YYYY"
                    value={{ startDate: hireDate, endDate: hireDate }}
                    onChange={function (value: DateValueType, e?: HTMLInputElement | null | undefined): void {
                        console.log(value);
                        if (value?.startDate) {
                            const startDate = value.startDate.toString();
                            const dateParts = startDate?.split("-");
                            const jsDate = new Date(Date.parse(startDate.replace(/-/g, "/")));
                            setHireDate(jsDate);
                            console.log(jsDate);
                        }
                    }}
                />
            </div>
            <InputControlled value={workPhone} label="Business Phone" onChange={(v) => setWorkPhone(v)} />
            <InputControlled value={groupName} label="Group Name" onChange={(v) => setGroupName(v)} />
            <InputControlled
                value={employerLocationDivision}
                label="Employer Location Division"
                onChange={(v) => setEmployerLocationDivision(v)}
            />
            <div className="grid gap-x-5 grid-cols-2">
                <InputControlled
                    value={companyDepartment}
                    label="Company Department"
                    onChange={(v) => setCompanyDepartment(v)}
                />
                <InputControlled
                    value={companyDepartmentCode}
                    label="Company Department Code"
                    onChange={(v) => setCompanyDepartmentCode(v)}
                />
            </div>
        </>
    );
}
function StateInfo({ existingData, setData }: { existingData: any; setData: (data: any) => void }) {
    const [stateMaritalFilingStatus, setStateMaritalFilingStatus] = React.useState<"S" | "M">("S");
    const [stateExemptionsClaimed, setStateExemptionsClaimed] = React.useState(0);
    const [exemptionAmountClaimed, setExemptionAmountClaimed] = React.useState(0);
    const [stateDependentExemptionsClaimed, setStateDependentExemptionsClaimed] = React.useState(0);
    const [grossWagesPayPeriod, setGrossWagesPayPeriod] = React.useState(0);
    const [withholdingRatePercentage, setWithholdingRatePercentage] = React.useState(0);
    const [payPeriod, setPayPeriod] = React.useState("Weekly");
    const [hireDate, setHireDate] = React.useState<Date | null>(null);
    const [dataSet, setDataSet] = React.useState(false);
    useEffect(() => {
        if (existingData && !dataSet) {
            setHireDate(existingData.hireDate);
            setStateMaritalFilingStatus(existingData.stateMaritalFilingStatus);
            setPayPeriod(existingData.payPeriod);
            setGrossWagesPayPeriod(existingData.grossWagesPayPeriod);
            setStateExemptionsClaimed(existingData.stateExemptionsClaimed);
            setStateDependentExemptionsClaimed(existingData.stateExemptionsClaimed);
            setWithholdingRatePercentage(existingData.withholdingRatePercentage);
            setExemptionAmountClaimed(existingData.exemptionAmountClaimed);
            setDataSet(true);
            return;
        }
        setData({
            hireDate,
            stateMaritalFilingStatus,
            payPeriod,
            grossWagesPayPeriod,
            stateExemptionsClaimed,
            stateDependentExemptionsClaimed,
            withholdingRatePercentage,
            exemptionAmountClaimed,
        });
    }, [
        dataSet,
        exemptionAmountClaimed,
        existingData,
        grossWagesPayPeriod,
        hireDate,
        payPeriod,
        setData,
        stateDependentExemptionsClaimed,
        stateExemptionsClaimed,
        stateMaritalFilingStatus,
        withholdingRatePercentage,
    ]);
    return (
        <>
            <div className="mb-5">
                <SelectNeoGen
                    value={stateMaritalFilingStatus}
                    onChange={(v: any) => setStateMaritalFilingStatus(v as "S" | "M")}
                    label="State Marital Filing Status"
                    options={[
                        { id: "S", name: "Single" },
                        { id: "M", name: "Married" },
                    ]}
                />
            </div>
            <div className="mb-5">
                <Label text="State Exemptions Claimed" isRequired={false} />
                <CurrencyInput
                    value={stateExemptionsClaimed}
                    onChange={(v) => {
                        console.log(v.currentTarget.value.replace(/[^.0-9]/g, ""));
                        setStateExemptionsClaimed(Number(v.currentTarget.value.replace(/[^.0-9]/g, "")));
                    }}
                />
            </div>
            <div className="mb-5">
                <Label text="State Dependent Exemptions Claimed" isRequired={false} />
                <CurrencyInput
                    value={stateDependentExemptionsClaimed}
                    onChange={(v) => {
                        console.log(v.currentTarget.value.replace(/[^.0-9]/g, ""));
                        setStateDependentExemptionsClaimed(Number(v.currentTarget.value.replace(/[^.0-9]/g, "")));
                    }}
                />
            </div>
            <div className="mb-5">
                <Label text="Withholding Rate %" isRequired={false} />
                <NumberInput
                    value={withholdingRatePercentage}
                    onChange={(v) => {
                        console.log(v.currentTarget.value.replace(/[^.0-9]/g, ""));
                        setWithholdingRatePercentage(Number(v.currentTarget.value.replace(/[^.0-9]/g, "")));
                    }}
                />
            </div>
            <div className="mb-5">
                <Label text="Exemption Amount Claimed" isRequired={false} />
                <CurrencyInput
                    value={exemptionAmountClaimed}
                    onChange={(v) => {
                        console.log(v.currentTarget.value.replace(/[^.0-9]/g, ""));
                        setExemptionAmountClaimed(Number(v.currentTarget.value.replace(/[^.0-9]/g, "")));
                    }}
                />
            </div>
        </>
    );
}
function FederalInfo({ existingData, setData }: { existingData: any; setData: (data: any) => void }) {
    const [federalMaritalFilingStatus, setFederalMaritalFilingStatus] = React.useState<"S" | "M">("S");
    const [federal2020W4Used, setFederal2020W4Used] = React.useState<"Y" | "N">("N");
    const [dataSet, setDataSet] = React.useState(false);
    const [f_2020W4Box2cTwoJobs, setF_2020W4Box2cTwoJobs] = React.useState<"Y" | "N">("N");
    const [federalExemptionsClaimed, setFederalExemptionsClaimed] = React.useState<"Y" | "N">("N");
    const [f_2020W4FederalDependentsAmount, setF_2020W4FederalDependentsAmount] = React.useState(0);
    useEffect(() => {
        if (existingData && !dataSet) {
            setFederal2020W4Used(existingData.federal2020W4Used);
            setFederalMaritalFilingStatus(existingData.federalMaritalFilingStatus);
            setF_2020W4Box2cTwoJobs(existingData.f_2020W4Box2cTwoJobs);
            setF_2020W4FederalDependentsAmount(existingData.f_2020W4FederalDependentsAmount);
            setFederalExemptionsClaimed(existingData.federalExemptionsClaimed);
            setDataSet(true);
            return;
        }
        setData({
            federal2020W4Used,
            federalMaritalFilingStatus,
            f_2020W4Box2cTwoJobs,
            f_2020W4FederalDependentsAmount,
            federalExemptionsClaimed,
        });
    }, [
        dataSet,
        existingData,
        setData,
        federal2020W4Used,
        federalMaritalFilingStatus,
        f_2020W4Box2cTwoJobs,
        f_2020W4FederalDependentsAmount,
        federalExemptionsClaimed,
    ]);
    return (
        <>
            <div className="mb-5">
                <SelectNeoGen
                    value={federal2020W4Used}
                    onChange={(v: any) => setFederal2020W4Used(v as "Y" | "N")}
                    label="Federal 2020 W4 Used"
                    options={[
                        { id: "Y", name: "Yes" },
                        { id: "N", name: "No" },
                    ]}
                />
            </div>
            <div className="mb-5">
                <SelectNeoGen
                    value={federalMaritalFilingStatus}
                    onChange={(v: any) => setFederalMaritalFilingStatus(v as "S" | "M")}
                    label="Federal Marital/Filing Status"
                    options={[
                        { id: "S", name: "Single" },
                        { id: "M", name: "Married" },
                    ]}
                />
            </div>
            <div className="mb-5">
                <SelectNeoGen
                    value={f_2020W4Box2cTwoJobs}
                    onChange={(v: any) => setF_2020W4Box2cTwoJobs(v as "Y" | "N")}
                    label="2020 W4 Box 2c Two Jobs"
                    options={[
                        { id: "Y", name: "Yes" },
                        { id: "N", name: "No" },
                    ]}
                />
            </div>
            <div className="mb-5">
                <Label text="2020 W4 Federal Dependents Amount" isRequired={false} />
                <CurrencyInput
                    value={f_2020W4FederalDependentsAmount}
                    onChange={(v) => {
                        console.log(v.currentTarget.value.replace(/[^.0-9]/g, ""));
                        setF_2020W4FederalDependentsAmount(Number(v.currentTarget.value.replace(/[^.0-9]/g, "")));
                    }}
                />
            </div>
            <div className="mb-5">
                <SelectNeoGen
                    value={federalExemptionsClaimed}
                    onChange={(v: any) => setFederalExemptionsClaimed(v as "Y" | "N")}
                    label="Federal Exemptions Claimed"
                    options={[
                        { id: "Y", name: "Yes" },
                        { id: "N", name: "No" },
                    ]}
                />
            </div>
        </>
    );
}
function MiscInfo({ existingData, setData }: { existingData: any; setData: (data: any) => void }) {
    const [relationshipStatus, setRelationshipStatus] = React.useState<"S" | "M" | "D" | "C" | "Se">("S");
    const [dataSet, setDataSet] = React.useState(false);
    const [effectiveDate, setEffectiveDate] = React.useState<Date | null>(null);
    useEffect(() => {
        if (existingData && !dataSet) {
            setRelationshipStatus(existingData.relationshipStatus);
            setEffectiveDate(existingData.effectiveDate);
            setDataSet(true);
            return;
        }
        setData({
            relationshipStatus,
            effectiveDate,
        });
    }, [dataSet, existingData, setData, relationshipStatus, effectiveDate]);
    return (
        <>
            <div className="grid grid-cols-2 gap-x-5">
                <div className="mb-5">
                    <SelectNeoGen
                        value={relationshipStatus}
                        onChange={(v: any) => setRelationshipStatus(v as "S" | "M" | "D" | "C" | "Se")}
                        label="Relationship Status"
                        options={[
                            { id: "S", name: "Single" },
                            { id: "M", name: "Married" },
                            { id: "D", name: "Divorces" },
                            { id: "C", name: "Cohabiting" },
                            { id: "Se", name: "Seperated" },
                        ]}
                    />
                </div>
                <div className="mb-5">
                    <Label text="Effective Date" isRequired={false} />
                    <Datepicker
                        asSingle={true}
                        displayFormat="MM/DD/YYYY"
                        value={{ startDate: effectiveDate, endDate: effectiveDate }}
                        onChange={function (value: DateValueType, e?: HTMLInputElement | null | undefined): void {
                            console.log(value);
                            if (value?.startDate) {
                                const startDate = value.startDate.toString();
                                const dateParts = startDate?.split("-");
                                const jsDate = new Date(Date.parse(startDate.replace(/-/g, "/")));
                                setEffectiveDate(jsDate);
                                console.log(jsDate);
                            }
                        }}
                    />
                </div>
            </div>
        </>
    );
}

function AddEditCensusEntry({
    show,
    close,
    currentEntry,
}: {
    show: boolean;
    close: () => void;
    currentEntry?: PcmpCensusType;
}) {
    const [internalData, setInternalData] = React.useState<any>();
    const setData = useCallback((data: any) => {
        setInternalData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
        console.log(data);
    }, []);
    const tabs = [
        {
            name: "Employee Info",
            href: "#",
            content: (
                <div>
                    <EmployeeInfo setData={setData} existingData={undefined} />
                </div>
            ),
        },
        {
            name: "Employment Info",
            href: "#",
            content: (
                <div>
                    <EmploymentInfo setData={setData} existingData={undefined} />
                </div>
            ),
        },
        {
            name: "State Info",
            href: "#",
            content: (
                <div>
                    <StateInfo setData={setData} existingData={undefined} />
                </div>
            ),
        },
        {
            name: "Federal Info",
            href: "#",
            content: (
                <div>
                    <FederalInfo setData={setData} existingData={undefined} />
                </div>
            ),
        },
        {
            name: "Misc. Info",
            href: "#",
            content: (
                <div>
                    <MiscInfo setData={setData} existingData={undefined} />
                </div>
            ),
        },
    ];

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(" ");
    }
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    return (
        <ModalDialog
            size="xl"
            close={() => close()}
            show={show}
            title={currentEntry ? "Edit Census Entry" : "Add Census Entry"}
            okAction={() => {
                console.log(internalData);
                if (currentEntry) {
                    alert("Edit");
                }
                if (!currentEntry) {
                    alert("Add");
                }
                close();
            }}
        >
            <div>
                <div className="hidden sm:block">
                    <TabGroup
                        selectedIndex={selectedIndex}
                        onChange={(x) => {
                            setSelectedIndex(x);
                            console.log(tabs);
                        }}
                    >
                        <nav aria-label="Tabs" className="isolate flex ">
                            <TabList>
                                {tabs.map((tab, tabIdx) => (
                                    <Tab key={tab.name}>
                                        <a
                                            key={tab.name}
                                            href={tab.href}
                                            aria-current={tabIdx === selectedIndex ? "page" : undefined}
                                            className={classNames(
                                                tabIdx === selectedIndex
                                                    ? "text-gray-900"
                                                    : "text-gray-500 hover:text-gray-700",
                                                tabIdx === 0 ? "rounded-l-lg" : "",
                                                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                                                "group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10",
                                            )}
                                        >
                                            <span>{tab.name}</span>
                                            <span
                                                aria-hidden="true"
                                                className={classNames(
                                                    tabIdx === selectedIndex ? "bg-indigo-500" : "bg-transparent",
                                                    "absolute inset-x-0 bottom-0 h-0.5",
                                                )}
                                            />
                                        </a>
                                    </Tab>
                                ))}
                            </TabList>
                        </nav>
                        <div className="mt-5">
                            <TabPanels>
                                {tabs.map((tab, tabIdx) => (
                                    <TabPanel key={tabIdx}>{tab.content}</TabPanel>
                                ))}
                            </TabPanels>
                        </div>
                    </TabGroup>
                </div>
            </div>
        </ModalDialog>
    );
}

export default AddEditCensusEntry;
