import PCMPDashboard from "../../dashboard/pcmp-dashboard";

function PCMPDash() {
    return (
        <>
            <PCMPDashboard
                setSideBarOpen={function (isOpen: boolean): void {
                    throw new Error("Function not implemented.");
                }}
                sideBarOpen={false}
                darkMode={false}
            />
        </>
    );
}

export default PCMPDash;
