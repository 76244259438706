import UtmStats from "../../utm/stats";
import AffiliateStats from "../affiliate-stats";
import MyCustomers from "../my-customers";
import UtmHitsByDate from "../utm-hits-by-date";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import { RoleGroup } from "../../../role-groups/domain/role-group";
import { AffiliateRepairDeals } from "../../../affiliate-billing/affiliate-repair-deals-page";

export const AffiliateContent = ({
    darkMode,
    users,
    usersQueryIsSuccess,
    usersQueryLoading,
    activeRole,
}: {
    darkMode: boolean;
    users: ClearERCUser[] | undefined;
    usersQueryIsSuccess: boolean;
    usersQueryLoading: boolean;
    activeRole: undefined | null | RoleGroup;
}) => {
    return (
        <>
            {window.location.href == "login.clearerc.com" ? (
                <AffiliateStats users={users} />
            ) : (
                <AffiliateRepairDeals included={true} />
            )}
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 mt-5">
                <div className="col-span-2 grid grid-cols-1 xl:grid-cols-2 gap-5">
                    <div className="col-span-1">
                        <UtmStats
                            darkMode={darkMode}
                            users={users}
                            usersQuerySuccess={usersQueryIsSuccess}
                            usersQueryLoading={usersQueryLoading}
                        />
                    </div>
                    <div className="col-span-1">
                        <UtmHitsByDate darkMode={darkMode} usersQuerySuccess={usersQueryIsSuccess} />
                    </div>
                    {window.location.href === "login.clearerc.com" && (
                        <div className="xl:col-span-2">
                            <MyCustomers users={users || []} activeRole={activeRole} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
