import { useQuery } from "@tanstack/react-query";
import React from "react";
import pcmpCensusService from "../../services/pcmp-census.service";
import Loader3 from "../utilities/Loader3";
import PageDescription from "../../layout/page-description";
import PrintPre from "../../layout/print-pre";
import TableNeogen from "../../layout/table-neogen";
import ButtonNeoGen from "../../layout/button-neogen";
import SelectNeoGen from "../../layout/select-neogen";
import companyService from "../../services/company.service";
import UserCompanyPicker from "../../layout/user-company-picker";
import { Company } from "../../companies/domain/company";
import { Button } from "react-bootstrap";
import { set } from "lodash";
import AddEditCensusEntry from "./modals/add-edit-census-entry";
import AddEditShortCensus from "./modals/add-edit-short-census";
import pcmpShortCensusService from "../../services/pcmp-short-census.service";
// import { Company } from "../../typings/api";

function PcmpShortCensus({ company }: { company: Company | -1 }) {
    const [showAddEditCensusEntry, setShowAddEditCensusEntry] = React.useState(false);

    const pcmpCensusQuery = useQuery(["pcmpShortCensus", "Mapped", company === -1 ? company : company.id], async () => {
        let response;
        if (company === -1) {
            response = await pcmpShortCensusService.getAll();
        } else {
            response = await pcmpShortCensusService.getFilteredWhere({ companyId: company.id });
        }
        if (response) {
            return response.data.map((r: any) => {
                r["401kContributionTotalPp"] = r.f_401kContributionTotalPp;
                console.log(r);
                const newR = {
                    id: r.id,
                    name: r.firstName + " " + r.lastName,
                    state: r.stateEmployeeFilesIn,
                    schedule: r.paySchedule,
                    gross: r.grossWagesPerPayPeriod,
                    majorMedical: r.employeeContributionMajorMedicalTotalPp,
                    fedMarital: r.federalMaritalFilingStatus,
                    fedExemps: r.federalExemptionsClaimed,
                    hiringDate: r.hireDate,
                    stateMarital: r.stateMaritalFilingStatus,
                    stateExemps: r.stateExemptionsClaimed,
                    //     companyId: r.companyId,
                    //     firstName: r.firstName,
                    //     lastName: r.lastName,
                };
                return newR;
            });
        }
    });
    if (pcmpCensusQuery.isLoading) {
        return (
            <div>
                <Loader3 />
            </div>
        );
    }
    return (
        <>
            <div className="rounded-lg mb-5">
                <div className="mb-5 flex gap-5">
                    <ButtonNeoGen
                        onClick={() => {
                            setShowAddEditCensusEntry(true);
                        }}
                        icon="fas fa-plus"
                    >
                        Add Employee
                    </ButtonNeoGen>
                </div>

                <div className="mb-5 flex gap-5 overflow-x-scroll max-w-7xl">
                    <TableNeogen
                        compact={true}
                        formatters={[
                            { field: "gross", type: "Currency" },
                            { field: "majorMedical", type: "Currency" },
                            // { field: "federalExemptionsClaimed", type: "Number" },
                            { field: "companyId", type: "Company" },
                            { field: "hiringDate", type: "Date" },
                        ]}
                        ignoreFields={company === -1 ? [] : ["companyId"]}
                        entries={pcmpCensusQuery.data ?? []}
                    />
                    <AddEditShortCensus
                        companyId={company === -1 ? -1 : company.id ?? -1}
                        show={showAddEditCensusEntry}
                        close={() => setShowAddEditCensusEntry(false)}
                    />
                </div>
            </div>
        </>
    );
}

export default PcmpShortCensus;
