import { Button } from "@headlessui/react";
import React from "react";
import { act } from "react-test-renderer";
import ButtonNeoGen from "../../../layout/button-neogen";
import image from "./image-1.jpg";
import jesse from "./jesse.jpg";
import ach from "./ach.jpg";
import baa from "./baa.jpg";
import msa from "./msa.jpg";
import filingCabinet from "./files.jpg";
import contractsImage from "./contracts.jpg";
import contactsImage from "./contacts.jpg";
import calendar from "./calendar.jpg";
import onboarding from "./onboarding.jpg";
import profile from "./profile-picture-3.jpg";
import profile2 from "./profile-picture-4.jpg";
import sa from "./sa.jpg";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import { useCompany } from "../../../companies/hooks/use-company";
import { getProcessflowProgresses } from "../../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import fileService from "../../../services/file.service";
import PrintPre from "../../../layout/print-pre";
import { File as FileType } from "../../../services/../files/domain/file";
import ModalDialog from "../../../layout/modal-dialog";
import { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";
import appointmentsService, { Appointment } from "../../../services/appointments.service";
import Loader3 from "../../utilities/Loader3";
const HomeContents = () => {
    return (
        <div className="p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full">
            <h3 className="text-lg font-bold text-gray-600 dark:text-white mb-2">
                Welcome to your Wellness Program dashboard
            </h3>
            <p className="mb-2">
                This is some placeholder content the Wellness Program&apos;s associated content, clicking another tab
                will toggle the visibility of this one for the next.
            </p>
            <p>The tab JavaScript swaps classes to control the content visibility and styling.</p>
        </div>
    );
};
const HomeContents2 = () => {
    const authToken = getAuthTokenNoThrow() || "no-token";
    const [user] = useRecoilState(userAtom);
    const queryCache = useQueryClient();
    // const companyQuery = useCompany({ authToken, id: companyId });
    // const company = companyQuery.data;

    const usersProgressQuery = useQuery(["processflowProgresses", { userId: user.id }], async () => {
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { group: 28 },
        });
        return { usersProgress };
    });
    return (
        <section className="p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full border-2 border-white dark:border-gray-600 dark:border shadow-xl">
            <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
                <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-5xl dark:text-white">
                    Welcome to the Wellness Program
                </h1>
                <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">
                    Here you&apos;ll find all the information you need to get started with the program. If you have any
                    questions or need assistance, please don&apos;t hesitate to reach out.
                </p>
                <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
                    <a
                        href="#"
                        onClick={() => {
                            // if (contract?.position) {
                            // Get the customer's processflow progress ID

                            const processflowId = usersProgressQuery.data?.usersProgress?.id;
                            if (processflowId) {
                                window.localStorage.setItem(
                                    "position-50",
                                    JSON.stringify({
                                        [processflowId]: { processflowStageId: 252, processflowId: 616 },
                                    }),
                                );
                            }
                            // }
                            window.localStorage.setItem("tempRoleGroup", "50");
                            window.location.href = "/processflow-wizard/28";
                        }}
                        className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
                    >
                        Get started
                        <svg
                            className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 5h12m0 0L9 1m4 4L9 9"
                            />
                        </svg>
                    </a>
                    {/* <a
                        href="#"
                        className="py-3 px-5 sm:ms-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                        Learn more
                    </a> */}
                </div>
            </div>
        </section>
    );
};
const ApplicationContents = () => {
    return (
        <div className="p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full border-2 border-white dark:border-gray-600 dark:border shadow-xl">
            <h3 className="text-lg font-bold text-gray-600 dark:text-white mb-2">Application Details</h3>
            <p className="mb-2">Please click below to open your application</p>
            <ButtonNeoGen
                className="bg-blue-500 text-white"
                onClick={() => {
                    console.log("Application clicked");
                }}
            >
                Open Application
            </ButtonNeoGen>
        </div>
    );
};
const FilesContents = () => {
    const files = [
        {
            name: "Some File",
            type: "PDF File",
            url: "janecooper@example.com",
            icon: "fa fa-file-pdf",
        },
        {
            name: "Some File",
            type: "PDF File",
            url: "janecooper@example.com",
            icon: "fa fa-file-pdf",
        },
        {
            name: "Some File",
            type: "PDF File",
            url: "janecooper@example.com",
            icon: "fa fa-file-pdf",
        },
        {
            name: "Some File",
            type: "PDF File",
            url: "janecooper@example.com",
            icon: "fa fa-file-pdf",
        },
        {
            name: "Some File",
            type: "PDF File",
            url: "janecooper@example.com",
            icon: "fa fa-file-pdf",
        },
        {
            name: "Some File",
            type: "PDF File",
            url: "janecooper@example.com",
            icon: "fa fa-file-pdf",
        },
        {
            name: "Some File",
            type: "PDF File",
            url: "janecooper@example.com",
            icon: "fa fa-file-pdf",
        },
        {
            name: "Some File",
            type: "PDF File",
            url: "janecooper@example.com",
            icon: "fa fa-file-pdf",
        },
        // More people...
    ];
    const authToken = getAuthTokenNoThrow() || "no-token";
    const [user] = useRecoilState(userAtom);
    const usersProgressQuery = useQuery(["processflowProgresses", { userId: user.id }], async () => {
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { group: 28 },
        });
        return { usersProgress };
    });
    const myFilesQuery = useQuery(["files", { userId: user.id }], async () => {
        const response = await fileService.getMyFiles();
        if (response) {
            return response.data.pageItems
                .filter((r: FileType) => {
                    return r.isArchived === false;
                })
                .map((r: any) => {
                    let icon = "fa fa-file";
                    switch (r.mimetype) {
                        case "application/pdf":
                            icon = "fa fa-file-pdf";
                            break;
                        case "image/jpeg":
                        case "image/png":
                            icon = "fa fa-file-image";
                            break;
                        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                            icon = "fa fa-file-word";
                            break;
                        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                            icon = "fa fa-file-excel";
                            break;
                        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                            icon = "fa fa-file-powerpoint";
                            break;
                        default:
                            icon = "fa fa-file";
                            break;
                    }
                    const newR = {
                        id: r.id,
                        originalFilename: r.originalFilename,
                        filename: r.filename,
                        mimetype: r.mimetype,
                        icon: icon,
                    };
                    return newR;
                });
        }
    });

    if (myFilesQuery.isLoading || usersProgressQuery.isLoading) {
        return <div>Loading...</div>;
    }

    // export default function Example() {
    return (
        <div className="grid grid-cols-1">
            <div className="overflow-hidden bg-white pt-8 pb-0 rounded-lg">
                <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                        <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                            <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                Your Files
                            </h2>
                            <p className="mt-6 text-xl/8 text-gray-600">
                                Below you&apos;ll find a list of files you&apos;ve uploaded to the program. Click to
                                download or view.
                            </p>
                            <p className="mt-6 text-base/7 text-gray-600">
                                You can upload new files to your application by clicking the button below.
                            </p>
                            <div className="mt-10 flex">
                                <a
                                    href="#"
                                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    onClick={() => {
                                        const processflowId = usersProgressQuery.data?.usersProgress?.id;
                                        if (processflowId) {
                                            window.localStorage.setItem(
                                                "position-50",
                                                JSON.stringify({
                                                    [processflowId]: {
                                                        processflowStageId: 252,
                                                        processflowId: 616,
                                                    },
                                                }),
                                            );
                                        }
                                        // }
                                        window.localStorage.setItem("tempRoleGroup", "50");
                                        window.location.href = "/processflow-wizard/28";
                                    }}
                                >
                                    Open Application <span aria-hidden="true">&rarr;</span>
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-start justify-start gap-6 sm:gap-8 lg:contents">
                            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-start">
                                <img
                                    alt=""
                                    src={filingCabinet}
                                    className="w-[28rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
            </div>
            <div>
                {myFilesQuery.data?.length === 0 && (
                    <div className="mt-10 text-center">
                        <p className="text-lg font-semibold text-gray-600">
                            No files found - you can upload files in your application by clicking the button above.
                        </p>
                    </div>
                )}
                <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 mt-5">
                    {myFilesQuery.data?.map((file) => (
                        <li
                            key={file.id}
                            className="col-span-1 flex flex-col divide-y divide-gray-200 dark:divide-gray-700 rounded-lg bg-white dark:bg-gray-900 text-center "
                        >
                            <div className="flex flex-1 flex-col p-4">
                                <i className={file.icon + " fa-3x text-blue-600"} />
                                <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                                    {file.originalFilename}
                                </h3>
                                <dl className="mt-1 flex grow flex-col justify-between">
                                    {/* <dt className="sr-only">Type</dt> */}
                                    {/* <dd className="text-sm text-gray-500">{file.mimetype}</dd> */}
                                    {/* <dt className="sr-only">Role</dt> */}
                                    {/* <dd className="mt-3">
                                    <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        {person.role}
                                    </span>
                                </dd> */}
                                </dl>
                            </div>
                            <div>
                                <div className="-mt-px flex divide-x divide-gray-200 dark:divide-gray-700">
                                    <div className="flex w-0 flex-1 hover:bg-gray-100 rounded-b-lg dark:hover:bg-gray-700">
                                        <a
                                            href={`https://api.clearerc.com/files/${file.filename}`}
                                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-2 text-sm font-bold text-gray-900"
                                        >
                                            <i className="fas fa-download text-lg text-gray-500 mr-3" />
                                            <span className="text-md text-gray-500">Download</span>
                                        </a>
                                    </div>
                                    {/* <div className="-ml-px flex w-0 flex-1">
                                    <a
                                        href={`tel:${file.url}`}
                                        className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                                    >
                                        <PhoneIcon aria-hidden="true" className="h-5 text-gray-400" />
                                        Show
                                    </a>
                                </div> */}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
    // }
};
const ContactsContents = () => {
    const contacts = [
        {
            id: 1,
            name: "Bonnie Green",
            title: "Account Manager",
            email: "bonnie@sureadvisors.com",
            phone: "(689) 444-3120",
            image: profile,
        },
        {
            id: 2,
            name: "Jason Birkett",
            title: "CEO & Founder",
            email: "jason@sureadvisors.com",
            phone: "(689) 444-3120",
            image: profile2,
        },
        {
            id: 3,
            name: "Sure Advisors",
            title: "Customer Support",
            email: "info@sureadvisors.com",
            phone: "(689) 444-3120",
            image: sa,
        },
    ];

    return (
        <div className="grid grid-cols-1 w-full">
            <div className="overflow-hidden bg-white pt-8 pb-0 rounded-lg w-full">
                <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                        <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                            <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                Your Contacts
                            </h2>
                            <p className="mt-6 text-xl/8 text-gray-600">
                                Below you&apos;ll find a list of people who have been assigned to your account.
                            </p>
                            <p className="my-6 text-base/7 text-gray-600">
                                You can message them or call them by clicking the buttons beneath their names.
                            </p>
                            {/*<div className="mt-10 flex">
                                <a
                                    href="#"
                                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    onClick={() => {
                                        const processflowId = usersProgressQuery.data?.usersProgress?.id;
                                        if (processflowId) {
                                            window.localStorage.setItem(
                                                "position-50",
                                                JSON.stringify({
                                                    [processflowId]: {
                                                        processflowStageId: 252,
                                                        processflowId: 616,
                                                    },
                                                }),
                                            );
                                        }
                                        // }
                                        window.localStorage.setItem("tempRoleGroup", "50");
                                        window.location.href = "/processflow-wizard/28";
                                    }}
                                >
                                    Open Application <span aria-hidden="true">&rarr;</span>
                                </a>
                            </div> */}
                        </div>
                        <div className="flex flex-wrap items-start justify-start gap-6 sm:gap-8 lg:contents">
                            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-start">
                                <img
                                    alt=""
                                    src={contactsImage}
                                    className="w-[28rem] max-w-none rounded-2xl bg-gray-50 object-cover mb-7"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5 pt-5">
                {contacts.map((contact) => (
                    <div
                        key={contact.id}
                        className="w-full  bg-white border border-gray-200 rounded-lg  dark:bg-gray-800 dark:border-gray-700 pt-5"
                    >
                        <div className="flex justify-end px-4 pt-4"></div>
                        <div className="flex flex-col items-center pb-10">
                            <img
                                className="w-24 h-24 mb-3 rounded-full shadow-lg"
                                src={contact.image}
                                alt="Staff image"
                            />
                            <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">{contact.name}</h5>
                            <span className="text-sm text-gray-500 dark:text-gray-400">{contact.title}</span>
                            <div className="flex mt-4 md:mt-6">
                                <a
                                    href={"mailto:" + contact.email}
                                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                    <i className="fal fa-envelope mr-3"></i>
                                    Send Message
                                </a>
                                <a
                                    href={"tel:" + contact.phone.replace(/\D/g, "")}
                                    className="py-2 px-4 ms-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                >
                                    <i className="fal fa-phone mr-3"></i>
                                    Call
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
                {/* <div className="w-full  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 pt-5">
                    <div className="flex justify-end px-4 pt-4"></div>
                    <div className="flex flex-col items-center pb-10">
                        <img className="w-24 h-24 mb-3 rounded-full shadow-lg" src={profile2} alt="Jason image" />
                        <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">Jason Birkett</h5>
                        <span className="text-sm text-gray-500 dark:text-gray-400">Janitor</span>
                        <div className="flex mt-4 md:mt-6">
                            <a
                                href="#"
                                className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                <i className="fal fa-envelope mr-3"></i>
                                Send Message
                            </a>
                            <a
                                href="#"
                                className="py-2 px-4 ms-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                            >
                                <i className="fal fa-phone mr-3"></i>
                                Call
                            </a>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};
const AppointmentsContents = () => {
    const authToken = getAuthTokenNoThrow() || "no-token";
    const [user] = useRecoilState(userAtom);
    const queryCache = useQueryClient();
    const [showNewAppointment, setShowNewAppointment] = React.useState(false);
    // const companyQuery = useCompany({ authToken, id: companyId });
    // const company = companyQuery.data;
    useEffect(() => {
        (async function () {
            const cal = await getCalApi({ namespace: "integration-appointment" });
            cal("ui", { hideEventTypeDetails: false, layout: "month_view" });
        })();
    }, []);

    const usersProgressQuery = useQuery(["processflowProgresses", { userId: user.id }], async () => {
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { group: 28 },
        });
        return { usersProgress };
    });

    const appointmentsQuery = useQuery(["appointments", "embellished", { userId: user.id }], async () => {
        const response = await appointmentsService.getFilteredWhere({
            or: [{ destinationCalendarEmail: user.email }, { email: user.email }],
        });
        if (response) {
            let newResponses = response.data.map((a: Appointment) => {
                console.log(new Date(a.payload.startTime).toLocaleString());
                let image = onboarding;
                if (a.payload.eventTitle === "Integration Appointment") {
                    image = onboarding;
                }
                // switch ()
                return {
                    id: a.bookingId,
                    name: a.payload.eventTitle,
                    description: a.payload.eventDescription,
                    image: image,
                    status: a.payload.status,
                    eventDateTime: new Date(a.payload.startTime).toLocaleString(),
                    payload: a.payload,
                    uid: a.payload.uid,
                };
            });
            const rescheduledIds: number[] = [];
            const cancelledIds: number[] = [];
            // Go through and make a list of any that reschedule previous ones
            newResponses.forEach((r) => {
                if (r.payload.rescheduleId) {
                    rescheduledIds.push(r.payload.rescheduleId);
                }
                if (r.payload.status === "CANCELLED") {
                    cancelledIds.push(r.id);
                }
            });

            newResponses = newResponses.filter((r) => {
                return !rescheduledIds.includes(r.id);
            });
            newResponses = newResponses.filter((r) => {
                return !cancelledIds.includes(r.id);
            });
            return newResponses ?? [];
        }
    });

    if (appointmentsQuery.isLoading || usersProgressQuery.isLoading) {
        return <Loader3 />;
    }
    return (
        <>
            <div className="grid grid-cols-1">
                <div className="overflow-hidden bg-white pt-8 pb-0 rounded-lg">
                    <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
                        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                            <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                                <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                    Your Appointments
                                </h2>
                                <p className="mt-6 text-xl/8 text-gray-600">
                                    Below you&apos;ll find a list of appointments you currently have scheduled. You can
                                    view them, reschedule them, or cancel them as needed.
                                </p>
                                <p className="mt-6 text-base/7 text-gray-600">
                                    If you&apos;d like to schedule a new appointment, please click the button below
                                </p>
                                <div className="mt-10 flex">
                                    {/* <a
                                        href="#"
                                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={() => {
                                            setShowNewAppointment(true);
                                        }}
                                        rel="noreferrer"
                                    ></a> */}
                                    <button
                                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        data-cal-namespace="integration-appointment"
                                        data-cal-link="team/sure-advisors/integration-appointment"
                                        data-cal-config={'{"layout":"month_view", "email": "' + user.email + '"}'}
                                    >
                                        New Appointment{" "}
                                        <span aria-hidden="true">
                                            <i className="fa fa-plus"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-wrap items-start justify-start gap-6 sm:gap-8 lg:contents">
                                <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-start">
                                    <img
                                        alt=""
                                        src={calendar}
                                        className="w-[28rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="mt-5 p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full border-2 border-white dark:border-gray-600 dark:border shadow-xl"> */}
                <div className={"mt-5"}>
                    {(appointmentsQuery.data ?? []).length == 0 ? (
                        <> </>
                    ) : (
                        // <div className="border rounded-lg p-5 bg-white dark:bg-gray-800 dark:border-gray-700">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                            {/* <PrintPre>{appointmentsQuery.data}</PrintPre> */}
                            {(appointmentsQuery.data ?? []).map((appointment) => (
                                <div
                                    key={appointment.id}
                                    className="w-full bg-white border border-gray-200 rounded-lg  dark:bg-gray-800 dark:border-gray-700 mb-5"
                                >
                                    {/* <a href="#"> */}
                                    <img className=" rounded-t-lg" src={appointment.image} alt="Contract Image" />
                                    {/* </a> */}
                                    <div className="p-5">
                                        {/* <a href="#"> */}
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                            {appointment.name}
                                        </h5>
                                        {/* </a> */}
                                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                            {appointment.description}
                                        </p>
                                        <p className="mb-3 font-bold text-gray-700 dark:text-gray-400">
                                            Scheduled For {appointment.eventDateTime}
                                        </p>
                                        <a
                                            href={"https://app.cal.com/booking/" + appointment.uid}
                                            target="_blank"
                                            className="mr-3 mb-3 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                            rel="noreferrer"
                                        >
                                            Details
                                            <i className="fas fa-info-circle ml-3" />
                                        </a>
                                        <a
                                            href={
                                                "https://app.cal.com/reschedule/" +
                                                appointment.uid +
                                                "?rescheduledBy=" +
                                                user.email
                                            }
                                            target="_blank"
                                            className="mr-3 mb-3 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-orange-700 rounded-lg hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800"
                                            rel="noreferrer"
                                        >
                                            Reschedule
                                            <i className="fas fa-clock ml-3 " />
                                        </a>
                                        <a
                                            href={"https://app.cal.com/booking/" + appointment.uid + "?cancel=true"}
                                            target="_blank"
                                            className="mr-3  mb-3 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                                            rel="noreferrer"
                                        >
                                            Cancel
                                            <i className="fas fa-times ml-2 " />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                        // </div>
                    )}
                </div>
            </div>
            <ModalDialog title="New Appointment" show={showNewAppointment} close={() => setShowNewAppointment(false)}>
                <iframe src="https://cal.com/team/sure-advisors/integration-appointment"></iframe>
            </ModalDialog>
        </>
    );
};
const ContractContents = () => {
    const authToken = getAuthTokenNoThrow() || "no-token";
    const [user] = useRecoilState(userAtom);
    const queryCache = useQueryClient();
    // const companyQuery = useCompany({ authToken, id: companyId });
    // const company = companyQuery.data;

    const usersProgressQuery = useQuery(["processflowProgresses", { userId: user.id }], async () => {
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { group: 28 },
        });
        return { usersProgress };
    });

    const contracts = [
        {
            id: 1,
            name: "Business Associate Agreement",
            description:
                "The Business Associate Agreement allows us to provide your information to our partners who provide health and wellness services. This is required as part of the program.",
            image: baa,
            status: "unsigned",
            position: { processflowStageId: 248, processflowId: 602 },
        },
        {
            id: 2,
            name: "Management Services Agreement",
            description:
                "The Management Services Agreement outlines the services provided by the program and the terms of the agreement. It also explains the responsibilities of the program and the client.",
            image: msa,
            status: "unsigned",
            position: { processflowStageId: 248, processflowId: 603 },
        },
        {
            id: 3,
            name: "ACH Authorization",
            description: "This form authorizes transfers to the company who operates billing for the program.",
            image: ach,
            status: "signed",
            position: { processflowStageId: 248, processflowId: 604 },
        },
    ];
    const unsignedContracts = contracts.filter((c) => c.status === "unsigned");
    const signedContracts = contracts.filter((c) => c.status === "signed");
    return (
        <div className="grid grid-cols-1">
            <div className="overflow-hidden bg-white pt-8 pb-0 rounded-lg">
                <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                        <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                            <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                Your Contracts
                            </h2>
                            <p className="mt-6 text-xl/8 text-gray-600">
                                Below you&apos;ll find a list of your contracts, both signed and unsigned. Click to
                                download or preview.
                            </p>
                            <p className="my-6 text-base/7 text-gray-600">
                                Once signed, the contract will move to the signed section and you can download or email
                                it.
                            </p>
                            {/*<div className="mt-10 flex">
                                <a
                                    href="#"
                                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    onClick={() => {
                                        const processflowId = usersProgressQuery.data?.usersProgress?.id;
                                        if (processflowId) {
                                            window.localStorage.setItem(
                                                "position-50",
                                                JSON.stringify({
                                                    [processflowId]: {
                                                        processflowStageId: 252,
                                                        processflowId: 616,
                                                    },
                                                }),
                                            );
                                        }
                                        // }
                                        window.localStorage.setItem("tempRoleGroup", "50");
                                        window.location.href = "/processflow-wizard/28";
                                    }}
                                >
                                    Open Application <span aria-hidden="true">&rarr;</span>
                                </a>
                            </div> */}
                        </div>
                        <div className="flex flex-wrap items-start justify-start gap-6 sm:gap-8 lg:contents">
                            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-start">
                                <img
                                    alt=""
                                    src={contractsImage}
                                    className="w-[28rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
            </div>
            <div className="mt-5">
                <div
                    className={"grid grid-cols-1 lg:grid-cols-" + (unsignedContracts.length > 0 ? "2" : "1") + " gap-5"}
                >
                    {unsignedContracts.length > 0 && (
                        <div className="border rounded-lg p-5 bg-white dark:bg-gray-800 dark:border-gray-700">
                            <h4 className="text-2xl font-bold tracking-tight  text-gray-600 dark:text-white mb-5 text-center">
                                Unsigned Contracts{" "}
                                {unsignedContracts.length > 0 && (
                                    <span
                                        className={
                                            "bg-red-500 text-white rounded-full align-top " +
                                            (unsignedContracts.length > 1 ? "px-1" : "px-1.5") +
                                            " text-sm"
                                        }
                                    >
                                        {unsignedContracts.length}
                                    </span>
                                )}
                            </h4>

                            {unsignedContracts.map((contract) => (
                                <div
                                    key={contract.id}
                                    className="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-5"
                                >
                                    {/* <a href="#"> */}
                                    <img className=" rounded-t-lg" src={contract.image} alt="Contract Image" />
                                    {/* </a> */}
                                    <div className="p-5">
                                        {/* <a href="#"> */}
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                            {contract.name}
                                        </h5>
                                        {/* </a> */}
                                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                            {contract.description}
                                        </p>
                                        <a
                                            href="#"
                                            onClick={() => {
                                                if (contract?.position) {
                                                    // Get the customer's processflow progress ID

                                                    const processflowId = usersProgressQuery.data?.usersProgress?.id;
                                                    if (processflowId) {
                                                        window.localStorage.setItem(
                                                            "position-50",
                                                            JSON.stringify({ [processflowId]: contract?.position }),
                                                        );
                                                    }
                                                }
                                                window.localStorage.setItem("tempRoleGroup", "50");
                                                window.location.href = "/processflow-wizard/28";
                                            }}
                                            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            Open
                                            <svg
                                                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 10"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M1 5h12m0 0L9 1m4 4L9 9"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="border rounded-lg p-5 bg-white dark:bg-gray-800 dark:border-gray-700">
                        <h4 className="text-2xl font-bold tracking-tight  text-center text-gray-600 dark:text-white mb-5">
                            Signed Contracts
                        </h4>
                        {/* <p>Contract 1 details</p> */}
                        <div className={"grid grid-cols-" + (unsignedContracts.length > 0 ? "1" : "2") + " gap-5"}>
                            {signedContracts.map((contract) => (
                                <div
                                    key={contract.id}
                                    className="w-full bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700"
                                >
                                    {/* <a href="#"> */}
                                    <img className="rounded-t-lg" src={contract.image} alt="Contract Image" />
                                    {/* </a> */}
                                    <div className="p-5">
                                        {/* <a href="#"> */}
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                            {contract.name}
                                        </h5>
                                        {/* </a> */}
                                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                            {contract.description}
                                        </p>
                                        <a
                                            href="#"
                                            className="mr-3 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            Download
                                            <svg
                                                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 10"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M1 5h12m0 0L9 1m4 4L9 9"
                                                />
                                            </svg>
                                        </a>
                                        <a
                                            href="#"
                                            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            Email
                                            <svg
                                                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 14 10"
                                            >
                                                <path
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M1 5h12m0 0L9 1m4 4L9 9"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const StatusContents = () => {
    return (
        <div className="grid grid-cols-1">
            <div className="p-5 mb-4 border border-gray-100 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                <time className="text-lg font-semibold text-gray-900 dark:text-white">November 13th, 2024</time>
                <ol className="mt-3 divide-y divider-gray-200 dark:divide-gray-700">
                    <li>
                        <a href="#" className="items-center block p-3 sm:flex hover:bg-gray-100 dark:hover:bg-gray-700">
                            <img
                                className="w-12 h-12 mb-3 me-3 rounded-full sm:mb-0"
                                src={jesse}
                                alt="Jese Leos image"
                            />
                            <div className="text-gray-600 dark:text-gray-400">
                                <div className="text-base font-normal">
                                    <span className="font-medium text-gray-900 dark:text-white">Jese Leos</span> likes{" "}
                                    <span className="font-medium text-gray-900 dark:text-white">
                                        Bonnie Green&apos;s
                                    </span>{" "}
                                    post in{" "}
                                    <span className="font-medium text-gray-900 dark:text-white">
                                        {" "}
                                        How to start with Flowbite library
                                    </span>
                                </div>
                                <div className="text-sm font-normal">
                                    &quot;I wanted to share a webinar zeroheight.&quot;
                                </div>
                                <span className="inline-flex items-center text-xs font-normal text-gray-500 dark:text-gray-400">
                                    <svg
                                        className="w-2.5 h-2.5 me-1"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M10 .5a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19ZM8.374 17.4a7.6 7.6 0 0 1-5.9-7.4c0-.83.137-1.655.406-2.441l.239.019a3.887 3.887 0 0 1 2.082 2.5 4.1 4.1 0 0 0 2.441 2.8c1.148.522 1.389 2.007.732 4.522Zm3.6-8.829a.997.997 0 0 0-.027-.225 5.456 5.456 0 0 0-2.811-3.662c-.832-.527-1.347-.854-1.486-1.89a7.584 7.584 0 0 1 8.364 2.47c-1.387.208-2.14 2.237-2.14 3.307a1.187 1.187 0 0 1-1.9 0Zm1.626 8.053-.671-2.013a1.9 1.9 0 0 1 1.771-1.757l2.032.619a7.553 7.553 0 0 1-3.132 3.151Z" />
                                    </svg>
                                    Public
                                </span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="items-center block p-3 sm:flex hover:bg-gray-100 dark:hover:bg-gray-700">
                            <img
                                className="w-12 h-12 mb-3 me-3 rounded-full sm:mb-0"
                                src={profile}
                                alt="Bonnie Green image"
                            />
                            <div>
                                <div className="text-base font-normal text-gray-600 dark:text-gray-400">
                                    <span className="font-medium text-gray-900 dark:text-white">Bonnie Green</span>{" "}
                                    react to{" "}
                                    <span className="font-medium text-gray-900 dark:text-white">
                                        Thomas Lean&apos;s
                                    </span>{" "}
                                    comment
                                </div>
                                <span className="inline-flex items-center text-xs font-normal text-gray-500 dark:text-gray-400">
                                    <svg
                                        className="w-2.5 h-2.5 me-1"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="m2 13.587 3.055-3.055A4.913 4.913 0 0 1 5 10a5.006 5.006 0 0 1 5-5c.178.008.356.026.532.054l1.744-1.744A8.973 8.973 0 0 0 10 3C4.612 3 0 8.336 0 10a6.49 6.49 0 0 0 2 3.587Z" />
                                        <path d="m12.7 8.714 6.007-6.007a1 1 0 1 0-1.414-1.414L11.286 7.3a2.98 2.98 0 0 0-.588-.21l-.035-.01a2.981 2.981 0 0 0-3.584 3.583c0 .012.008.022.01.033.05.204.12.401.211.59l-6.007 6.007a1 1 0 1 0 1.414 1.414L8.714 12.7c.189.091.386.162.59.211.011 0 .021.007.033.01a2.981 2.981 0 0 0 3.584-3.584c0-.012-.008-.023-.011-.035a3.05 3.05 0 0 0-.21-.588Z" />
                                        <path d="M17.821 6.593 14.964 9.45a4.952 4.952 0 0 1-5.514 5.514L7.665 16.75c.767.165 1.55.25 2.335.251 6.453 0 10-5.258 10-7 0-1.166-1.637-2.874-2.179-3.407Z" />
                                    </svg>
                                    Private
                                </span>
                            </div>
                        </a>
                    </li>
                </ol>
            </div>
            <div className="p-5 border border-gray-100 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                <time className="text-lg font-semibold text-gray-900 dark:text-white">November 12th, 2024</time>
                <ol className="mt-3 divide-y divider-gray-200 dark:divide-gray-700">
                    <li>
                        <a href="#" className="items-center block p-3 sm:flex hover:bg-gray-100 dark:hover:bg-gray-700">
                            <img
                                className="w-12 h-12 mb-3 me-3 rounded-full sm:mb-0"
                                src={profile2}
                                alt="Jason Birkett image"
                            />
                            <div className="text-gray-600 dark:text-gray-400">
                                <div className="text-base font-normal">
                                    <span className="font-medium text-gray-900 dark:text-white">Jason Birkett</span>{" "}
                                    likes{" "}
                                    <span className="font-medium text-gray-900 dark:text-white">
                                        Bonnie Green&apos;s
                                    </span>{" "}
                                    post in{" "}
                                    <span className="font-medium text-gray-900 dark:text-white">
                                        {" "}
                                        How to start with Flowbite library
                                    </span>
                                </div>
                                <div className="text-sm font-normal">
                                    &quot;I wanted to share a webinar zeroheight.&quot;
                                </div>
                                <span className="inline-flex items-center text-xs font-normal text-gray-500 dark:text-gray-400">
                                    <svg
                                        className="w-2.5 h-2.5 me-1"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="m2 13.587 3.055-3.055A4.913 4.913 0 0 1 5 10a5.006 5.006 0 0 1 5-5c.178.008.356.026.532.054l1.744-1.744A8.973 8.973 0 0 0 10 3C4.612 3 0 8.336 0 10a6.49 6.49 0 0 0 2 3.587Z" />
                                        <path d="m12.7 8.714 6.007-6.007a1 1 0 1 0-1.414-1.414L11.286 7.3a2.98 2.98 0 0 0-.588-.21l-.035-.01a2.981 2.981 0 0 0-3.584 3.583c0 .012.008.022.01.033.05.204.12.401.211.59l-6.007 6.007a1 1 0 1 0 1.414 1.414L8.714 12.7c.189.091.386.162.59.211.011 0 .021.007.033.01a2.981 2.981 0 0 0 3.584-3.584c0-.012-.008-.023-.011-.035a3.05 3.05 0 0 0-.21-.588Z" />
                                        <path d="M17.821 6.593 14.964 9.45a4.952 4.952 0 0 1-5.514 5.514L7.665 16.75c.767.165 1.55.25 2.335.251 6.453 0 10-5.258 10-7 0-1.166-1.637-2.874-2.179-3.407Z" />
                                    </svg>
                                    Private
                                </span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="items-center block p-3 sm:flex hover:bg-gray-100 dark:hover:bg-gray-700">
                            <img className="w-12 h-12 mb-3 me-3 rounded-full sm:mb-0" src={sa} alt="Mike Willi image" />
                            <div>
                                <div className="text-base font-normal text-gray-600 dark:text-gray-400">
                                    <span className="font-medium text-gray-900 dark:text-white">Mike Willi</span> react
                                    to{" "}
                                    <span className="font-medium text-gray-900 dark:text-white">
                                        Thomas Lean&apos;s
                                    </span>{" "}
                                    comment
                                </div>
                                <span className="inline-flex items-center text-xs font-normal text-gray-500 dark:text-gray-400">
                                    <svg
                                        className="w-2.5 h-2.5 me-1"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M10 .5a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19ZM8.374 17.4a7.6 7.6 0 0 1-5.9-7.4c0-.83.137-1.655.406-2.441l.239.019a3.887 3.887 0 0 1 2.082 2.5 4.1 4.1 0 0 0 2.441 2.8c1.148.522 1.389 2.007.732 4.522Zm3.6-8.829a.997.997 0 0 0-.027-.225 5.456 5.456 0 0 0-2.811-3.662c-.832-.527-1.347-.854-1.486-1.89a7.584 7.584 0 0 1 8.364 2.47c-1.387.208-2.14 2.237-2.14 3.307a1.187 1.187 0 0 1-1.9 0Zm1.626 8.053-.671-2.013a1.9 1.9 0 0 1 1.771-1.757l2.032.619a7.553 7.553 0 0 1-3.132 3.151Z" />
                                    </svg>
                                    Public
                                </span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="items-center block p-3 sm:flex hover:bg-gray-100 dark:hover:bg-gray-700">
                            <img
                                className="w-12 h-12 mb-3 me-3 rounded-full sm:mb-0"
                                src={jesse}
                                alt="Jese Leos image"
                            />
                            <div className="text-gray-600 dark:text-gray-400">
                                <div className="text-base font-normal">
                                    <span className="font-medium text-gray-900 dark:text-white">Jese Leos</span> likes{" "}
                                    <span className="font-medium text-gray-900 dark:text-white">
                                        Bonnie Green&apos;s
                                    </span>{" "}
                                    post in{" "}
                                    <span className="font-medium text-gray-900 dark:text-white">
                                        {" "}
                                        How to start with Flowbite library
                                    </span>
                                </div>
                                <div className="text-sm font-normal">
                                    &quot;I wanted to share a webinar zeroheight.&quot;
                                </div>
                                <span className="inline-flex items-center text-xs font-normal text-gray-500 dark:text-gray-400">
                                    <svg
                                        className="w-2.5 h-2.5 me-1"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M10 .5a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19ZM8.374 17.4a7.6 7.6 0 0 1-5.9-7.4c0-.83.137-1.655.406-2.441l.239.019a3.887 3.887 0 0 1 2.082 2.5 4.1 4.1 0 0 0 2.441 2.8c1.148.522 1.389 2.007.732 4.522Zm3.6-8.829a.997.997 0 0 0-.027-.225 5.456 5.456 0 0 0-2.811-3.662c-.832-.527-1.347-.854-1.486-1.89a7.584 7.584 0 0 1 8.364 2.47c-1.387.208-2.14 2.237-2.14 3.307a1.187 1.187 0 0 1-1.9 0Zm1.626 8.053-.671-2.013a1.9 1.9 0 0 1 1.771-1.757l2.032.619a7.553 7.553 0 0 1-3.132 3.151Z" />
                                    </svg>
                                    Public
                                </span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="items-center block p-3 sm:flex hover:bg-gray-100 dark:hover:bg-gray-700">
                            <img
                                className="w-12 h-12 mb-3 me-3 rounded-full sm:mb-0"
                                src={sa}
                                alt="Bonnie Green image"
                            />
                            <div className="text-gray-600 dark:text-gray-400">
                                <div className="text-base font-normal">
                                    <span className="font-medium text-gray-900 dark:text-white">Bonnie Green</span>{" "}
                                    likes{" "}
                                    <span className="font-medium text-gray-900 dark:text-white">
                                        Bonnie Green&apos;s
                                    </span>{" "}
                                    post in{" "}
                                    <span className="font-medium text-gray-900 dark:text-white">
                                        {" "}
                                        Top figma designs
                                    </span>
                                </div>
                                <div className="text-sm font-normal">
                                    @quot;I wanted to share a webinar zeroheight.@quot;
                                </div>
                                <span className="inline-flex items-center text-xs font-normal text-gray-500 dark:text-gray-400">
                                    <svg
                                        className="w-2.5 h-2.5 me-1"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="m2 13.587 3.055-3.055A4.913 4.913 0 0 1 5 10a5.006 5.006 0 0 1 5-5c.178.008.356.026.532.054l1.744-1.744A8.973 8.973 0 0 0 10 3C4.612 3 0 8.336 0 10a6.49 6.49 0 0 0 2 3.587Z" />
                                        <path d="m12.7 8.714 6.007-6.007a1 1 0 1 0-1.414-1.414L11.286 7.3a2.98 2.98 0 0 0-.588-.21l-.035-.01a2.981 2.981 0 0 0-3.584 3.583c0 .012.008.022.01.033.05.204.12.401.211.59l-6.007 6.007a1 1 0 1 0 1.414 1.414L8.714 12.7c.189.091.386.162.59.211.011 0 .021.007.033.01a2.981 2.981 0 0 0 3.584-3.584c0-.012-.008-.023-.011-.035a3.05 3.05 0 0 0-.21-.588Z" />
                                        <path d="M17.821 6.593 14.964 9.45a4.952 4.952 0 0 1-5.514 5.514L7.665 16.75c.767.165 1.55.25 2.335.251 6.453 0 10-5.258 10-7 0-1.166-1.637-2.874-2.179-3.407Z" />
                                    </svg>
                                    Private
                                </span>
                            </div>
                        </a>
                    </li>
                </ol>
            </div>
        </div>
    );
};

const tabs = [
    {
        name: "Home",
        icon: "fa-home",
        content: <HomeContents2 />,
        active: true,
        unread: 0,
    },
    // {
    //     name: "Pending",
    //     icon: "fa-exclamation-circle",
    //     content: "Pending",
    //     unread: 7,
    // },
    // {
    //     name: "Application",
    //     icon: "fa-file",
    //     content: <ApplicationContents />,
    //     active: false,
    //     unread: 0,
    // },
    {
        name: "Contracts",
        icon: "fa-file-contract",
        content: <ContractContents />,
        active: false,
        unread: 2,
    },
    {
        name: "Files",
        icon: "fa-folder-open",
        content: <FilesContents />,
        unread: 0,
    },
    {
        name: "Contacts",
        icon: "fa-id-card",
        content: <ContactsContents />,
        unread: 0,
    },
    {
        name: "Appointments",
        icon: "fa-calendar",
        content: <AppointmentsContents />,
        unread: 1,
    },
    {
        name: "Status",
        icon: "fa-spinner",
        content: <StatusContents />,
        unread: 0,
    },
];

function TabbedTopSection() {
    const [activeTab, setActiveTab] = React.useState(tabs[0]);
    return (
        <div className="bg-gradient-to-br to-slate-100 from-slate-200 dark:from-slate-900 dark:to-gray-800 border border-white rounded-xl  dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner min-h-32 grid grid-cols-1 content-center shadow mt-5 p-5">
            <div className="md:flex">
                <ul className="flex-column space-y space-y-4 text-sm font-medium text-gray-500 dark:text-gray-400 md:me-4 mb-4 md:mb-0">
                    {tabs.map((tab, index) => (
                        <li key={index}>
                            <a
                                href="#"
                                onClick={() => {
                                    setActiveTab(tab);
                                }}
                                className={`inline-flex items-center px-4 py-3 rounded-lg    w-full  ${
                                    activeTab.name === tab.name
                                        ? "bg-blue-700 text-white "
                                        : "bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white hover:text-gray-900 hover:bg-blue-50 "
                                }`}
                            >
                                <span
                                    className={`text-lg w-4 text-center ${
                                        (activeTab.name === tab.name ? "fas " : "fal ") + tab.icon
                                    } mr-3`}
                                ></span>
                                {tab.name}
                                {tab.unread > 0 && (
                                    <span
                                        className={
                                            "bg-red-500 text-white rounded-full ml-2 " +
                                            (tab.unread === 1 ? "px-2" : "px-1.5") +
                                            " py-0 "
                                        }
                                    >
                                        {tab.unread}
                                    </span>
                                )}
                            </a>
                        </li>
                    ))}
                    {/* // <li>
                    //     <a
                    //         href="#"
                    //         className="inline-flex items-center px-4 py-3 text-white bg-blue-700 rounded-lg active w-full dark:bg-blue-600"
                    //         aria-current="page"
                    //     >
                    //         <span className="fas fa-home mr-3"></span>
                    //         Home
                    //     </a>
                    // </li> */}

                    {/* <li>
                        <a
                            href="#"
                            className="inline-flex items-center px-4 py-3 rounded-lg hover:text-gray-900 bg-gray-50 hover:bg-gray-100 w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            <span className="fas fa-file mr-3"></span>
                            Application
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            className="inline-flex items-center px-4 py-3 rounded-lg hover:text-gray-900 bg-gray-50 hover:bg-gray-100 w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            <span className="fas fa-file-contract mr-3"></span>
                            Contracts
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            className="inline-flex items-center px-4 py-3 rounded-lg hover:text-gray-900 bg-gray-50 hover:bg-gray-100 w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            <span className="fas fa-folder-open mr-3"></span>
                            Files
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            className="inline-flex items-center px-4 py-3 rounded-lg hover:text-gray-900 bg-gray-50 hover:bg-gray-100 w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            <span className="fas fa-id-card mr-3"></span>
                            Contacts
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            className="inline-flex items-center px-4 py-3 rounded-lg hover:text-gray-900 bg-gray-50 hover:bg-gray-100 w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            <span className="fas fa-calendar mr-3"></span>
                            Appointments
                        </a>
                    </li> */}
                </ul>
                {tabs.find((t) => t.name === activeTab.name)?.content}
            </div>
        </div>
    );
}

export default TabbedTopSection;
