import { RoleGroup } from "../../../role-groups/domain/role-group";
import { Section, Stat } from "../../../affiliate-billing/affiliate-deals-page";
import { Currency } from "../../../billing/components";
import { TBody, THead, Table, Td, Tr } from "../../../layout/table";
import ButtonNeoGen from "../../../layout/button-neogen";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import { useReferrers } from "../../referrers/hooks/use-referrers";
import { useAuth } from "../../../auth/use-auth";
import { useMemo, useState } from "react";
import { AddAffiliateToReferrerModal } from "../../referrers/components/add-affiliate-to-referrer-modal";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useUserCompanies } from "../../../companies/hooks/use-user-companies";
import { useCompanies } from "../../../companies/hooks/use-companies";
import lineItemsService from "../../../services/line-items.service";
import invoicesService from "../../../services/invoices.service";
import invoiceLineItemsService from "../../../services/invoice-line-items.service";
import companyRepairStagesService from "../../../services/company-repair-stages.service";
import { usePayments } from "../../../payments/hooks/use-payments";
import { affiliateCommissionPercentage } from "../../../affiliate-billing/affiliate-repair-deals-page";

export const ReferrerContent = ({
    darkMode,
    activeRole,
    usersQuery,
    currentUser,
}: {
    darkMode: boolean;
    activeRole: undefined | null | RoleGroup;
    usersQuery: UseQueryResult<ClearERCUser[] | undefined, unknown>;
    currentUser: ClearERCUser;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const [showAddAffiliateToReferrerModal, setShowAddAffiliateToReferrerModal] = useState(false);
    const referrerRelationsQuery = useReferrers({ authToken, filters: { where: { referrerId: currentUser?.id } } });
    const [sortUsers, setSortUsers] = useState<"asc" | "desc">("desc");
    const referrerRelations = useMemo(
        () =>
            (referrerRelationsQuery.data || []).sort((a, b) => {
                if (sortUsers === "asc") {
                    return a.affiliateUser?.firstName?.localeCompare(b.affiliateUser?.firstName || "") || -1;
                } else {
                    return b.affiliateUser?.firstName?.localeCompare(a.affiliateUser?.firstName || "") || 1;
                }
            }),
        [referrerRelationsQuery.data, sortUsers],
    );
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);
    const affiliateIds = useMemo(() => referrerRelations.map((r) => r.affiliateId), [referrerRelations]);
    const [totalCommission, setTotalCommission] = useState(0);
    const [outstandingCommission, setOutstandingCommission] = useState(0);
    const [paidCommission, setPaidCommission] = useState(0);

    // Fetch data for referrer's affiliates
    const userCompaniesQuery = useUserCompanies({
        authToken,
        filters: {
            where: {
                roleGroupId: 7,
                ercUserId: { inq: affiliateIds },
            },
        },
    });

    const userCompanies = useMemo(() => userCompaniesQuery.data || [], [userCompaniesQuery.data]);

    const companiesQuery = useCompanies({
        authToken,
        filters: { where: { id: { inq: (userCompanies || []).map((uc) => uc.companyId) } } },
    });
    const companies = useMemo(() => companiesQuery.data || [], [companiesQuery.data]);

    const lineItemsQuery = useQuery(["line-items"], async () => {
        const response = await lineItemsService.getAll();
        if (response) {
            return response.data;
        }
    });

    const lineItems = useMemo(() => lineItemsQuery.data || [], [lineItemsQuery.data]);

    const invoicesQuery = useQuery(["invoices", companies], async () => {
        const response = await invoicesService.getFilteredWhere({ companyId: { inq: companies.map((c) => c.id) } });
        if (response) {
            return response.data;
        }
    });

    const companyInvoices = useMemo(() => invoicesQuery.data || [], [invoicesQuery.data]);

    const invoiceLineItemsQuery = useQuery(["invoice-line-items", companies, companyInvoices], async () => {
        const response = await invoiceLineItemsService.getFilteredWhere({
            invoiceId: { inq: companyInvoices.map((c) => c.id) },
        });
        if (response) {
            return response.data;
        }
    });

    const invoiceLineItems = useMemo(() => invoiceLineItemsQuery.data || [], [invoiceLineItemsQuery.data]);

    const companyRepairStageQuery = useQuery(["companyRepairStages"], async () => {
        const response = await companyRepairStagesService.getAll();
        if (response) {
            return response.data;
        }
    });

    const paymentsQuery = usePayments({
        authToken,
        filters: { where: { toUserId: { inq: affiliateIds } } },
    });
    const payments = useMemo(() => paymentsQuery.data || [], [paymentsQuery.data]);

    const repairDeals = companyRepairStageQuery.data?.filter((item) => companies.find((c) => c.id === item.id));
    const companiesWithRepairDeals = companies.filter((c) => repairDeals?.find((rd) => rd.id === c.id));

    const rows = referrerRelations.map((r) => {
        const commissionEarnedOnAffiliateDeals = r.commissionPercentage;
        const affiliate = r.affiliateUser;
        const userCompaniesForAffiliate = userCompanies.filter(
            (uc) => uc.ercUserId === r.affiliateId && companiesWithRepairDeals.find((c) => c.id === uc.companyId),
        );

        const deals = userCompaniesForAffiliate.map((uc) => {
            const company = companiesWithRepairDeals.find((c) => c.id === uc.companyId);
            const invoices = companyInvoices.filter((i) => i.companyId === company?.id);
            const totalCommissionableLineItems = invoices.reduce((acc, invoice) => {
                const invLineItems = invoiceLineItems.filter((li) => li.invoiceId === invoice.id);
                invLineItems.map((item) => {
                    const li = lineItems.find((i) => i.id === item.lineItemId);
                    if (li?.isCommisionable === 1) {
                        acc += item.price;
                    }
                });
                return acc;
            }, 0);
            const totalDue =
                totalCommissionableLineItems * affiliateCommissionPercentage * (commissionEarnedOnAffiliateDeals / 100);

            return {
                company,
                totalDue,
            };
        });

        return {
            id: r.id,
            affiliate,
            commission: commissionEarnedOnAffiliateDeals,
            deals,
        };
    });

    const sumTotalCommission = rows.reduce(
        (acc, row) => acc + row.deals.reduce((acc, deal) => acc + deal.totalDue, 0),
        0,
    );

    useMemo(() => setTotalCommission(sumTotalCommission), [sumTotalCommission]);
    useMemo(() => setOutstandingCommission(sumTotalCommission), [sumTotalCommission]);

    return (
        <div className="mt-5 flex flex-col gap-5">
            {showAddAffiliateToReferrerModal && (
                <AddAffiliateToReferrerModal
                    hideCommission={true}
                    onClose={() => setShowAddAffiliateToReferrerModal(false)}
                    referrer={currentUser as User}
                    users={users as User[]}
                    onCreated={() => {
                        referrerRelationsQuery.refetch();
                        usersQuery.refetch();
                        setShowAddAffiliateToReferrerModal(false);
                    }}
                />
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 20,
                    flex: 1,
                    justifyContent: "space-between",
                }}
            >
                <Section
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                    }}
                    className="w-full"
                >
                    <Stat title="Total Commission Due" value={<Currency amount={totalCommission} />} />
                    <Stat title="Outstanding Commission" value={<Currency amount={outstandingCommission} />} />
                    {/* <Stat title="Paid Commission" value={<Currency amount={paidCommission} />} /> */}
                </Section>
            </div>
            <div className="flex justify-between items-center">
                <p>Referred Users</p>
                <ButtonNeoGen onClick={() => setShowAddAffiliateToReferrerModal(true)}>Refer new user</ButtonNeoGen>
            </div>
            {rows.length > 0 ? (
                <Table>
                    <THead>
                        <Tr>
                            <Td style={{ width: "30%", display: "flex", gap: 10 }}>
                                User{" "}
                                <button
                                    className={sortUsers === "asc" ? "fa fa-caret-down" : "fa fa-caret-up"}
                                    onClick={() => setSortUsers(sortUsers === "asc" ? "desc" : "asc")}
                                />
                            </Td>
                            <Td>Commission %</Td>
                            <Td style={{ width: "50%" }}>Deals (Company, Commission Due)</Td>
                        </Tr>
                    </THead>

                    <TBody>
                        {rows?.map((row) => (
                            <Tr key={row.id}>
                                <Td>
                                    {row.affiliate?.firstName} {row.affiliate?.lastName}
                                </Td>
                                <Td>{row.commission}%</Td>
                                <Td>
                                    {row.deals.length > 0 ? (
                                        <Table>
                                            <TBody>
                                                {row.deals.map((deal) => (
                                                    <Tr key={deal.company?.id}>
                                                        <Td>{deal.company?.name}</Td>
                                                        <Td>
                                                            <Currency amount={deal.totalDue} />
                                                        </Td>
                                                        {/* <Td>-</Td> */}
                                                    </Tr>
                                                ))}
                                            </TBody>
                                        </Table>
                                    ) : (
                                        <p className="text-left">No deals yet</p>
                                    )}
                                </Td>
                            </Tr>
                        ))}
                    </TBody>
                </Table>
            ) : (
                <p className="text-center">No data yet</p>
            )}
        </div>
    );
};
