import React, { useEffect, useState } from "react";
import ModalDialog from "../../../layout/modal-dialog";
import { Document, Page } from "react-pdf";
import ButtonNeoGen from "../../../layout/button-neogen";
import { getCompanies } from "../../../companies/actions/get-companies";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import invoiceLineItemsService from "../../../services/invoice-line-items.service";
import TaxPayerAdvocate from "./taxPayerAdvocate";
import Transcripts from "./transcripts";
import { getProcessflowProgresses } from "../../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
const packagesAvailable = [
    {
        title: "2848 Form",
        icon: "fal fa-file-contract",
        active: false,
        bg: "cyan",
        tempRoleGroup: 57,
        url: "/processflow-wizard/28",
        position: { processflowStageId: 234, processflowId: 562 },
    },
    {
        title: "Pre Underwriting",
        icon: "fas fa-signature",
        active: false,
        bg: "orange",
        tempRoleGroup: 59,
        url: "/processflow-wizard/28",
        position: { processflowStageId: 245, processflowId: 590 },
    },

    {
        title: "Full Underwriting",
        icon: "fal fa-pen-field",
        active: false,
        bg: "purple",
        url: "/processflow-wizard/28",
        tempRoleGroup: 60,
        position: { processflowStageId: 243, processflowId: 577 },
    },

    // {
    //     title: "Tax Advocacy Service",
    //     icon: "fal fa-headset",
    //     active: false,
    //     bg: "yellow",
    //     lineItemIds: [24],
    //     filename: "/files/erc-document-266431d8-7eb2-4670-8134-7197ae6662dd.pdf",
    //     activeItem: <TaxPayerAdvocate />,
    // },
    // {
    //     title: "ERC Track & Trace",
    //     icon: "fal fa-location-dot",
    //     active: false,
    //     bg: "cyan",
    //     filename: "/files/erc-document-742e6866-ac89-4fe5-b5e3-185a63fb0103.pdf",
    //     lineItemIds: [26],
    //     activeItem: <Transcripts />,
    // },
    // {
    //     title: "Other Tax Credits",
    //     icon: "fal fa-money-check-dollar",
    //     active: false,
    //     bg: "red",
    // },
];
const FILE_API_URL = process.env.REACT_APP_FILE_SERVER ?? process.env.REACT_APP_API_URL ?? "https://api.clearerc.com/";
function Links() {
    const [showFileUpload, setShowFileUpload] = React.useState(false);
    const [file, setFile] = React.useState("");
    const [active, setActive] = React.useState(false);
    const [activeItem, setActiveItem] = React.useState("");
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [packages, setPackages] = useState<any[]>([]);
    const authToken = getAuthTokenNoThrow() || "no-token";

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
        setPageNumber(1);
    }
    useEffect(() => {
        const companies = getCompanies({ authToken }).then(async (response) => {
            // console.log("packagesAvailable", packagesAvailable);
            // console.log({ response });
            // Go through each company
            for (const company of response) {
                // Go through each package and check if the user has the line item
                // If they do, set the package to active
                // If they don't, set the package to inactive
                // If the package has no line items, set the package to inactive
                // console.log("Checking company id:" + company.id);
                for (let i = 0; i < packagesAvailable.length; i++) {
                    // const packageItem = packagesAvailable[i];
                    // if (packageItem.lineItemIds) {
                    //     let found = false;
                    //     for (const lineItemId of packageItem.lineItemIds) {
                    //         console.log("Checking line item id:" + lineItemId);
                    //         if (await invoiceLineItemsService.checkForPresence(company.id, lineItemId)) {
                    //             console.log("Found line item id:" + lineItemId);
                    //             found = true;
                    //             break;
                    //         }
                    //     }
                    //     packageItem.active = found;
                    // } else {
                    //     packageItem.active = false;
                    // }
                }
                // console.log("packagesAvailable", packagesAvailable);
                setPackages(packagesAvailable);
            }
        });
    }, [authToken]);
    return (
        <div className="grid grid-cols-6 gap-5">
            {packages.map((packageItem) => {
                return (
                    <div key={packageItem.title} className="col-span-2">
                        {/* <div className="relative w-full ">
                            <div className="absolute top-2 right-2 h-10 w-20">
                                <div
                                    className={
                                        "bg-white dark:bg-gray-800 dark:text-gray-400 p-0 pt-1 rounded-full text-center " +
                                        (packageItem.active ? "border border-green-500" : "")
                                    }
                                >
                                    {packageItem.active && <i className="fas fa-check-circle text-green-600 " />}
                                    <span
                                        className={
                                            "text-xs " +
                                            (packageItem.active ? "ml-1" : "") +
                                            " inline-block align-text-top"
                                        }
                                    >
                                        {packageItem.active ? "Active" : "Available"}
                                    </span>
                                </div>
                            </div>
                        </div> */}
                        <div
                            className={
                                "text-gray-700 flex border p-5 bg-" +
                                packageItem.bg +
                                "-400 bg-gradient-to-br " +
                                " from-" +
                                packageItem.bg +
                                "-100 " +
                                " dark:from-" +
                                packageItem.bg +
                                "-900 " +
                                +"hover:bg-" +
                                packageItem.bg +
                                "-100 dark:bg-" +
                                packageItem.bg +
                                "-600 dark:hover:bg-" +
                                packageItem.bg +
                                "-800 border-gray-300 dark:border-gray-600 dark:text-gray-400 cursor-pointer text-center h-36 m-0 rounded-xl hover:shadow-none shadow-lg dark:shadow-sm hover:border-blue-500"
                            }
                            onClick={async () => {
                                console.log("clicked");
                                // window.open(FILE_API_URL + packageItem.filename, "_blank");
                                if (packageItem.filename) {
                                    setFile(FILE_API_URL + packageItem.filename);
                                    setShowFileUpload(true);
                                    setActive(packageItem.active);
                                    setActiveItem(packageItem.activeItem);
                                } else if (packageItem.url) {
                                    // window.localStorage.removeItem("tempRoleGroup");
                                    if (packageItem.tempRoleGroup) {
                                        window.localStorage.setItem("tempRoleGroup", packageItem.tempRoleGroup);
                                    }
                                    if (packageItem.position) {
                                        // Get the customer's processflow progress ID
                                        const [usersProgress] = await getProcessflowProgresses({
                                            authToken,
                                            filters: { id: packageItem.position.processFlowId, group: 28 },
                                        });
                                        console.error("usersProgress", usersProgress);
                                        const processflowId = usersProgress.id;
                                        window.localStorage.setItem(
                                            "position-" + packageItem.tempRoleGroup,
                                            JSON.stringify({ [processflowId]: packageItem.position }),
                                        );
                                    }
                                    window.location.href = packageItem.url;
                                } else {
                                    alert("Information coming soon");
                                }
                            }}
                        >
                            <div className="dark:text-gray-400 mt-auto card-body align-items-center d-flex justify-content-center flex-column my-auto text-center mx-auto ">
                                <div className={"flex-grow"}>
                                    <div className="mb-3 mt-2 my-auto text-3xl dark:text-green-400">
                                        <i className={"fa-thin " + packageItem.icon + " "} />
                                    </div>
                                </div>
                                <div className=" text-xl font-light dark:text-green-400 mt-5">{packageItem.title}</div>
                            </div>
                        </div>
                        {/* <RecordingButton title={"Free ERC Repair Evaluation"} icon={"fal fa-check-circle"} /> */}
                    </div>
                );
            })}
            {showFileUpload && (
                <ModalDialog
                    size={active ? "lg" : "xl"}
                    title={""}
                    close={() => setShowFileUpload(false)}
                    show={true}
                    showOk={false}
                    showCancel={false}
                >
                    <div className="">
                        {active ? (
                            <>{activeItem}</>
                        ) : (
                            <>
                                <div className="flex flex-row items-center gap-2 w-full place-content-center ">
                                    <ButtonNeoGen
                                        disabled={pageNumber === 1}
                                        onClick={() => {
                                            const pn = numPages && pageNumber > 1 ? pageNumber - 1 : pageNumber;
                                            setPageNumber(pn);
                                        }}
                                    >
                                        Back
                                    </ButtonNeoGen>
                                    Page {pageNumber} of {numPages}
                                    <ButtonNeoGen
                                        disabled={pageNumber === numPages}
                                        onClick={() => {
                                            const pn = numPages && pageNumber < numPages ? pageNumber + 1 : pageNumber;
                                            setPageNumber(pn);
                                        }}
                                    >
                                        Next
                                    </ButtonNeoGen>
                                </div>
                                <div className="mt-5 text-center">
                                    <Document
                                        file={file}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        className={"text-center flex flex-col items-center"}
                                    >
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                </div>
                            </>
                        )}
                    </div>
                </ModalDialog>
            )}
        </div>
    );
}

export default Links;
