import { useQuery } from "@tanstack/react-query";
import user from "pusher-js/types/src/core/user";
import React from "react";
import interactionsService from "../../../services/interactions.service";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import PrintPre from "../../../layout/print-pre";
import ButtonNeoGen from "../../../layout/button-neogen";
import TableNeogen from "../../../layout/table-neogen";

function Messaging() {
    const [user] = useRecoilState(userAtom);
    const messagesQuery = useQuery(["messages", user.id], async () => {
        const response = await interactionsService.getAll();
        if (response) {
            return response.data;
        }
    });
    if (messagesQuery.isLoading) return <div>Loading...</div>;
    return (
        <div>
            {/* <div className="my-5">
                <ButtonNeoGen onClick={() => console.log("clicked")}>New Message</ButtonNeoGen>
            </div> */}
            {messagesQuery.data?.length === 0 ? (
                "No messages"
            ) : (
                <TableNeogen
                    formatters={[
                        {
                            field: "Date",
                            type: "Date",
                        },
                        {
                            field: "From",
                            type: "UserObject",
                        },
                    ]}
                    entries={(messagesQuery.data ?? []).map((message: any) => {
                        return {
                            id: message.id,
                            Message:
                                message.activity?.length > 50
                                    ? message.activity.substring(0, 50) + "..."
                                    : message.activity,
                            Date: message.dateCreated,
                            From: message.interactionFromUser,
                        };
                    })}
                />
            )}
        </div>
    );
}

export default Messaging;
