import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import PrintPre from "../../../layout/print-pre";
import { useCompanies } from "../../../companies/hooks/use-companies";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { useQuery } from "@tanstack/react-query";
import userCompanyService from "../../../services/user-company.service";
import usersService from "../../../services/users.service";
import roleGroupService from "../../../services/role-group.service";
import ButtonNeoGen from "../../../layout/button-neogen";
import ChatModal from "./modals/chatModal";

const roleGroupsToShow = [7, 8, 13, 14, 15, 16, 17, 21, 24, 25, 26, 27, 29, 38];

function MyContacts() {
    const [user] = useRecoilState(userAtom);
    const [contacts, setContacts] = useState<any[]>([]);
    const [showChatModal, setShowChatModal] = useState(false);
    const [userToChatWith, setUserToChatWith] = useState<any>();
    const authToken = getAuthTokenNoThrow();
    const companies = useCompanies({
        authToken: authToken ?? "",
    });
    const userCompaniesQuery = useQuery(
        ["userCompanies", user.id],
        async () => {
            const response = await userCompanyService.getFilteredWhere({ companyId: companies.data?.[0].id });
            if (response) {
                return response.data;
            }
        },
        { enabled: !!companies.data },
    );
    const userCompaniesUsersQuery = useQuery(["userCompaniesUsers", user.id], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data;
        }
    });
    const roleGroupsQuery = useQuery(["roleGroups"], async () => {
        const response = await roleGroupService.getAll();
        if (response) {
            return response.data;
        }
    });

    useEffect(() => {
        const usersToShow = userCompaniesQuery.data?.filter((uc) => roleGroupsToShow.includes(uc.roleGroupId));
        const internalContacts: any[] = [];
        usersToShow?.forEach((userToShow) => {
            const user = userCompaniesUsersQuery.data?.find((u) => u.id === userToShow.ercUserId);
            const roleGroup = roleGroupsQuery.data?.find((rg) => rg.id === userToShow.roleGroupId);
            console.log(user, roleGroup);
            internalContacts.push({
                user,
                roleGroup,
            });
        });
        setContacts(internalContacts ?? []);
    }, [roleGroupsQuery.data, userCompaniesQuery.data, userCompaniesUsersQuery.data]);

    return (
        <div>
            <p className="mt-0 text-left text-xl font-light tracking-wider text-gray-600 sm:text-2xl dark:text-green-400 ">
                Contacts
            </p>
            {contacts
                .filter((c) => c.user)
                ?.map((contact) => (
                    <div
                        key={contact.user?.id}
                        className="border border-gray-300 rounded-lg mt-5 p-5 bg-gradient-to-br from-white to-slate-100 shadow dark:from-gray-900 dark:border-gray-700"
                    >
                        <p>
                            {contact.user?.firstName} {contact.user?.lastName}
                        </p>
                        <p>{contact.roleGroup?.name}</p>
                        <p>Phone: {contact.user?.phone}</p>
                        <p>
                            Email: <a href={"mailto:" + contact.user?.email}>{contact.user?.email}</a>
                        </p>
                        <div className="mt-3">
                            <ButtonNeoGen
                                size="xs"
                                onClick={() => {
                                    setUserToChatWith(contact.user);
                                    setShowChatModal(true);
                                }}
                            >
                                Start Chat
                            </ButtonNeoGen>
                        </div>
                    </div>
                ))}
            <ChatModal show={showChatModal} close={() => setShowChatModal(false)} whoTo={userToChatWith} />
        </div>
    );
}

export default MyContacts;
