/* eslint-disable react/no-unescaped-entities */
import axios from "axios";
import { useAuth } from "../auth/use-auth";
import { getUrl } from "./get-url";
import { useFinchConnect } from "@tryfinch/react-connect";
import { makeRequest } from "../jason-proof-of-concept/shared/utils";
import { useMutation } from "@tanstack/react-query";
import ButtonNeoGen from "../layout/button-neogen";
import { useState } from "react";
import Logo from "./sure-advisors-logo.png";
import {
    IconArrowLeft,
    IconArrowRight,
    IconCash,
    IconCashBanknote,
    IconCashOff,
    IconCashRegister,
    IconClock,
    IconEdit,
    IconFileInvoice,
    IconGlobe,
    IconGraph,
    IconInvoice,
    IconLock,
    IconMoneybag,
    IconPlugConnected,
    IconPlugX,
    IconQuote,
    IconTax,
    IconUpload,
    IconUsers,
} from "@tabler/icons-react";
import Loader3 from "../sections/utilities/Loader3";
import { useNavigate } from "react-router";

export const POCPage = () => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const companyId = 123;
    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const createLinkMutation = useMutation({
        mutationFn: async ({ code, companyId }: { code: string; companyId: number }) => {
            await makeRequest({ method: "post", path: "/finch/link", authToken, data: { code, companyId } });
        },
    });

    const { open } = useFinchConnect({
        clientId: process.env.REACT_APP_FINCH_CLIENT_ID,
        products: ["company", "directory", "individual", "ssn", "benefits", "payment", "pay_statement", "employment"],
        sandbox: "finch",
        onSuccess: async (data) => {
            console.log("onSuccess", data);
            createLinkMutation.mutate({ code: data.code, companyId });
            setStep(3);
            setIsLoading(false);
        },
        onError: (error) => {
            console.log("onError", error);
        },
        onClose: () => {
            console.log("onClose");
            setIsLoading(false);
        },
    });

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "calc(100vh - 100px)",
                background: "#f9f9f9",
            }}
        >
            <div
                style={{
                    width: 550,
                    border: "1px solid lightgray",
                    borderRadius: 30,
                    padding: 40,
                    paddingTop: 40,
                    paddingBottom: 40,
                    boxShadow: "0px 0px 30px 5px lightgray",
                    background: "white",
                }}
            >
                {step === 1 && (
                    <div>
                        <h2 style={{ textAlign: "center", paddingBottom: 20 }}>
                            <img src={Logo} width={250} style={{ margin: "auto", borderRadius: 10 }} />
                        </h2>
                        <div style={{ paddingBottom: 10 }}>
                            <h1 style={{ textAlign: "center", fontWeight: 600, fontSize: 42, lineHeight: 1 }}>
                                Enroll in PCMP and Save on FICA Taxes
                            </h1>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: 20,
                                paddingBottom: 12,
                            }}
                        >
                            <div style={{ width: "100%", textAlign: "center", color: "gray", fontSize: 16 }}>
                                PCMP offers a tax-efficient and flexible solution for businesses to provide wellness
                                benefits to employees while reducing both employer and employee tax liabilities — all
                                while ensuring compliance with IRS regulations.
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: 28,
                                paddingBottom: 18,
                            }}
                        >
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 30 }}>
                                <div>
                                    <div style={{ fontWeight: 600, fontSize: 19, paddingBottom: 5 }}>
                                        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                            <div>
                                                <IconUsers />
                                            </div>
                                            <div>Boost Employee Wellness</div>
                                        </div>
                                    </div>
                                    <div style={{ fontSize: 15 }}>
                                        Provide your employees with tax-deductible wellness benefits designed to support
                                        their physical, mental, and financial health, leading to a happier, healthier
                                        workforce.
                                    </div>
                                </div>
                                {/* <div>
                                    <div style={{ fontWeight: 600, fontSize: 19, paddingBottom: 5 }}>
                                        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                            <div>
                                                <IconGraph />
                                            </div>
                                            <div>Increase Employee Retention And Satisfaction</div>
                                        </div>
                                    </div>
                                    <div style={{ fontSize: 15 }}>
                                        By offering Section 125 benefits to your employees, you can increase employee
                                        retention and satisfaction by letting your employees make use of tax-efficient
                                        benefits.
                                    </div>
                                </div> */}
                                <div>
                                    <div style={{ fontWeight: 600, fontSize: 19, paddingBottom: 5 }}>
                                        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                            <div>
                                                <IconTax />
                                            </div>
                                            <div>Reduce Taxes for You and Your Employees</div>
                                        </div>
                                    </div>
                                    <div style={{ fontSize: 15 }}>
                                        By implementing PCMP, your business can save up to <b>7.65%</b> on payroll taxes
                                        — an average of <b>$685</b> per employee annually.
                                    </div>
                                </div>
                                {/* <div>
                                    <div style={{ fontWeight: 600, fontSize: 19, paddingBottom: 5 }}>
                                        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                            <div>
                                                <IconCashRegister />
                                            </div>
                                            <div>See How Much You Can Save</div>
                                        </div>
                                    </div>
                                    <div style={{ fontSize: 15 }}>
                                        SureAdvisors partners with <a href="https://finch.com">Finch</a> to connect
                                        directly to your payroll provider to analyze your payroll and show you how much
                                        you and your employees can save in taxes.
                                    </div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: 600, fontSize: 19, paddingBottom: 5 }}>
                                        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                            <div>
                                                <IconFileInvoice />
                                            </div>
                                            <div>Quick, Easy And Accurate Quotation</div>
                                        </div>
                                    </div>
                                    <div style={{ fontSize: 15 }}>
                                        By connecting directly to your payroll provider, SureAdvisors can give you a
                                        quote and Section 125 proposal in just a few clicks, it takes just a few
                                        seconds.
                                    </div>
                                </div> */}
                                <div>
                                    <div style={{ fontWeight: 600, fontSize: 19, paddingBottom: 5 }}>
                                        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                            <div>
                                                <IconLock />
                                            </div>
                                            <div>Safe, Secure and Compliant</div>
                                        </div>
                                    </div>
                                    <div style={{ fontSize: 15 }}>
                                        At Sure Advisors, we take your privacy and data security seriously. We never
                                        sell or share your information with third parties. For more details, please
                                        review our terms and privacy policy.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "70%", paddingTop: 25 }}>
                                <ButtonNeoGen
                                    style={{
                                        borderRadius: 14,
                                        padding: 12,
                                        fontSize: 16,
                                        background: "rgba(68, 112, 255, 1)",
                                    }}
                                    block
                                    onClick={async () => {
                                        setStep(2);
                                    }}
                                >
                                    Get Free Quote
                                </ButtonNeoGen>
                            </div>
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <div>
                        <h2 style={{ textAlign: "center", paddingBottom: 20 }}>
                            <img src={Logo} width={250} style={{ margin: "auto", borderRadius: 10 }} />
                        </h2>
                        <div style={{ paddingBottom: 10 }}>
                            <h1 style={{ textAlign: "center", fontWeight: 600, fontSize: 42, lineHeight: 1 }}>
                                Get Quoted In Seconds
                            </h1>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: 20,
                                paddingBottom: 20,
                            }}
                        >
                            <div style={{ width: "100%", textAlign: "center", color: "gray", fontSize: 16 }}>
                                SureAdvisors makes it quick and easy to see how much your business and employees could
                                save with a Section 125 plan.
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: 20,
                                paddingBottom: 20,
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 24,
                                }}
                            >
                                {/* <div style={{ textAlign: "center" }}>We need your payroll in order to quote you</div> */}
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: 1,
                                        background: "#f6f6f6CE",
                                        borderRadius: 10,
                                        padding: 10,
                                    }}
                                >
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr
                                                className="hover:bg-gray-200 cursor-pointer"
                                                onClick={() => {
                                                    open();
                                                    setIsLoading(true);
                                                }}
                                                style={{ borderBottom: "1px solid #ccc" }}
                                            >
                                                <td style={{ width: "90%", padding: 15, height: 20 }}>
                                                    <div>
                                                        <div
                                                            style={{
                                                                fontSize: 20,
                                                                fontWeight: 500,
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                gap: 5,
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <div>
                                                                <IconPlugConnected />
                                                            </div>
                                                            <div>Connect Payroll</div>
                                                            <div
                                                                className="bg-green-500"
                                                                style={{
                                                                    fontSize: 13,
                                                                    borderRadius: 14,
                                                                    color: "white",
                                                                    padding: "0px 8px 0px 8px",
                                                                }}
                                                            >
                                                                Recommended
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                color: "gray",
                                                                fontSize: 15,
                                                            }}
                                                        >
                                                            <div>
                                                                Securely connect directly to your payroll provider
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    gap: 4,
                                                                    fontSize: 13,
                                                                }}
                                                            >
                                                                <div>
                                                                    <IconClock size={15} />
                                                                </div>
                                                                <div>takes just a few seconds</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    {isLoading ? (
                                                        <div>
                                                            <Loader3 noMargin noHeight />
                                                        </div>
                                                    ) : (
                                                        <IconArrowRight size={28} />
                                                    )}
                                                </td>
                                            </tr>
                                            <tr
                                                className="hover:bg-gray-200 cursor-pointer"
                                                style={{ borderBottom: "1px solid #ccc" }}
                                            >
                                                <td style={{ width: "90%", padding: 15, height: 20 }}>
                                                    <div>
                                                        <div
                                                            style={{
                                                                fontSize: 20,
                                                                fontWeight: 500,
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                gap: 5,
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <div>
                                                                <IconUpload />
                                                            </div>
                                                            <div>Upload Payroll</div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                color: "gray",
                                                                fontSize: 15,
                                                            }}
                                                        >
                                                            <div>Upload your payroll files from your provider</div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    gap: 4,
                                                                    fontSize: 13,
                                                                }}
                                                            >
                                                                <div>
                                                                    <IconClock size={15} />
                                                                </div>
                                                                <div>takes a few minutes</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    <IconArrowRight size={28} />
                                                </td>
                                            </tr>
                                            <tr
                                                className="hover:bg-gray-200 cursor-pointer"
                                                onClick={() => {
                                                    navigate("/admin/pcmp");
                                                }}
                                            >
                                                <td style={{ width: "90%", padding: 15, height: 20 }}>
                                                    <div>
                                                        <div
                                                            style={{
                                                                fontSize: 20,
                                                                fontWeight: 500,
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                gap: 5,
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <div>
                                                                <IconEdit />
                                                            </div>
                                                            <div>Manually Create Payroll</div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                color: "gray",
                                                                fontSize: 15,
                                                            }}
                                                        >
                                                            <div>Manually create your payroll online</div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    gap: 4,
                                                                    fontSize: 13,
                                                                }}
                                                            >
                                                                <div>
                                                                    <IconClock size={15} />
                                                                </div>
                                                                <div>takes a few hours</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    <IconArrowRight size={28} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                            <div
                                style={{
                                    width: "85%",
                                    paddingTop: 25,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 10,
                                }}
                            >
                                <div>
                                    <ButtonNeoGen
                                        onClick={async () => {
                                            setStep(1);
                                            setIsLoading(false);
                                        }}
                                        type="outline"
                                    >
                                        Back
                                    </ButtonNeoGen>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {step === 3 && (
                    <div>
                        <h2 style={{ textAlign: "center", paddingBottom: 20 }}>
                            <img src={Logo} width={250} style={{ margin: "auto", borderRadius: 10 }} />
                        </h2>
                        <div style={{ paddingBottom: 10 }}>
                            <h1 style={{ textAlign: "center", fontWeight: 600, fontSize: 34 }}>
                                Your business could save up to <span style={{ fontWeight: 800 }}>$89,000</span> in taxes
                                per year
                            </h1>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: 20,
                            }}
                        >
                            <div style={{ width: "100%", textAlign: "center", color: "gray", fontSize: 16 }}>
                                We've emailed you a detailed quote and costing breakdown, but here is a quick summary:
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: 28,
                                paddingBottom: 28,
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 24,
                                }}
                            >
                                <div style={{ display: "flex", flexDirection: "column", gap: 14 }}>
                                    <div>
                                        You have <b>328 full time employees</b> on your payroll. <b>Only 23</b> of these
                                        employees are currently registered for Section 125 benefits.
                                    </div>
                                    <div>
                                        If you enrolled just 100 additional employees your business could save up to{" "}
                                        <b>$48,000</b> in taxes per year.
                                    </div>
                                    <div>
                                        If you enrolled all 328 employees, your business could save up to <b>$89,000</b>{" "}
                                        in taxes per year.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                fontWeight: 700,
                                fontSize: 17,
                            }}
                        >
                            <div>
                                SureAdvisors can help you quickly and easily enroll and manage employees in a Section
                                125 Plan for just{" "}
                                <span style={{ fontWeight: 800 }}>$65 per enrolled employee, per month</span>.
                            </div>
                        </div>
                        <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                            <div
                                style={{
                                    width: "85%",
                                    paddingTop: 25,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 10,
                                }}
                            >
                                <div>
                                    <ButtonNeoGen
                                        onClick={async () => {
                                            setStep(2);
                                            setIsLoading(false);
                                        }}
                                        type="outline"
                                    >
                                        Back
                                    </ButtonNeoGen>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <ButtonNeoGen
                                        style={{
                                            borderRadius: 14,
                                            padding: 12,
                                            fontSize: 16,
                                            background: "rgba(68, 112, 255, 1)",
                                        }}
                                        block
                                        onClick={async () => {
                                            navigate(`/processflow-wizard/28`);
                                        }}
                                        isLoading={isLoading}
                                    >
                                        Get Started Now
                                    </ButtonNeoGen>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
