import React, { useMemo } from "react";
import invoicesService from "../../../services/invoices.service";
import { useQuery } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import PrintPre from "../../../layout/print-pre";
import { useNavigate } from "react-router";
import { usePromise } from "../../../jason-proof-of-concept/shared/hooks";
import progressDataAtom from "../../../atoms/progressDataAtom";
import { useCompanies } from "../../../companies/hooks/use-companies";
import { useUserCompanies } from "../../../companies/hooks/use-user-companies";
import { getProcessflowStages } from "../../../jason-proof-of-concept/processflow-stages/actions/get-processflow-stages";
import { getProcessflows } from "../../../jason-proof-of-concept/processflows/actions/get-process-flows";
import { getAuthToken } from "../../../services/auth-header";
import { Table } from "../../../layout/table";
import TableNeogen from "../../../layout/table-neogen";

function MyInvoices() {
    const [progressData, setProgressData] = useRecoilState(progressDataAtom);
    const navigate = useNavigate();
    const authToken = getAuthToken();
    const [user] = useRecoilState(userAtom);
    // If the server is login.ercrepair.com use 28 otherwise use 7
    const group = window.location.hostname === "login.ercrepair.com" ? 28 : 7;

    const processflowStagesAction = usePromise(async () => {
        const processflowStages = await getProcessflowStages({
            authToken,
            processflowGroup: group,
        });
        return processflowStages;
    }, []);

    const processflowsAction = usePromise(async () => {
        const processflows = await getProcessflows({
            authToken,
            group,
        });
        return processflows;
    }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const processflows = useMemo(
        () => (processflowsAction.result || []).sort((a, b) => (a.order || 0) - (b.order || 0)),
        [processflowsAction.result],
    );
    const userCompaniesQuery = useUserCompanies({
        authToken,
        filters: { where: { ercUserId: user.id } },
    });
    const userCompanies = useMemo(() => userCompaniesQuery.data || [], [userCompaniesQuery.data]);

    const companiesQuery = useCompanies({
        authToken,
        filters: { where: { id: { inq: userCompanies.map((uc) => uc.companyId) } } },
    });
    const invoicesQuery = useQuery(
        ["invoices", companiesQuery.data?.[0]?.id],
        async () => {
            const response = await invoicesService.getFilteredWhere({ companyId: companiesQuery.data?.[0]?.id });
            if (response) {
                return response.data;
            }
        },
        { enabled: !!companiesQuery.data },
    );
    // const invoiceLineItemsQuery = useQuery(["invoiceLineItems"], async () => {
    if (invoicesQuery.isLoading) return <div>Loading...</div>;
    return (
        <div>
            <p className="mt-0 mb-5 text-left text-xl font-light tracking-wider text-gray-600 sm:text-2xl dark:text-green-400 ">
                Invoices
            </p>
            {/* <PrintPre>{companiesQuery.data}</PrintPre>
            <PrintPre>{invoicesQuery.data}</PrintPre> */}
            <TableNeogen
                formatters={[
                    {
                        field: "createdAt",
                        type: "Date",
                    },
                    {
                        field: "dueDate",
                        type: "DateNullable",
                    },
                    {
                        field: "issuedAt",
                        type: "Date",
                    },
                    {
                        field: "amount",
                        type: "Currency",
                    },
                ]}
                ignoreFields={[
                    "companyId",
                    "stripeInvoiceId",
                    "updatedAt",
                    "createdAt",
                    "stripeInvoiceUrl",
                    "qbInvoiceURL",
                    "qbInvoiceId",
                    "qbInvoicePaymentUrl",
                    "amountPaid",
                    "contactFirstName",
                    "contactLastName",
                    "contactEmail",
                    "contactPhone",
                    "paidAt",
                ]}
                entries={invoicesQuery.data ?? []}
            />
        </div>
    );
}

export default MyInvoices;
