import { Link } from "react-router-dom";
import "./pies.css";
import {
    affiliateCommissionMultiplier,
    clearErcFeesMultiplier,
    getEstimatedPayoutFromData,
} from "../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { formatCurrency } from "../billing/utils";
import { Tooltip } from "./tooltip";
import { customerSortType } from "../sections/dashboard/my-customers";
import { UserIcon } from "@heroicons/react/24/outline";
import { useRecoilState } from "recoil";
import userAtom from "../atoms/userAtom";
import { CompanyStatus } from "../companies/domain/company";
import { CustomerRow } from "../sections/dashboard/props";
import namers from "../services/namers";
import SelectStage from "./stage-selector";
import { useMemo, useState } from "react";
import processflowProgressService from "../services/processflow-progress.service";
import { THead, Table, Tr, Th, TBody, Td } from "./table";
import OptionsDropDown from "./options-dropdown";
import { RoleGroup } from "../role-groups/domain/role-group";
import { CompanyNotesModal } from "../sections/dashboard/dashboard-content/companyNotesModal";
import SelectStageCPA from "./stage-selector-cpa";
import { useInteractions } from "../interactions/hooks/use-interactions";
import { getAuthTokenNoThrow } from "../services/auth-header";
import { useCompanies } from "../companies/hooks/use-companies";
import { useUserCompanies } from "../companies/hooks/use-user-companies";
import ButtonNeoGen from "./button-neogen";
import mondaySyncDataService from "../services/monday-sync-data.service";

const sortCustomers = (rows: CustomerRow[], sortType: customerSortType) => {
    const withoutNames = rows.filter((c) => !c.company.name);
    const withNames = rows.filter((c) => c.company.name);
    switch (sortType) {
        case "createdAtDesc":
            return rows.sort((a, b) =>
                a.company.createdAt && b.company.createdAt ? (a.company.createdAt < b.company.createdAt ? 1 : -1) : 0,
            );
        case "createdAtAsc":
            return rows.sort((a, b) =>
                a.company.createdAt && b.company.createdAt ? (a.company.createdAt > b.company.createdAt ? 1 : -1) : 0,
            );
        case "nameDesc":
            return [
                ...withNames.sort((a, b) =>
                    (a.company?.name?.toLowerCase() ?? "") < (b.company.name?.toLowerCase() ?? "") ? 1 : -1,
                ),
                ...withoutNames,
            ];
        case "nameAsc":
            return [
                ...withNames.sort((a, b) =>
                    (a.company.name?.toLowerCase() ?? "zzz") > (b.company.name?.toLowerCase() ?? "zzz") ? 1 : -1,
                ),
                ...withoutNames,
            ];

        case "emailDesc":
            return rows.sort((a, b) =>
                (a.company.ownedBy?.email?.toLowerCase() || "") < (b.company.ownedBy?.email?.toLowerCase() || "")
                    ? 1
                    : -1,
            );
        case "emailAsc":
            return rows.sort((a, b) =>
                (a.company.ownedBy?.email?.toLowerCase() || "") > (b.company.ownedBy?.email?.toLowerCase() || "")
                    ? 1
                    : -1,
            );
        case "statusDesc":
            return rows.sort((a, b) =>
                (a.processflowProgress?.status?.toLowerCase() || "") <
                (b.processflowProgress?.status?.toLowerCase() || "")
                    ? 1
                    : -1,
            );
        case "statusAsc":
            return rows.sort((a, b) =>
                (a.processflowProgress?.status?.toLowerCase() || "") >
                (b.processflowProgress?.status?.toLowerCase() || "")
                    ? 1
                    : -1,
            );
        default:
            return rows;
    }
};

export default function DashboardCustomers({
    search,
    sortBy,
    rows,
    activeRole,
    asCards,
}: {
    search: string;
    sortBy: customerSortType;
    rows: CustomerRow[];
    asCards?: boolean;
    activeRole: undefined | null | RoleGroup;
}) {
    const [user] = useRecoilState(userAtom);
    const canRoleSeeCommissions = !!user?.roleGroups?.find((rg) => [4, 7, 9, 16, 17, 11].includes(rg.id));
    const isSuperUserOrDocCollector = !!user?.roleGroups?.find((rg) => [4, 14, , 15, 26].includes(rg.id));
    const companyRows = rows.filter((r) => r.company);
    const [selectStageOpen, setSelectStageOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<CustomerRow | null>(null);
    const [stage, setStage] = useState<string | null>(null);
    const [companyNotesModalToShow, setCompanyNotesModalToShow] = useState<number | undefined>();
    const authToken = getAuthTokenNoThrow() || "no-token";
    const activeRows = sortCustomers(
        companyRows.filter(
            (r) =>
                !r.company.status ||
                r.company.status === CompanyStatus.active ||
                r.company.status === CompanyStatus.inReview,
        ),
        sortBy,
    ).filter((r) => {
        const name = r.company.name?.toLowerCase() ?? "";
        const email = r.company.ownedBy?.email?.toLowerCase() ?? "";
        const searchLower = search.toLowerCase();
        return name.includes(searchLower) || email.includes(searchLower);
    });
    const archivedRows = sortCustomers(
        companyRows.filter((r) => r.company.status === CompanyStatus.archived),
        sortBy,
    );

    const interactionsQuery = useInteractions({
        authToken,
        filters: { where: { interaction_to: user.id } },
        enabled: !!user.id,
    });

    const notes = useMemo(() => (interactionsQuery.data || []).filter((i) => i.type === 3), [interactionsQuery.data]);

    async function saveStage(stageName: string) {
        setStage(stageName);
        if (stageName && selectedRow) {
            if (!selectedRow.processflowProgress) {
                alert("no processflowProgress");
            } else {
                const duplicate: any = { ...selectedRow.processflowProgress };
                duplicate.data = JSON.stringify(duplicate.data);
                duplicate.status = stageName;
                selectedRow.processflowProgress.status = stageName;
                if (!duplicate.client) {
                    duplicate.client = selectedRow.company.id;
                }
                if (!duplicate.currentStage) {
                    duplicate.currentStage = -1;
                }
                if (!duplicate.currentStep) {
                    duplicate.currentStep = -1;
                }
                processflowProgressService.update(duplicate.id, duplicate);
                await mondaySyncDataService.pushStatusToMonday(
                    selectedRow.processflowProgress.id,
                    stageName,
                    user.id ?? "Unknown user",
                );
            }

            setSelectedRow(null);
            setSelectStageOpen(false);
        }
    }

    const userCompaniesQuery = useUserCompanies({
        authToken,
        filters: { where: { ercUserId: user.id, roleGroupId: activeRole?.id } },
    });
    const userCompanies = useMemo(() => userCompaniesQuery.data || [], [userCompaniesQuery.data]);

    const companiesQuery = useCompanies({
        authToken,
        filters: { where: { id: { inq: userCompanies.map((uc) => uc.companyId) } } },
    });

    const handleOpenLink = (route: string) => {
        const newTab = window.open("", "_blank");
        // @ts-ignore
        newTab.location.href = route;
    };

    return (
        <>
            {asCards ? (
                <div>
                    <ul
                        role="list"
                        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 first-letter:pb-10"
                    >
                        {activeRows.map((row) => {
                            const clientPayout = getEstimatedPayoutFromData({
                                data: {
                                    "Average full time W2 Employees in 2020?": row.w2Employees.w2Employees2020,
                                    "Average full time W2 Employees in 2021?": row.w2Employees.w2Employees2021,
                                },
                            });
                            const clearErcFees = clientPayout * clearErcFeesMultiplier;
                            const affiliateCommission = clearErcFees * affiliateCommissionMultiplier;
                            const affiliateName = row.affiliate
                                ? row.affiliate.firstName
                                    ? `${row.affiliate.firstName} ${row.affiliate.lastName}`
                                    : row.affiliate.email
                                : "No affiliate";
                            const isCloser = !!user?.roleGroups?.find((rg) => 11 === rg.id);
                            const isCloserOwnDeal =
                                row.company.ownedBy?.closerId && row.company.ownedBy.closerId === user.id;
                            const showCommissions =
                                canRoleSeeCommissions && (isCloser ? (isCloserOwnDeal ? true : false) : true);

                            return (
                                <li
                                    key={row.company.id}
                                    className="col-span-1 flex flex-col justify-between dark:divide-gray-700 bg-gradient-to-br to-slate-50 from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-gray-200 sm:border-white rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner sm:shadow-xl"
                                >
                                    {isSuperUserOrDocCollector && (
                                        <button
                                            onClick={() => {
                                                setStage(row.processflowProgress?.status ?? "");
                                                setSelectedRow(row);
                                                setSelectStageOpen(true);
                                            }}
                                            className={
                                                "border-b border-gray-300 dark:border-gray-800 " +
                                                "text-center " +
                                                " from-" +
                                                namers(row.processflowProgress?.status ?? "", "color") +
                                                "-100 " +
                                                " to-" +
                                                namers(row.processflowProgress?.status ?? "", "color") +
                                                "-200 " +
                                                " dark:from-" +
                                                namers(row.processflowProgress?.status ?? "", "color") +
                                                "-800 " +
                                                " dark:to-" +
                                                namers(row.processflowProgress?.status ?? "", "color") +
                                                "-900 " +
                                                " dark:text-white bg-gradient-to-b text-xs rounded-t-xl m-0 p-3 mt-0 " +
                                                " hover:from-" +
                                                namers(row.processflowProgress?.status ?? "", "color") +
                                                "-300 " +
                                                " hover:to-" +
                                                namers(row.processflowProgress?.status ?? "", "color") +
                                                "-500 " +
                                                " hover:dark:from-" +
                                                namers(row.processflowProgress?.status ?? "", "color") +
                                                "-900 " +
                                                " hover:dark:to-" +
                                                namers(row.processflowProgress?.status ?? "", "color") +
                                                "-800 "
                                            }
                                        >
                                            {!row.processflowProgress
                                                ? "No processflow progress"
                                                : row.processflowProgress?.status ?? "Unknown Status"}
                                        </button>
                                    )}
                                    <div
                                        className={
                                            "flex w-full items-center justify-between space-x-6 px-6 pt-4 text-center" +
                                            (isSuperUserOrDocCollector
                                                ? ""
                                                : "border-b dark:border-gray-700 from-slate-50 to-white dark:from-slate-900 dark:to-gray-800 dark:text-white bg-gradient-to-t text-xs rounded-t-xl m-0 p-3 mt-0 border-b-2 border-gray-white")
                                        }
                                    >
                                        <div className="flex-1 truncate">
                                            <div className="flex items-center">
                                                <a
                                                    href={`mailto:${row.company.ownedBy?.email}`}
                                                    className="text-center mx-auto capitalize truncate text-sm font-medium text-gray-900 dark:text-slate-400"
                                                >
                                                    {row.company?.name || "No name"}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-1 mb-6 px-6">
                                        <div className="flex gap-1 items-center mt-3">
                                            <p className="text-xs text-gray-500">
                                                Avg. employees in 2020: {row.w2Employees.w2Employees2020 || 0}, 2021:{" "}
                                                {row.w2Employees.w2Employees2021 || 0}
                                            </p>{" "}
                                        </div>
                                        <div className="flex items-center gap-1 my-3">
                                            <p className="truncate text-xs text-gray-500 underline">
                                                Estimated payouts
                                            </p>
                                            <Tooltip
                                                body={`Estimated payouts are calculated based on the W2 employee numbers from 2020 and 2021. Client payout is what the IRS reimburses ${
                                                    !showCommissions
                                                        ? "."
                                                        : ", the ClearERC fees are estimated to be 15% - 25% of the client payout and the affiliate commission is 15% of the ClearERC fees."
                                                }`}
                                            />
                                        </div>
                                        <p className="truncate text-xs text-gray-500">
                                            Client payout: {formatCurrency(clientPayout)}
                                            {showCommissions && (
                                                <>
                                                    <br />
                                                    ClearERC Fees: {formatCurrency(clearErcFees)}
                                                    <br />
                                                    Affiliate Commission: {formatCurrency(affiliateCommission)}
                                                </>
                                            )}
                                        </p>
                                        <div className="flex gap-1 items-center mt-3">
                                            <UserIcon width={12} height={12} className="-ml-0.5 text-gray-500" />
                                            <p className="text-xs text-gray-500">Affiliate: {affiliateName}</p>{" "}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="-mt-px flex divide-x divide-gray-200 dark:divide-gray-700">
                                            <div className="-ml-px flex w-0 flex-1 border-t border-gray-300 dark:border-gray-800">
                                                <Link
                                                    to={"/companies/" + row.company?.id}
                                                    className="dark:to-gray-800 dark:from-gray-900 dark:hover:to-green-800 dark:hover:from-gray-800 dark:hover:text-white   dark:border-gray-700 dark:text-gray-400 relative inline-flex w-0 flex-1 items-center justify-center rounded-b-xl  border-l border-t border-white py-4 text-sm font-medium text-gray-700 hover:text-gray-500 hover:to-gray-200 bg-gradient-to-b to-white from-indigo-100"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6 text-gray-400"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                                                        />
                                                    </svg>

                                                    <span className="ml-3">Open Company</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    {archivedRows && archivedRows.length > 0 && (
                        <>
                            <br />
                            <h2 className={"mb-4"}>Inactive companies</h2>
                            <ul
                                role="list"
                                className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 max-h-96  first-letter:pb-10"
                            >
                                {archivedRows.map((row) => {
                                    const clientPayout = getEstimatedPayoutFromData({
                                        data: {
                                            "Average full time W2 Employees in 2020?": row.w2Employees.w2Employees2020,
                                            "Average full time W2 Employees in 2021?": row.w2Employees.w2Employees2021,
                                        },
                                    });
                                    const clearErcFees = clientPayout * clearErcFeesMultiplier;
                                    const affiliateCommission = clearErcFees * affiliateCommissionMultiplier;
                                    const affiliateName = row.affiliate
                                        ? row.affiliate.firstName
                                            ? `${row.affiliate.firstName} ${row.affiliate.lastName}`
                                            : row.affiliate.email
                                        : "No affiliate";
                                    const isCloser = !!user?.roleGroups?.find((rg) => 11 === rg.id);
                                    const isCloserOwnDeal =
                                        row.company.ownedBy?.closerId && row.company.ownedBy.closerId === user.id;
                                    const showCommissions =
                                        canRoleSeeCommissions && (isCloser ? (isCloserOwnDeal ? true : false) : true);

                                    return (
                                        <li
                                            key={row.company.id}
                                            className="col-span-1 flex flex-col justify-between dark:divide-gray-700 bg-gradient-to-br to-slate-50 from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-gray-200 sm:border-white rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner sm:shadow-xl"
                                        >
                                            <div className="flex w-full items-center justify-between space-x-6 px-6 pt-6">
                                                <div className="flex-1 truncate">
                                                    <div className="flex items-center">
                                                        <a
                                                            href={`mailto:${row.company.ownedBy?.email}`}
                                                            className="capitalize truncate text-sm font-medium text-gray-900 dark:text-gray-300"
                                                        >
                                                            {row.company?.name || "No name"}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 mb-6 px-6">
                                                <div className="flex gap-1 items-center mt-3">
                                                    <p className="text-xs text-gray-500">
                                                        Avg. employees in 2020: {row.w2Employees.w2Employees2020 || 0},
                                                        2021: {row.w2Employees.w2Employees2021 || 0}
                                                    </p>{" "}
                                                </div>
                                                <div className="flex items-center gap-1 my-3">
                                                    <p className="truncate text-xs text-gray-500 underline">
                                                        Estimated payouts
                                                    </p>
                                                    <Tooltip
                                                        body={`Estimated payouts are calculated based on the W2 employee numbers from 2020 and 2021. Client payout is what the IRS reimburses ${
                                                            !showCommissions
                                                                ? "."
                                                                : ", the ClearERC fees are estimated to be 15% - 25% of the client payout and the affiliate commission is 15% of the ClearERC fees."
                                                        }`}
                                                    />
                                                </div>
                                                <p className="truncate text-xs text-gray-500">
                                                    Client payout: {formatCurrency(clientPayout)}
                                                    {showCommissions && (
                                                        <>
                                                            <br />
                                                            ClearERC Fees: {formatCurrency(clearErcFees)}
                                                            <br />
                                                            Affiliate Commission: {formatCurrency(affiliateCommission)}
                                                        </>
                                                    )}
                                                </p>
                                                <div className="flex gap-1 items-center mt-3">
                                                    <UserIcon
                                                        width={12}
                                                        height={12}
                                                        className="-ml-0.5 text-gray-500"
                                                    />
                                                    <p className="text-xs text-gray-500">Affiliate: {affiliateName}</p>{" "}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="-mt-px flex divide-x divide-gray-200 dark:divide-gray-700">
                                                    <div className="-ml-px flex w-0 flex-1 border-t border-gray-300 dark:border-gray-800">
                                                        <Link
                                                            to={"/companies/" + row.company?.id}
                                                            className="dark:to-gray-800 dark:from-gray-900 dark:border-gray-700 dark:text-gray-400 relative inline-flex w-0 flex-1 items-center justify-center rounded-b-xl  border-l border-t border-white py-4 text-sm font-medium text-gray-700 hover:text-gray-500 hover:to-gray-200 bg-gradient-to-t to-white from-slate-100"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={1.5}
                                                                stroke="currentColor"
                                                                className="w-6 h-6 text-gray-400"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                                                                />
                                                            </svg>

                                                            <span className="ml-3">Open Company</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </>
                    )}
                </div>
            ) : ["Accountant", "Tax attorney", "Doc collector"].includes(activeRole?.name || "") ? (
                <>
                    <h2 className="mb-2 mt-3 tracking-wide text-left">Assigned Clients</h2>
                    <Table>
                        <THead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Status</Th>
                                <Th>Email</Th>
                                <Th>Owned By</Th>
                                <Th>Progressflow Progress</Th>
                                <Th>Notes</Th>
                                <Th />
                            </Tr>
                        </THead>
                        <TBody>
                            {[...activeRows, ...archivedRows]?.map((r) => {
                                const notesCount = notes.filter((n) => n.companyId === r.company.id).length;
                                const positiveNotesCount = notesCount > 0;
                                return (
                                    <Tr key={r.company.id}>
                                        <Td className="break-words text-sm pr-3 pl-4">{r.company.name}</Td>
                                        <Td>{r.company.status}</Td>
                                        <Td className="break-words text-sm pr-3 pl-4">{r.company.email || "-"}</Td>
                                        <Td>
                                            {r.company.ownedBy && r.company.ownedBy.firstName
                                                ? [r.company.ownedBy.firstName, r.company.ownedBy.lastName].join(" ")
                                                : "-"}
                                        </Td>
                                        <Td className="break-words text-sm pr-3 pl-4">
                                            {r.processflowProgress?.status || "-"}
                                        </Td>
                                        <Td>
                                            {positiveNotesCount ? (
                                                <ButtonNeoGen
                                                    onClick={() =>
                                                        positiveNotesCount
                                                            ? setCompanyNotesModalToShow(r.company.id)
                                                            : undefined
                                                    }
                                                >
                                                    View notes ({notesCount})
                                                </ButtonNeoGen>
                                            ) : (
                                                "-"
                                            )}
                                        </Td>
                                        <Td>
                                            <OptionsDropDown
                                                options={[
                                                    {
                                                        icon: "fal fa-folder-open ",
                                                        label: "Open client",
                                                        action: () => handleOpenLink(`/companies/${r.company.id}`),
                                                    },
                                                    {
                                                        icon: "fal fa-file",
                                                        label: "View files",
                                                        action: () =>
                                                            handleOpenLink(`/companies/${r.company.id}?tab=files`),
                                                    },
                                                    {
                                                        icon: "fal fa-file-invoice",
                                                        label: "Open application",
                                                        action: () =>
                                                            handleOpenLink(
                                                                `/companies/${r.company.id}?tab=application`,
                                                            ),
                                                    },
                                                    {
                                                        icon: "fal fa-retweet",
                                                        label: "Update processflow progress",
                                                        action: () => {
                                                            setSelectStageOpen(true);
                                                            setSelectedRow(r);
                                                        },
                                                    },
                                                ]}
                                            />
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </TBody>
                    </Table>
                </>
            ) : (
                <>
                    <Table>
                        <THead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Ave. employees</Th>
                                <Th>Client Payout</Th>
                                <Th>Progressflow progress</Th>
                                <Th>Affiliate</Th>
                                <Th />
                            </Tr>
                        </THead>
                        <TBody>
                            {activeRows?.map((r) => {
                                return (
                                    <Tr key={r.company.id}>
                                        <Td>{r.company.name}</Td>
                                        <Td>
                                            <p>2020: {r.w2Employees.w2Employees2020 || "-"}</p>
                                            <p>2021: {r.w2Employees.w2Employees2021 || "-"}</p>
                                        </Td>
                                        <Td>
                                            $
                                            {getEstimatedPayoutFromData({
                                                data: {
                                                    "Average full time W2 Employees in 2020?":
                                                        r.w2Employees.w2Employees2020,
                                                    "Average full time W2 Employees in 2021?":
                                                        r.w2Employees.w2Employees2021,
                                                },
                                            })}
                                        </Td>
                                        <Td>{r.processflowProgress?.status || "-"}</Td>
                                        <Td>
                                            {r.affiliate
                                                ? r.affiliate.firstName
                                                    ? `${r.affiliate.firstName} ${r.affiliate.lastName}`
                                                    : r.affiliate.email
                                                : "No affiliate"}
                                        </Td>
                                        <Td>
                                            <OptionsDropDown
                                                options={[
                                                    {
                                                        icon: "fal fa-folder-open ",
                                                        label: "Open client",
                                                        action: () => handleOpenLink(`/companies/${r.company.id}`),
                                                    },
                                                    {
                                                        icon: "fal fa-file",
                                                        label: "View files",
                                                        action: () =>
                                                            handleOpenLink(`/companies/${r.company.id}?tab=files`),
                                                    },
                                                    {
                                                        icon: "fal fa-file-invoice",
                                                        label: "Open application",
                                                        action: () =>
                                                            handleOpenLink(
                                                                `/companies/${r.company.id}?tab=application`,
                                                            ),
                                                    },
                                                    {
                                                        icon: "fal fa-retweet",
                                                        label: "Update processflow progress",
                                                        action: () => {
                                                            setStage(r.processflowProgress?.status ?? "");
                                                            setSelectedRow(r);
                                                            setSelectStageOpen(true);
                                                        },
                                                    },
                                                ]}
                                            />
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </TBody>
                    </Table>
                </>
            )}
            {activeRole?.name === "Accountant" ? (
                <SelectStageCPA
                    open={selectStageOpen}
                    setOpen={setSelectStageOpen}
                    stage={stage}
                    saveStage={saveStage}
                />
            ) : (
                <SelectStage open={selectStageOpen} setOpen={setSelectStageOpen} stage={stage} saveStage={saveStage} />
            )}
            {companyNotesModalToShow && (
                <CompanyNotesModal
                    notes={notes.filter((n) => n.companyId === companyNotesModalToShow)}
                    close={() => setCompanyNotesModalToShow(undefined)}
                    companyId={companyNotesModalToShow}
                    companyName={(companiesQuery.data || []).find((c) => c.id === companyNotesModalToShow)?.name || ""}
                />
            )}
        </>
    );
}
