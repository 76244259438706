import { Currency } from "../../../../../billing/components";
import ButtonNeoGen from "../../../../../layout/button-neogen";
import { DateDisplay } from "../../../../../layout/date-display";
import OptionsDropDown from "../../../../../layout/options-dropdown";
import SearchField from "../../../../../layout/search-field";
import { TBody, THead, Table, Td, Tr } from "../../../../../layout/table";
import { BillingRow } from "../domain/billing";

export const BillingAndHistorySection = ({ billingRows }: { billingRows: BillingRow[] }) => {
    return (
        <>
            <div className="flex justify-center gap-5 my-4">
                <div className="text-2xl rounded-md bg-white px-8 py-5 w-full text-center">
                    <Currency amount={30} />
                    <p className="text-lg">Future commissions</p>
                </div>
                <div className="text-2xl rounded-md bg-white px-8 py-5 w-full text-center">
                    <Currency amount={30} />
                    <p className="text-lg">Due commissions</p>
                </div>
                <div className="text-2xl rounded-md bg-white px-8 py-5 w-full text-center">
                    <Currency amount={45} />
                    <p className="text-lg">Paid commissions</p>
                </div>
            </div>

            <div className="flex justify-end gap-2 mb-4">
                <SearchField
                    placeholder="Search"
                    search=""
                    setSearch={() => {
                        console.log("search");
                    }}
                />
            </div>
            <Table>
                <THead>
                    <Tr>
                        <Td>Company</Td>
                        <Td>Created At</Td>
                        <Td>Deal Status</Td>
                        <Td>Details</Td>
                        <Td>Profit Share</Td>
                        <Td></Td>
                    </Tr>
                </THead>
                <TBody>
                    {billingRows?.map((row: any) => (
                        <Tr key={row.companyId}>
                            <Td>{row.companyName}</Td>
                            <Td>
                                <DateDisplay date={row.createdAt} />
                            </Td>
                            <Td>{row.dealStatus}</Td>
                            <Td>{row.details}</Td>
                            <Td>
                                <div>
                                    <Currency amount={row.usersProfitShare} />
                                </div>
                            </Td>
                            <Td>
                                <div>
                                    <OptionsDropDown
                                        options={[
                                            {
                                                label: "Edit Status",
                                                action: () => {
                                                    console.log("Edit Status");
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </Td>
                        </Tr>
                    ))}
                </TBody>
            </Table>
        </>
    );
};
