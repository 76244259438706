import { IconUsers, IconTax, IconLock } from "@tabler/icons-react";
import React from "react";
import ButtonNeoGen from "../../layout/button-neogen";
import LogoBox from "../../layout/LogoBox";

function PCMPIntro() {
    return (
        <div>
            <h2 style={{ textAlign: "center", paddingBottom: 20 }}>
                <LogoBox whiteBackground={true} />
            </h2>
            <div style={{ paddingBottom: 10 }}>
                <h1 style={{ textAlign: "center", fontWeight: 600, fontSize: 42, lineHeight: 1 }}>
                    Enroll in PCMP and Save on FICA Taxes
                </h1>
            </div>
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 20,
                    paddingBottom: 12,
                }}
            >
                <div style={{ width: "100%", textAlign: "center", color: "gray", fontSize: 16 }}>
                    PCMP offers a tax-efficient and flexible solution for businesses to provide wellness benefits to
                    employees while reducing both employer and employee tax liabilities — all while ensuring compliance
                    with IRS regulations.
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 28,
                    paddingBottom: 18,
                }}
            >
                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 30 }}>
                    <div>
                        <div style={{ fontWeight: 600, fontSize: 19, paddingBottom: 5 }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                <div>
                                    <IconUsers />
                                </div>
                                <div>Boost Employee Wellness</div>
                            </div>
                        </div>
                        <div style={{ fontSize: 15 }}>
                            Provide your employees with tax-deductible wellness benefits designed to support their
                            physical, mental, and financial health, leading to a happier, healthier workforce.
                        </div>
                    </div>
                    {/* <div>
                    <div style={{ fontWeight: 600, fontSize: 19, paddingBottom: 5 }}>
                        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                            <div>
                                <IconGraph />
                            </div>
                            <div>Increase Employee Retention And Satisfaction</div>
                        </div>
                    </div>
                    <div style={{ fontSize: 15 }}>
                        By offering Section 125 benefits to your employees, you can increase employee
                        retention and satisfaction by letting your employees make use of tax-efficient
                        benefits.
                    </div>
                </div> */}
                    <div>
                        <div style={{ fontWeight: 600, fontSize: 19, paddingBottom: 5 }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                <div>
                                    <IconTax />
                                </div>
                                <div>Reduce Taxes for You and Your Employees</div>
                            </div>
                        </div>
                        <div style={{ fontSize: 15 }}>
                            By implementing PCMP, your business can save up to <b>7.65%</b> on payroll taxes — an
                            average of <b>$685</b> per employee annually.
                        </div>
                    </div>
                    {/* <div>
                    <div style={{ fontWeight: 600, fontSize: 19, paddingBottom: 5 }}>
                        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                            <div>
                                <IconCashRegister />
                            </div>
                            <div>See How Much You Can Save</div>
                        </div>
                    </div>
                    <div style={{ fontSize: 15 }}>
                        SureAdvisors partners with <a href="https://finch.com">Finch</a> to connect
                        directly to your payroll provider to analyze your payroll and show you how much
                        you and your employees can save in taxes.
                    </div>
                </div>
                <div>
                    <div style={{ fontWeight: 600, fontSize: 19, paddingBottom: 5 }}>
                        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                            <div>
                                <IconFileInvoice />
                            </div>
                            <div>Quick, Easy And Accurate Quotation</div>
                        </div>
                    </div>
                    <div style={{ fontSize: 15 }}>
                        By connecting directly to your payroll provider, SureAdvisors can give you a
                        quote and Section 125 proposal in just a few clicks, it takes just a few
                        seconds.
                    </div>
                </div> */}
                    <div>
                        <div style={{ fontWeight: 600, fontSize: 19, paddingBottom: 5 }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                <div>
                                    <IconLock />
                                </div>
                                <div>Safe, Secure and Compliant</div>
                            </div>
                        </div>
                        <div style={{ fontSize: 15 }}>
                            At Sure Advisors, we take your privacy and data security seriously. We never sell or share
                            your information with third parties. For more details, please review our terms and privacy
                            policy.
                        </div>
                    </div>
                </div>
            </div>
            {/* <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                <div style={{ width: "70%", paddingTop: 25 }}>
                    <ButtonNeoGen
                        style={{
                            borderRadius: 14,
                            padding: 12,
                            fontSize: 16,
                            background: "rgba(68, 112, 255, 1)",
                        }}
                        block
                        onClick={async () => {
                            // setStep(2);
                            alert(2);
                        }}
                    >
                        Get Free Quote
                    </ButtonNeoGen>
                </div>
            </div> */}
        </div>
    );
}

export default PCMPIntro;
