// and super user can probably just have all 3 role groups

import { useMemo } from "react";
import { getProcessflowStages } from "../../../jason-proof-of-concept/processflow-stages/actions/get-processflow-stages";
import { usePromise } from "../../../jason-proof-of-concept/shared/hooks";
import { getProcessflows } from "../../../jason-proof-of-concept/processflows/actions/get-process-flows";
import { Stage, Stages } from "../../../jason-proof-of-concept/components/stages";
import { useRecoilState } from "recoil";
import progressDataAtom from "../../../atoms/progressDataAtom";
import { useNavigate } from "react-router";
import History from "../../../companies/components/history";

import { useCompanies } from "../../../companies/hooks/use-companies";
import { useUserCompanies } from "../../../companies/hooks/use-user-companies";
import userAtom from "../../../atoms/userAtom";
import PrintPre from "../../../layout/print-pre";
import { useQuery } from "@tanstack/react-query";
import { getProcessflowProgresses } from "../../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import { getAuthToken } from "../../../services/auth-header";
import Messages from "../../messages/messages";
import Messaging from "./messaging";
import PageDescription from "../../../layout/page-description";
import MyContacts from "./myContacts";
import MyAppointments from "./myAppointments";
import MyInvoices from "./myInvoices";
import RecordingButton from "../../../layout/recording-button";
import Packages from "./packages";
import Links from "./links";

export const ClientContent = ({ darkMode }: { darkMode: boolean }) => {
    const [progressData, setProgressData] = useRecoilState(progressDataAtom);
    const navigate = useNavigate();
    const authToken = getAuthToken();
    const [user] = useRecoilState(userAtom);
    // If the server is login.ercrepair.com use 28 otherwise use 7
    const group = window.location.hostname === "login.ercrepair.com" ? 28 : 7;

    const processflowStagesAction = usePromise(async () => {
        const processflowStages = await getProcessflowStages({
            authToken,
            processflowGroup: group,
        });
        return processflowStages;
    }, []);

    const processflowsAction = usePromise(async () => {
        const processflows = await getProcessflows({
            authToken,
            group,
        });
        return processflows;
    }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const processflows = useMemo(
        () => (processflowsAction.result || []).sort((a, b) => (a.order || 0) - (b.order || 0)),
        [processflowsAction.result],
    );
    const userCompaniesQuery = useUserCompanies({
        authToken,
        filters: { where: { ercUserId: user.id } },
    });
    const userCompanies = useMemo(() => userCompaniesQuery.data || [], [userCompaniesQuery.data]);

    const companiesQuery = useCompanies({
        authToken,
        filters: { where: { id: { inq: userCompanies.map((uc) => uc.companyId) } } },
    });

    const progressQueryHistory = useQuery(["billing-page-calcs2", { userId: user.id }, authToken], async () => {
        if (!user.id) {
            return {};
        }
        const group = 28;
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { userId: user.id, group },
        });
        return { usersProgress };
    });

    if (
        processflowStagesAction.isLoading ||
        processflowsAction.isLoading ||
        userCompaniesQuery.isLoading ||
        progressQueryHistory.isLoading ||
        companiesQuery.isLoading
    ) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 mt-5">
                <div className="xl:col-span-2">
                    <div className="md:col-span-1 sm:col-span-5 sm:block">
                        {/* <PrintPre>{progressQueryHistory}</PrintPre> */}
                        {/* <PageDescription title="Progress" /> */}
                        <div className="grid grid-cols-8 gap-5 sm:grid-cols-1">
                            <div className=" col-span-8 pb-2  bg-white dark:bg-gray-800  rounded-xl shadow overflow-hidden">
                                {/* <div className="mt-0 md:pt-0 text-left sm:text-center font-medium text-gray-500 dark:text-green-400 md:flex"> */}
                                <p className="px-5 pt-3 pb-0 mt-0 sm:pb-0 text-left text-xl  font-light tracking-wider text-gray-600 sm:text-2xl dark:text-green-400 ">
                                    Packages
                                </p>
                                <div className=" px-5 py-3">
                                    <Packages companyId={companiesQuery.data?.[0]?.id ?? -1} />
                                </div>
                            </div>
                            <div className=" col-span-8  pb-2 bg-white dark:bg-gray-800  rounded-xl shadow overflow-hidden">
                                {/* <div className="mt-0 md:pt-0 text-left sm:text-center font-medium text-gray-500 dark:text-green-400 md:flex"> */}
                                <p className="px-5 pt-3 pb-0 mt-0 sm:pb-0 text-left text-xl  font-light tracking-wider text-gray-600 sm:text-2xl dark:text-green-400 ">
                                    Appointments
                                </p>
                                <div className=" px-5 py-3">
                                    <MyAppointments />
                                </div>
                            </div>
                            <div className=" col-span-8  pb-2 bg-white dark:bg-gray-800  rounded-xl shadow overflow-hidden">
                                {/* <div className="mt-0 md:pt-0 text-left sm:text-center font-medium text-gray-500 dark:text-green-400 md:flex"> */}
                                <p className="px-5 pt-3 pb-0 mt-0 sm:pb-0 text-left text-xl  font-light tracking-wider text-gray-600 sm:text-2xl dark:text-green-400 ">
                                    Important Links
                                </p>
                                <div className=" px-5 py-3">
                                    <Links />
                                </div>
                            </div>
                            <div className="sm:col-span-8 col-span-6   bg-white dark:bg-gray-800  rounded-xl shadow overflow-hidden">
                                {/* <div className="mt-0 md:pt-0 text-left sm:text-center font-medium text-gray-500 dark:text-green-400 md:flex"> */}
                                <p className="px-4 py-5 pb-0 mt-0 sm:pb-0 text-left text-xl sm:p-6  font-light tracking-wider text-gray-600 sm:text-2xl dark:text-green-400 ">
                                    Messages
                                </p>
                                <div className="max-h-96 -pt-6 overflow-y-scroll px-4 py-5">
                                    <Messaging />
                                </div>
                            </div>

                            <div className="sm:col-span-8 col-span-2   bg-white dark:bg-gray-800  rounded-xl shadow overflow-hidden">
                                {/* <div className="mt-0 md:pt-0 text-left sm:text-center font-medium text-gray-500 dark:text-green-400 md:flex"> */}
                                <p className="px-4 py-5 pb-0 mt-0 sm:pb-0 text-left text-xl sm:p-6  font-light tracking-wider text-gray-600 sm:text-2xl dark:text-green-400 ">
                                    Activity
                                </p>
                                {/* </div> */}
                                <div className="max-h-96 -pt-6 overflow-y-scroll px-4 py-5">
                                    <History
                                        companyId={companiesQuery.data?.[0]?.id}
                                        owner={user.id}
                                        progressQuery={progressQueryHistory}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-8 col-span-6 -pt-6 px-4 py-5 bg-white dark:bg-gray-800  sm:p-6 rounded-xl shadow ">
                                <MyInvoices />
                            </div>
                            <div className="sm:col-span-8 col-span-2 -pt-6 px-4 py-5 bg-white dark:bg-gray-800  sm:p-6 rounded-xl shadow ">
                                <MyContacts />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
