/* eslint-disable indent */
import * as React from "react";
import CheckBoxNeoGenControlled from "../../layout/checkbox-controlled";
import states from "../../sections/utilities/states.json";
import TextAreaNeoGenControlled from "../../layout/text-area-controlled";
import InputControlled from "../../layout/input-controlled";
import RadioButtonNeoGenControlled from "../../layout/radiobutton-neogen-controlled";
import FileUploadWidget from "../../layout/file-upload-widget";
import { FileUploadWithMultiple } from "../../typings/api/file-upload";
import { calculate } from "../../sections/utilities/calculate";
import CalendarWidget from "../../sections/utilities/calendar-widget";
import ButtonNeoGen from "../../layout/button-neogen";
import { Entry } from "../processflows/domain/processflow";
import { ButtonActionTypes } from "../../layout/button-creator";
import { useBreakpoint } from "../../hooks/appMedia";
import { PaymentOptions, paymentOptionsKey } from "../../billing/paymentOptions";
import { FinanceOptions } from "../../billing/financeOptions";
import { getEstimatedPayoutFromData } from "../other/actions/getEstimatedPayout";
import Datepicker from "react-tailwindcss-datepicker";
import IndustryPicker from "../../layout/IndustryPicker";
import CustomTable from "../../layout/custom-table";
import PopoverComponent from "../../layout/popover-component";
import DollarInput from "../../layout/dollar-input";
import SignatureCanvas from "react-signature-canvas";
import { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { format } from "date-fns";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useCompany } from "../../companies/hooks/use-company";
import { CurrencyInput } from "../../layout/form/currency-input";
import { ProcessFlowProgressData } from "../../typings/api/processflow-progress-data";
import TableNeogen from "../../layout/table-neogen";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import companyService from "../../services/company.service";
import { Company } from "../../companies/domain/company";
import companyGroupService from "../../services/company-group.service";
import userCompanyService from "../../services/user-company.service";
import SwalNeogenFire from "../../layout/swal-neogen";
import SelectNeoGenMultiple from "../../layout/select-neogen-multiple";
import citiesService from "../../services/cities.service";
import bulletPointsService, { BulletPoint } from "../../services/bullet-points.service";
import bulletPointGroupService, { BulletPointGroup } from "../../services/bullet-point-group.service";
import ApplyToSources from "./modals/apply-to-sources";
import Invoices from "./invoices";
import { Document, Page } from "react-pdf";
import { AlertUser } from "../../layout/alert-field";
import { createAlert } from "../../alerts/actions/create-alert";
import EinNumberInput from "../../layout/form/ein-number-input";
import ZipCodeInput from "../../layout/form/zipcode-input";
import PrintPre from "../../layout/print-pre";
import { POCPage } from "../../poc/poc-page";
import PcmpCensus from "../../sections/pcmp/pcmp-census";
import {
    IconArrowRight,
    IconClock,
    IconEdit,
    IconLock,
    IconPlugConnected,
    IconTax,
    IconUpload,
    IconUsers,
} from "@tabler/icons-react";
import Loader3 from "../../sections/utilities/Loader3";
import LogoBox from "../../layout/LogoBox";
import PCMPIntro from "./pcmp-intro";
import PCMPIntro2 from "./pcmp-intro2";
import PcmpShortCensus from "../../sections/pcmp/pcmp-short-census";
import { useNavigate } from "react-router";
import { makeRequest } from "../shared/utils";
import { useFinchConnect } from "@tryfinch/react-connect";
import NewSignature from "./new-signature";
import { set } from "lodash";
import ModalDialog from "../../layout/modal-dialog";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

type OnClick = (action: ButtonActionTypes) => void;

const FILE_API_URL = process.env.REACT_APP_FILE_SERVER ?? process.env.REACT_APP_API_URL ?? "https://api.clearerc.com/";
const API_URL = process.env.REACT_APP_API_URL ?? "https://api.clearerc.com/";

export const RenderEntry = ({
    entry,
    entryErrors,
    onChange,
    onChangeWithEntry,
    onClick,
    data,
    allEntries,
    noMargin,
    drawWithFlex,
    isMobile,
    isTablet,
    getFileKey,
    companyId,
    pfIsOptional,
    progressData,
    changeCompanyAndProcessflow,
    processflowId,
}: {
    entry: Entry;
    entryErrors?: string[];
    onChange: (value: any) => void;
    onChangeWithEntry: (value: any, entry: Entry) => void;
    onClick: OnClick;
    data: Record<string, any>;
    allEntries: any;
    noMargin?: boolean;
    drawWithFlex?: boolean;
    isMobile?: boolean;
    isTablet?: boolean;
    getFileKey?: (entry: Entry) => string;
    companyId?: number;
    pfIsOptional?: number;
    progressData?: ProcessFlowProgressData;
    changeCompanyAndProcessflow?: any;
    processflowId?: number;
}) => {
    const estimatedAmount = getEstimatedPayoutFromData({ data });
    const user = window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user") ?? "") : {};
    const isAdmin = (user?.user?.roleGroups || []).find((r: any) => r.id === 4);
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const [internalCompany, setInternalCompany] = React.useState<Company | null>(null);
    const [showCreateCompanyGroup, setShowCreateCompanyGroup] = React.useState(false);
    const [companyGroupName, setCompanyGroupName] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    const [showAddNewCompany, setShowAddnewCompany] = React.useState(false);
    const [showApplyToSources, setShowApplyToSources] = useState(false);
    const [currentBulletPointGroups, setCurrentBulletPointGroups] = React.useState<BulletPointGroup[]>([]);
    const [currentBulletPoints, setCurrentBulletPoints] = React.useState<BulletPoint[]>([]);
    const [pageNumber, setPageNumber] = React.useState<number>(1);
    const [numPages, setNumPages] = React.useState<number>(1);
    const [showEmailContract, setShowEmailContract] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [contractType, setContractType] = useState("BSA");
    const queryCache = useQueryClient();
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    // const auth = useAuth();
    // const authToken = auth.expectAuthToken();
    // const companyId = 123;
    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const createLinkMutation = useMutation({
        mutationFn: async ({ code, companyId }: { code: string; companyId: number }) => {
            await makeRequest({ method: "post", path: "/finch/link", authToken, data: { code, companyId } });
        },
    });

    const { open } = useFinchConnect({
        clientId: process.env.REACT_APP_FINCH_CLIENT_ID,
        products: ["company", "directory", "individual", "ssn", "benefits", "payment", "pay_statement", "employment"],
        sandbox: "finch",
        onSuccess: async (data) => {
            console.log("onSuccess", data);
            createLinkMutation.mutate({ code: data.code, companyId: companyId ?? 123 });
            setStep(3);

            setTimeout(() => {
                onClick("nextStep");
            }, 2000);
            setIsLoading(false);
        },
        onError: (error) => {
            console.log("onError", error);
        },
        onClose: () => {
            console.log("onClose");
            setIsLoading(false);
        },
    });
    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }
    const citiesQuery = useQuery(["cities"], async () => {
        const response = await citiesService.getAll();
        if (response) {
            return response.data;
        }
    });
    const bulletPointsQuery = useQuery(["bulletPoints"], async () => {
        const response = await bulletPointsService.getAll();
        if (response) {
            return response.data;
        }
    });
    const bulletPointsGroupQuery = useQuery(["bulletPointsGroup"], async () => {
        const response = await bulletPointGroupService.getAll();
        if (response) {
            return response.data;
        }
    });
    const companyQuery = useQuery(["companies"], async () => {
        const response = await companyService.getAll();
        if (response) {
            const c = response.data?.find((c: Company) => c.id === companyId) ?? null;
            console.error(c);
            setInternalCompany(c);
            return response.data;
        }
    });
    React.useEffect(() => {
        if (companyQuery.data) {
            const c = companyQuery.data?.find((c: Company) => c.id === companyId) ?? null;
            setInternalCompany(c);
        }
    }, [companyId, companyQuery.data]);
    if (entry.field === paymentOptionsKey) {
        return (
            <PaymentOptions
                estimatedAmount={estimatedAmount}
                defaultValue={data[entry.field]}
                onChange={(id) => {
                    onChange(id);
                }}
            />
        );
    }

    if (entry.field === "finance_options") {
        return (
            <FinanceOptions
                estimatedAmount={estimatedAmount}
                defaultValue={data[entry.field]}
                onChange={(id) => {
                    onChange(id);
                }}
            />
        );
    }

    function getAvailableCities(data: any) {
        let filtered = citiesQuery?.data;
        if (data["Company 1 Verify state"] !== undefined) {
            if (data["Company 1 Verify state"][0] === "[") {
                const enabledStates = states.filter((s) => JSON.parse(data["Company 1 Verify state"]).includes(s.id));
                const enabledStateNames = enabledStates.map((s) => s.name);
                filtered = filtered?.filter((c: any) => enabledStateNames.includes(c.state));
            } else {
                filtered = filtered?.filter((c: any) => c.state === data["Company 1 Verify state"]);
            }
        }
        if (filtered) {
            const unique: { [key: string]: number } = {};
            const distinct = [];
            for (let i = 0; i < filtered.length; i++) {
                if (!unique[filtered[i].city]) {
                    distinct.push(filtered[i]);
                    unique[filtered[i].city] = 1;
                }
            }
            filtered = distinct;
        }
        // TODO: Check for counties
        return filtered;
    }
    function getAvailableCounties(data: any) {
        let filtered = citiesQuery?.data;
        if (data["Company 1 Verify state"] !== undefined) {
            if (data["Company 1 Verify state"][0] === "[") {
                const enabledStates = states.filter((s) => JSON.parse(data["Company 1 Verify state"]).includes(s.id));
                const enabledStateNames = enabledStates.map((s) => s.name);
                filtered = filtered?.filter((c: any) => enabledStateNames.includes(c.state));
            } else {
                filtered = filtered?.filter((c: any) => c.state === data["Company 1 Verify state"]);
            }
        }
        if (data["Company 1 Verify city"] !== undefined) {
            if (data["Company 1 Verify city"][0] === "[") {
                const enabledCities = citiesQuery?.data?.filter((s) =>
                    JSON.parse(data["Company 1 Verify city"]).includes(s.id),
                );
                const enabledCityNames = enabledCities?.map((s) => s.city);
                filtered = filtered?.filter((c: any) => enabledCityNames?.includes(c.city));
            } else {
                filtered = filtered?.filter((c: any) => c.city === data["Company 1 Verify city"]);
            }
        }
        if (filtered) {
            const unique: { [key: string]: number } = {};
            const distinct = [];
            for (let i = 0; i < filtered.length; i++) {
                if (!unique[filtered[i].county]) {
                    distinct.push(filtered[i]);
                    unique[filtered[i].county] = 1;
                }
            }
            filtered = distinct;
        }

        return filtered;
    }

    const ContractPicker = ({ addHtml }: { addHtml: boolean }) => {
        const [loggedInUser, setUser] = useRecoilState(userAtom);
        const [showSignatureCanvas, setShowSignatureCanvas] = useState(data[entry?.field] === undefined);
        const signatureCanvasRef = useRef(null);
        const [isEmpty, setIsEmpty] = useState(true);
        const userCompany = useCompany({ authToken, id: companyId || -1 });

        // const replaceVariables = (htmlString: string, variables: string[], entries: any) => {
        //     console.log({ entries });
        //     if (!variables) {
        //         return htmlString;
        //     }
        //     for (const variableName in variables) {
        //         const placeholder = `{{${variableName}}}`;
        //         console.log({ placeholder, variableName, value: entries[variableName] });
        //         htmlString = htmlString.replace(new RegExp(placeholder, "g"), entries[variableName]);
        //     }
        //     // for (const [variable, value] of Object.entries(variables)) {
        //     //     const placeholder = `{{${variable}}}`;
        //     //     console.log(placeholder, value);
        //     //     htmlString = htmlString.replace(new RegExp(placeholder, "g"), value);
        //     // }
        //     return htmlString;
        // };

        // const variables: { [key: string]: string } = {
        //     companyName: userCompany?.data?.name || "Company name unknown",
        //     companyAddress: userCompany?.data?.address || "Company address unknown",
        //     city: userCompany?.data?.city || "Company city unknown",
        //     state: userCompany?.data?.state || "Company state unknown",
        //     country: userCompany?.data?.country || "Company country unknown",
        //     email: loggedInUser.email,
        //     ownerFirstName: loggedInUser.firstName || "-",
        //     ownerLastName: loggedInUser.lastName || "-",
        // };

        // eslint-disable-next-line no-case-declarations
        const baseHtml = entry.value.replace(/\n/g, "<br />");
        console.log({ data });
        const html: string = baseHtml
            .replace(/{{(.*?)}}/g, (_match: string, fieldFound: string) => {
                // Look for the field value in the current step. If it's not there then
                // either the customer hasn't entered it yet or it's not in the current
                // step. So we look for it in the previous steps.
                // The other option is that the field is a calculation, in which case
                // we need to look for the field values to calculate it.
                // The val variable will hold the value of the field and its details.
                console.log({ fieldFound });
                const val: Entry = data?.[fieldFound];

                // If the field type is a state, return the long form state name.
                if (val?.type === "state") {
                    return states.find((s: any) => s.id === val.fieldValue)?.name ?? "";
                }

                // If the field type is a checkbox, return it as Yes or No
                if (val?.type === "checkbox") {
                    return val.fieldValue ? "Yes" : "No";
                }

                // If we didn't find the field value it might just have not been filled out yet.
                // We should know from the field type if it's a calculation or not.

                // First look through all entries and see if we can find the field name.
                const field: Entry | undefined = (allEntries ?? []).find((e: any) => {
                    return e.id === fieldFound;
                });

                if (!val && field?.type === "valueCalculator") {
                    try {
                        let total = 0;
                        const foundFull = allEntries?.find((p: any) => p.id === fieldFound);
                        const found = foundFull.value;

                        if (found) {
                            for (const calculation of JSON.parse(found)) {
                                let val1 = 0;
                                let val2 = 0;
                                if (calculation.field1 === "**num") {
                                    val1 = calculation.field1number;
                                } else {
                                    val1 = data?.[calculation.field1]?.toString().replace(/\D/g, "");
                                }
                                if (calculation.field2 === "**num") {
                                    val2 = calculation.field2number;
                                } else {
                                    val2 = data?.[calculation.field2]?.toString().replace(/\D/g, "");
                                }
                                const opResult = calculate(val1, val2, calculation.operator);
                                total += opResult;
                            }
                        }
                        return (Number.isNaN(total) ? 0 : total).toLocaleString();
                    } catch (e) {
                        return "(<></>)";
                    }
                }
                console.log({ val });
                return val?.fieldValue ?? val ?? "";
            })
            .replace(/\n/g, "<br />");
        console.log({ html });

        const HeaderComponent = (
            <div className="mb-3 mt-8">
                <h3 className="font-bold">Signed by:</h3>
            </div>
        );

        const NameComponent = (
            <div className="my-3">
                <p className="font-bold">
                    Name: {loggedInUser.firstName} {loggedInUser.lastName}
                </p>
                <p className="font-bold">Email: {loggedInUser.email}</p>
                <p className="font-bold">Signed at: {format(new Date(), "dd/LL/yyyy kk:mm")}</p>
            </div>
        );

        const handleCaptureSignature = async () => {
            // @ts-ignore
            const signatureDataUrl = signatureCanvasRef.current?.toDataURL();
            const img = new Image();
            img.src = signatureDataUrl;
            const contractElement = document.getElementById("contract");
            if (addHtml) {
                const headerContainer = document.createElement("div");
                ReactDOM.render(HeaderComponent, headerContainer);

                const nameContainer = document.createElement("div");
                ReactDOM.render(NameComponent, nameContainer);

                if (contractElement) {
                    while (headerContainer.firstChild) {
                        contractElement.appendChild(headerContainer.firstChild);
                    }
                }
                contractElement?.appendChild(img);

                if (contractElement) {
                    while (nameContainer.firstChild) {
                        contractElement.appendChild(nameContainer.firstChild);
                    }
                }
            }

            const c = document.getElementById("contract");
            const htmlString = c?.outerHTML;
            if (addHtml) {
                onChange(htmlString);
            } else {
                onChange(signatureDataUrl + "%%%%%" + new Date().toISOString());
            }
            setShowSignatureCanvas(false);
        };
        if (!addHtml && data[entry.field]) {
            return (
                <>
                    <img
                        src={data[entry.field].split("%%%%%")[0]}
                        alt="signature"
                        className="border border-gray-500 w-1/2"
                    />
                    {/* {data[entry.field].split("%%%%%")[1]} */}
                </>
            );
        }

        return (
            <>
                <div key={entry.id} className={" " + (entry.widthOf300Cols ?? "w-full") + " mb-5"}>
                    <div
                        id="contract"
                        className="ql-snow ql-read-only !font-normal !text-sm !tracking-wider !leading-normal !text-gray-500 !not-italic !no-underline "
                        dangerouslySetInnerHTML={{ __html: data[entry.field] || html }}
                    />
                    {entry.helpDocument && (
                        <>
                            <PopoverComponent document={entry.helpDocument} />
                        </>
                    )}
                </div>
                {showSignatureCanvas && (
                    <>
                        <div className="my-4">
                            <p className="text-gray-500">Your signature</p>
                            <div className="border-gray-500 w-[500px] border-2">
                                <SignatureCanvas
                                    ref={signatureCanvasRef}
                                    penColor="black"
                                    canvasProps={{ width: 500, height: 200, className: "signature-canvas" }}
                                    onBegin={() => setIsEmpty(false)}
                                />
                            </div>
                            <ButtonNeoGen
                                type="outline"
                                className="mr-2"
                                onClick={() => {
                                    // @ts-ignore
                                    signatureCanvasRef.current?.clear();
                                    setIsEmpty(true);
                                }}
                            >
                                Clear
                            </ButtonNeoGen>
                            <ButtonNeoGen
                                onClick={handleCaptureSignature}
                                // @ts-ignore
                                disabled={isEmpty}
                                className="mt-2"
                            >
                                Save signature
                            </ButtonNeoGen>
                        </div>
                    </>
                )}
            </>
        );
    };

    let width = "";
    switch (entry.widthOf300Cols) {
        case 300:
            width = "  w-full ";
            break;
        case 200:
            width = "  w-2/3 inline-block md:pr-5 ";
            break;
        case 150:
            width = isMobile ? " w-full " : "  w-1/2 inline-block md:pr-5 ";
            break;
        case 100:
            width =
                " w-full inline-block align-top " +
                " md:align-top md:w-1/2 md:pr-5 " +
                "  lg:align-top lg:w-1/3 lg:inline-block lg:pr-5 ";
            break;
        case 75:
            width = isMobile
                ? " w-full inline-block "
                : isTablet
                ? " w-1/2 inline-block align-top pr-5 "
                : "  w-1/4 inline-block pr-5 align-top ";
            break;
        case 60:
        case 50:
            width = "  w-1/5 inline-block md:pr-5 h-full ";
            break;
        default:
            width = "  w-full inline-block ";
            break;
    }

    width += " my-1 top-0  left-0 h-full ";
    if (entry.hideWhenFilled && data[entry.field] && data[entry.field].length > 0) {
        width += " hidden ";
    }

    switch (entry.type) {
        case "textdisplay":
        case "textDisplay":
            return (
                <div className={" " + width + (noMargin ? " m-0" : " mb-5")}>
                    <p className="text-sm text-gray-500 tracking-wider">
                        <span className="text-sm  tracking-wider font-bold text-indigo-600 dark:text-green-500">
                            {entry.value.trim()}:{" "}
                        </span>
                        {/* <b>{typeof (data[entry.field] === "String") ? data[entry.field] : ""}</b> */}
                        {typeof data[entry.field] === "string" ? data[entry.field] : typeof data[entry.field]}
                        {/* {typeof data[entry.field] !== "string" && <PrintPre>{entry.field}</PrintPre>} */}
                        {data?.[`${entry.field}_chipLabel`] && (
                            <div
                                className={`ml-1 py-0 px-2 inline-block text-xxs rounded-xl ${
                                    data?.[`${entry.field}_chipLabel`] === "Qualified"
                                        ? " bg-green-100 border-green-400 text-green-600 dark:bg-green-800 dark:text-green-200 "
                                        : data?.[`${entry.field}_chipLabel`] === "Not qualified"
                                        ? " bg-red-100 border-red-400 text-red-600 dark:bg-red-800 dark:text-red-200 "
                                        : " bg-blue-100 border-blue-400 text-blue-600 "
                                } `}
                            >
                                {data?.[`${entry.field}_chipLabel`]}
                            </div>
                        )}
                    </p>
                </div>
            );
        case "previewURL":
            return (
                <>
                    <div>
                        <div className="flex justify-between">
                            <div className="flex align-middle gap-2">
                                <ButtonNeoGen
                                    disabled={pageNumber === 1}
                                    onClick={() => {
                                        const pn = numPages && pageNumber > 1 ? pageNumber - 1 : pageNumber;
                                        setPageNumber(pn);
                                        localStorage.setItem(`${entry.field}-pageNumber`, pn.toString());
                                    }}
                                >
                                    Back
                                </ButtonNeoGen>
                                <ButtonNeoGen
                                    disabled={pageNumber === numPages}
                                    onClick={() => {
                                        const pn = numPages && pageNumber < numPages ? pageNumber + 1 : pageNumber;
                                        setPageNumber(pn);
                                        localStorage.setItem(`${entry.field}-pageNumber`, pn.toString());
                                    }}
                                >
                                    Next
                                </ButtonNeoGen>
                            </div>
                            <p>
                                Page {pageNumber} of {numPages}
                            </p>
                        </div>
                        <Document file={`${entry.field}`} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} scale={1.5} className="m-auto text-center" />
                        </Document>
                    </div>
                </>
            );
            break;
        case "downloadURL":
            return (
                // <div className={" inline-block w-full lg:w-1/2 min-[1048px]:w-1/3 min-[2000px]:w-1/4 mr-5 "}>
                //     <a href={entry.field} target="_blank" rel="noreferrer" className=" text-center ">
                //         <div className="mb-5">
                //             <Label htmlFor="file" text={"aaa"} />
                //         </div>
                //         <div
                //             className={
                //                 "border shadow-lg p-5 text-center rounded-lg border-gray-200 dark:border-gray-700 dark:bg-gray-900 " +
                //                 // " inline-block w-full lg:w-1/2 min-[1048px]:w-1/3 min-[2000px]:w-1/4 mr-5 " +
                //                 " mb-5 hover:text-indigo-500 dark:border-gray-700 dark:hover:border-indigo-500 dark:hover:bg-indigo-900/30 dark:hover:text-green-500"
                //             }
                //         >
                //             <i className="fas fa-download fa-2x"></i>
                //             <br />
                //             <br />
                //             {entry.value}
                //         </div>
                //     </a>
                // </div>
                <a href={entry.field} target="_blank" rel="noreferrer" className=" text-center ">
                    <div className=" text-left md:text-center  w-full lg:w-1/2 min-[1048px]:w-1/3 min-[2000px]:w-1/4 inline-block md:pr-5  ">
                        <label className="-top-1 inline-block relative text-sm font-normal tracking-wider leading-normal text-gray-400 dark:text-gray-400">
                            Download Form
                        </label>
                        <div className=" flex w-full  bg-grey-lighter my-auto justify-center flex-grow ">
                            <label className="w-full md:w-64 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg  tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-blue-500 dark:bg-gray-900 border-1 my-5 shadow-lg dark:border-gray-700 dark:hover:border-indigo-500 dark:hover:bg-indigo-900/30 dark:hover:text-green-500">
                                <svg
                                    className="w-8 h-8"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 52 52"
                                >
                                    <path
                                        d="M48.5,31h-3c-0.8,0-1.5,0.7-1.5,1.5v10c0,0.8-0.7,1.5-1.5,1.5h-33C8.7,44,8,43.3,8,42.5v-10
		C8,31.7,7.3,31,6.5,31h-3C2.7,31,2,31.7,2,32.5V46c0,2.2,1.8,4,4,4h40c2.2,0,4-1.8,4-4V32.5C50,31.7,49.3,31,48.5,31z"
                                    />
                                    <path
                                        d="M25,37.6c0.6,0.6,1.5,0.6,2.1,0l13.5-13.5c0.6-0.6,0.6-1.5,0-2.1l-2.1-2.1c-0.6-0.6-1.5-0.6-2.1,0l-5.6,5.6
		c-0.6,0.6-1.7,0.2-1.7-0.7V3.5C29,2.7,28.2,2,27.5,2h-3C23.7,2,23,2.7,23,3.5v21.2c0,0.9-1.1,1.3-1.7,0.7l-5.6-5.6
		c-0.6-0.6-1.5-0.6-2.1,0L11.5,22c-0.6,0.6-0.6,1.5,0,2.1L25,37.6z"
                                    />
                                </svg>
                                <span className="mt-2 text-base leading-normal">{entry.value}</span>
                            </label>
                        </div>
                    </div>
                </a>
            );

        case "bulletPointPicker":
            const currentEntries = JSON.parse(data[entry.field] ?? "[]") || [];
            const cB = [];
            const cBG = [];
            for (const e of currentEntries) {
                // alert(e);
                if (e.toString()?.includes("g:")) {
                    cBG.push(e);
                } else {
                    cB.push(e);
                }
            }
            // alert(JSON.stringify(cBG));
            // setCurrentBulletPoints(cB);
            // setCurrentBulletPointGroups(cBG);
            return (
                <>
                    {bulletPointsGroupQuery.data?.map((group: BulletPointGroup) => (
                        <div
                            key={group.id}
                            className="border border-white shadow-md rounded-xl py-2 px-5 bg-gray-100 mb-5 dark:bg-gray-900 dark:border-gray-700"
                        >
                            <CheckBoxNeoGenControlled
                                setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    try {
                                        if (data[entry.field]?.includes("::")) {
                                            data[entry.field] = "[]";
                                        }
                                        const currentValue = JSON.parse(data[entry.field] ?? "[]") || [];
                                        const newValue = e.target.checked
                                            ? [...currentValue, "g:" + group.id]
                                            : currentValue.filter((id: string) => id !== "g:" + group.id);
                                        onChange(JSON.stringify(newValue));
                                    } catch (err) {
                                        console.error(err);
                                        onChange(JSON.stringify([]));
                                    }
                                }}
                                value={JSON.parse(data[entry.field] ?? "[]")?.includes("g:" + group.id ?? -1)}
                                name={entry.field}
                                label={group.name}
                                description={entry.description ?? ""}
                            />
                            {JSON.parse(data[entry.field] ?? "[]")?.includes("g:" + group.id ?? -1) && (
                                <div className="mt-5">
                                    <div className="flex gap-3 mb-5">
                                        <ButtonNeoGen
                                            onClick={() => {
                                                //enableAll
                                                const currentEntries = JSON.parse(data[entry.field] ?? "[]") || [];
                                                // Go through all the bullet points and add them to the currentEntries
                                                for (const bp of bulletPointsQuery.data ?? []) {
                                                    if (
                                                        bp.bulletPointGroup === group.id &&
                                                        !currentEntries.includes(bp.id)
                                                    ) {
                                                        currentEntries.push(bp.id);
                                                    }
                                                }
                                                onChange(JSON.stringify(currentEntries));
                                            }}
                                        >
                                            Enable All For {group.name}
                                        </ButtonNeoGen>
                                        <ButtonNeoGen
                                            onClick={() => {
                                                //disableAll
                                                const currentEntries = JSON.parse(data[entry.field] ?? "[]") || [];
                                                for (const bp of bulletPointsQuery.data ?? []) {
                                                    if (
                                                        bp.bulletPointGroup === group.id &&
                                                        currentEntries.includes(bp.id)
                                                    ) {
                                                        currentEntries.splice(currentEntries.indexOf(bp.id), 1);
                                                    }
                                                }
                                                onChange(JSON.stringify(currentEntries));
                                            }}
                                        >
                                            Disable All For {group.name}
                                        </ButtonNeoGen>
                                    </div>
                                    {bulletPointsQuery.data
                                        ?.filter((bp: BulletPoint) => bp.bulletPointGroup === group.id)
                                        .map((bp: BulletPoint) => (
                                            <div key={bp.id} className="mb-3">
                                                <CheckBoxNeoGenControlled
                                                    setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const currentValue = JSON.parse(data[entry.field]) || [];
                                                        const newValue = e.target.checked
                                                            ? [...currentValue, bp.id]
                                                            : currentValue.filter((id: number) => id !== bp.id);
                                                        // setCurrentBulletPoints(newValue);
                                                        onChange(JSON.stringify(newValue));
                                                    }}
                                                    value={JSON.parse(data[entry.field] ?? "[]").includes(bp.id ?? -1)}
                                                    name={entry.field}
                                                    label={bp.description}
                                                    description={entry.description}
                                                    helpDocument={entry.helpDocument}
                                                    required={entry.required}
                                                />
                                                {entry.description}
                                            </div>
                                        ))}
                                </div>
                            )}
                        </div>
                    ))}
                    <ButtonNeoGen
                        onClick={() => {
                            setShowApplyToSources(true);
                        }}
                    >
                        Apply To Sources
                    </ButtonNeoGen>
                    <ApplyToSources
                        sources={{
                            count: data["Revenue sources business 1"],
                            "Source 1": data["Source or service 1 company 1"],
                            "Source 2": data["Source or service 2 company 1"],
                            "Source 3": data["Source or service 3 company 1"],
                            "Source 4": data["Source or service 4 company 1"],
                            "Source 5": data["Source or service 5 company 1"],
                            "Source 6": data["Source or service 6 company 1"],
                            "Source 7": data["Source or service 7 company 1"],
                            "Source 8": data["Source or service 8 company 1"],
                            "Source 9": data["Source or service 9 company 1"],
                            "Source 10": data["Source or service 10 company 1"],
                        }}
                        show={showApplyToSources}
                        close={() => setShowApplyToSources(false)}
                        data={data}
                        allEntries={allEntries}
                        onChangeWithEntry={onChangeWithEntry}
                    />
                </>
            );
        case "bulletPointForSourcePicker":
            try {
                return (
                    <>
                        {bulletPointsGroupQuery.data?.map((group: BulletPointGroup) => (
                            <div
                                key={group.id}
                                className="border border-white shadow-md rounded-xl p-5 bg-gray-100 mb-5 dark:bg-gray-900 dark:border-gray-700"
                            >
                                <CheckBoxNeoGenControlled
                                    setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        try {
                                            if (data[entry.field]?.includes("::")) {
                                                data[entry.field] = "[]";
                                            }
                                            const currentValue = JSON.parse(data[entry.field] ?? "[]") || [];
                                            const newValue = e.target.checked
                                                ? [...currentValue, "g:" + group.id]
                                                : currentValue.filter((id: string) => id !== "g:" + group.id);
                                            onChange(JSON.stringify(newValue));
                                        } catch (err) {
                                            console.error(err);
                                            onChange(JSON.stringify([]));
                                        }
                                    }}
                                    value={JSON.parse(data[entry.field] ?? "[]")?.includes("g:" + group.id ?? -1)}
                                    name={entry.field}
                                    label={group.name}
                                    description={entry.description ?? ""}
                                />
                                {JSON.parse(data[entry.field] ?? "[]")?.includes("g:" + group.id ?? -1) && (
                                    <div className="mt-5">
                                        <div className="flex gap-3 mb-5">
                                            <ButtonNeoGen
                                                onClick={() => {
                                                    //enableAll
                                                    const currentEntries = JSON.parse(data[entry.field] ?? "[]") || [];
                                                    // Go through all the bullet points and add them to the currentEntries
                                                    for (const bp of bulletPointsQuery.data ?? []) {
                                                        if (
                                                            bp.bulletPointGroup === group.id &&
                                                            !currentEntries.includes(bp.id)
                                                        ) {
                                                            currentEntries.push(bp.id);
                                                        }
                                                    }
                                                    onChange(JSON.stringify(currentEntries));
                                                }}
                                            >
                                                Enable All For {group.name}
                                            </ButtonNeoGen>
                                            <ButtonNeoGen
                                                onClick={() => {
                                                    //disableAll
                                                    const currentEntries = JSON.parse(data[entry.field] ?? "[]") || [];
                                                    for (const bp of bulletPointsQuery.data ?? []) {
                                                        if (
                                                            bp.bulletPointGroup === group.id &&
                                                            currentEntries.includes(bp.id)
                                                        ) {
                                                            currentEntries.splice(currentEntries.indexOf(bp.id), 1);
                                                        }
                                                    }
                                                    onChange(JSON.stringify(currentEntries));
                                                }}
                                            >
                                                Disable All For {group.name}
                                            </ButtonNeoGen>
                                        </div>
                                        {bulletPointsQuery.data
                                            ?.filter((bp: BulletPoint) => bp.bulletPointGroup === group.id)
                                            .map((bp: BulletPoint) => (
                                                <div
                                                    key={bp.id}
                                                    className="border border-white shadow-md rounded-xl p-5 bg-gray-100 mb-5 dark:bg-gray-900 dark:border-gray-700"
                                                >
                                                    <CheckBoxNeoGenControlled
                                                        setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            const currentValue = JSON.parse(data[entry.field]) || [];
                                                            const newValue = e.target.checked
                                                                ? [...currentValue, bp.id]
                                                                : currentValue.filter((id: number) => id !== bp.id);
                                                            // setCurrentBulletPoints(newValue);
                                                            onChange(JSON.stringify(newValue));
                                                        }}
                                                        value={JSON.parse(data[entry.field] ?? "[]").includes(
                                                            bp.id ?? -1,
                                                        )}
                                                        name={entry.field}
                                                        label={bp.description}
                                                        description={entry.description}
                                                        helpDocument={entry.helpDocument}
                                                        required={entry.required}
                                                    />
                                                    {entry.description}
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </>
                );
            } catch (err) {
                console.error(err);
                return <></>;
            }
        case "video":
            return (
                <div className={" " + width + " mb-5"}>
                    <span className="text-sm text-gray-500 tracking-wider">{entry.value}</span>
                    <video controls width="100%">
                        <source src={FILE_API_URL + "/files/" + entry.field} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        case "checkbox":
            return (
                <div className={" " + width + " mb-5"}>
                    <CheckBoxNeoGenControlled
                        setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(e.currentTarget.checked);
                        }}
                        value={data[entry.field]}
                        name={entry.field}
                        label={entry.value}
                        description={entry.description}
                        helpDocument={entry.helpDocument}
                        required={entry.required}
                    />
                </div>
            );
        case "reviewedBy":
            return (
                <div className={" " + width + " mb-5"}>
                    {/* <PrintPre> */}
                    {}
                    {/* </PrintPre> */}
                    <CheckBoxNeoGenControlled
                        setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(e.currentTarget.checked + "::" + user.user.email);
                        }}
                        value={JSON.parse(data[entry.field]?.split("::")?.[0] ?? "false")}
                        name={entry.field}
                        label={
                            entry.value +
                            " " +
                            (JSON.parse(data[entry.field]?.split("::")?.[0] ?? "false")
                                ? "Reviewed By: " + data[entry.field]?.split("::")?.[1] ?? ""
                                : "")
                        }
                        description={entry.description}
                        helpDocument={entry.helpDocument}
                        required={entry.required}
                    />
                </div>
            );
        case "PCMP":
            return <POCPage />;
        case "FullCensus":
            return <PcmpCensus company={internalCompany ?? -1} />;
        case "ShortCensus":
            return <PcmpShortCensus company={internalCompany ?? -1} />;
        case "industryPicker":
            return (
                <div className={" " + width + " mb-5 align-bottom pb-1"}>
                    <IndustryPicker
                        label={entry.value}
                        setIndustries={(e: number[] | null | number) => {
                            onChange(e);
                        }}
                        industries={data[entry.field]}
                    />
                    {entry.description && <p className="text-sm text-gray-500 tracking-wider">{entry.description}</p>}
                </div>
            );
        case "state":
            return (
                <div className={" " + width + " mb-5"} title={entry.field}>
                    <SelectNeoGenMultiple
                        onChange={(e: any[]) => {
                            const arr = [];
                            try {
                                for (const i of e) {
                                    arr.push(i);
                                }
                            } catch (err) {
                                console.log({ err, e });
                            }
                            onChange(JSON.stringify([...new Set(arr)]));
                        }}
                        value={
                            data[entry.field]?.[0] === "["
                                ? JSON.parse(data[entry.field] ?? "[]")
                                : states.filter((s) => {
                                      return s.id === data[entry.field];
                                  }) ?? []
                        }
                        options={states}
                        label={entry.value}
                        required={entry.required}
                    />
                    <p className="text-sm text-gray-500 tracking-wider">{entry.description}</p>
                </div>
            );
        case "city":
            return (
                <div className={" " + width + " mb-5"} title={entry.field}>
                    <SelectNeoGenMultiple
                        onChange={(e: any[]) => {
                            const arr = [];
                            try {
                                for (const i of e) {
                                    arr.push(i);
                                }
                            } catch (err) {
                                console.log({ err, e });
                            }
                            onChange(JSON.stringify([...new Set(arr)]));
                        }}
                        value={
                            data[entry.field]?.[0] === "["
                                ? JSON.parse(data[entry.field] ?? "[]")
                                : states.filter((s) => {
                                      return s.id === data[entry.field];
                                  }) ?? []
                        }
                        options={(
                            getAvailableCities(data)?.map((c: any) => {
                                return { name: c.city, id: c.id };
                            }) ?? []
                        ).sort((a, b) => a.name.localeCompare(b.name))}
                        label={entry.value}
                        required={entry.required}
                    />
                    <p className="text-sm text-gray-500 tracking-wider">{entry.description}</p>
                </div>
            );
        case "county":
            return (
                <div className={" " + width + " mb-5"} title={entry.field}>
                    <SelectNeoGenMultiple
                        onChange={(e: any[]) => {
                            const arr = [];
                            if (Array.isArray(e)) {
                                for (const i of e) {
                                    arr.push(i);
                                }
                            }
                            onChange(JSON.stringify([...new Set(arr)]));
                        }}
                        value={
                            data[entry.field]?.[0] === "["
                                ? JSON.parse(data[entry.field] ?? "[]")
                                : states.filter((s) => {
                                      return s.id === data[entry.field];
                                  }) ?? []
                        }
                        options={(
                            getAvailableCounties(data)?.map((c: any) => {
                                return { name: c.county, id: c.id };
                            }) ?? []
                        ).sort((a, b) => a.name.localeCompare(b.name))}
                        label={entry.value}
                        required={entry.required}
                    />
                    <p className="text-sm text-gray-500 tracking-wider">{entry.description}</p>
                </div>
            );
        case "textarea":
            return (
                <div className={" " + width + " mb-5"}>
                    <TextAreaNeoGenControlled
                        setValue={(e: string) => {
                            onChange(e);
                        }}
                        value={data?.[entry.field] ?? entry.defaultVal ?? ""}
                        name={entry.field}
                        label={entry.value}
                        description={entry.description}
                        required={entry.required}
                        helpDocument={entry.helpDocument}
                        autoComplete="off"
                    />
                </div>
            );
        case "textfield": {
            return (
                <div className={" " + width + " self-start"} title={entry.field}>
                    <InputControlled
                        setValue={(e: string) => {
                            onChange(e);
                        }}
                        required={entry.required}
                        disabled={entry.disabled}
                        password={entry.password}
                        type={entry.password ? "password" : "text"}
                        value={data?.[entry.field] ?? entry.defaultVal ?? ""}
                        name={entry.field}
                        label={entry.value}
                        helpDocument={entry.helpDocument}
                        description={entry.description}
                        errors={entryErrors}
                        chipLabel={data?.[`${entry.field}_chipLabel`]}
                        autoComplete="off"
                    />
                </div>
            );
        }
        case "dollar":
            return (
                <div className={" " + width + " self-start"}>
                    <p className="text-sm text-gray-500 tracking-wider">{entry.value}</p>
                    <DollarInput
                        setValue={(e: string) => {
                            onChange(e);
                        }}
                        value={data?.[entry.field] ?? entry.defaultVal ?? ""}
                        autoComplete="off"
                    />
                </div>
            );
        case "currency":
            return (
                <div className={" " + width + " self-start"}>
                    <div className="flex justify-between items-center mb-1">
                        <p className="text-sm text-gray-400 tracking-wider">
                            {entry.value}
                            {entry.required && (
                                <span
                                    className="inline-block text-lg leading-none text-red-500 font-extralight pl-[1px] mr-[1px]"
                                    style={{ top: 10 }}
                                >
                                    *
                                </span>
                            )}
                        </p>
                        {data?.[`${entry.field}_chipLabel`] && (
                            <div
                                className={`text-xs rounded-xl ${
                                    data?.[`${entry.field}_chipLabel`] === "Qualified"
                                        ? " bg-green-100 border-green-400 text-green-600 "
                                        : data?.[`${entry.field}_chipLabel`] === "Not qualified"
                                        ? " bg-red-100 border-red-400 text-red-600 "
                                        : " bg-blue-100 border-blue-400 text-blue-600 "
                                } px-2 py-1`}
                            >
                                {data?.[`${entry.field}_chipLabel`]}
                            </div>
                        )}
                    </div>
                    <CurrencyInput
                        required={entry.required}
                        disabled={entry.disabled}
                        onChange={(e) => {
                            onChange(Number.parseFloat(e.target.value));
                        }}
                        value={data?.[entry.field] ?? entry.defaultVal ?? ""}
                        autoComplete="off"
                    />
                </div>
            );
        case "customTable":
            return (
                <div className={" " + width + " mb-5"}>
                    <p className="text-sm text-gray-500 tracking-wider">{entry.value}</p>
                    <CustomTable
                        data={data?.[entry.field]?.data ?? []}
                        columns={data?.[entry.field]?.columns ?? []}
                        setData={(e: any) => {
                            onChange({ data: e, columns: data?.[entry.field]?.columns ?? [] });
                        }}
                        setColumns={(e: any) => {
                            onChange({ data: data?.[entry.field]?.data, columns: e ?? [] });
                        }}
                    />
                </div>
            );

        case "valueCalculator":
            // Doesn't draw anything - it gets included in HTML
            // try {
            //     let total = 0;

            //     const foundFull = allEntries?.find((p: any) => p.id === entry.field);
            //     const found = foundFull.value;

            //     if (found) {
            //         for (const calculation of JSON.parse(found)) {
            //             let val1 = 0;
            //             let val2 = 0;
            //             if (calculation.field1 === "**num") {
            //                 val1 = calculation.field1number;
            //             } else if (data?.[calculation.field1]) {
            //                 val1 = data?.[calculation.field1]?.toString().replace(/\D/g, "");
            //             } else {
            //                 val1 = 0;
            //             }
            //             console.log({ val1 });
            //             if (calculation.field2 === "**num") {
            //                 val2 = calculation.field2number;
            //             } else if (data?.[calculation.field2]) {
            //                 val2 = data?.[calculation.field2]?.toString().replace(/\D/g, "");
            //             } else {
            //                 val2 = 0;
            //             }
            //             console.log({ val2 });
            //             console.log({ operator: calculation.operator });
            //             const opResult = calculate(val1, val2, calculation.operator);
            //             total += opResult;
            //             console.log({ field: entry.field, total });
            //         }
            //     } else {
            //         return <>Not Found: {entry.field}</>;
            //     }
            //     return (
            //         <>
            //             {/* <PrintPre>{foundFull}</PrintPre> */}
            //             <div>{entry.field}</div>
            //             <div>{(Number.isNaN(total) ? 0 : total).toLocaleString()}</div>
            //         </>
            //     );
            // } catch (e) {
            //     console.error(e);
            //     return (
            //         <>
            //             Error2
            //             <PrintPre>{e}</PrintPre>
            //         </>
            //     );
            // }
            return <></>;
        // return (
        //     <div>
        //         <PrintPre>{entry}</PrintPre>
        //     </div>
        // );
        case "yesNo":
            return (
                <div className={" " + width + "  mb-5"}>
                    <RadioButtonNeoGenControlled
                        onChange={(e: string | number) => {
                            onChange(e.toString());
                        }}
                        value={data?.[entry.field]}
                        options={[
                            { id: "1", name: "Yes" },
                            { id: "0", name: "No" },
                        ]}
                        id={entry.field}
                        label={entry.value}
                        helpDocument={entry.helpDocument}
                        required={entry.required}
                    />
                    <p className="text-sm text-gray-500 tracking-wider">{entry.description}</p>
                </div>
            );
        case "selectBox":
            return (
                <div className={" " + width + "  mb-5 "}>
                    <RadioButtonNeoGenControlled
                        onChange={(e: string | number) => {
                            onChange(e.toString());
                        }}
                        value={data?.[entry.field]}
                        options={entry.selectBoxEntries?.map((selectBoxEntry: any) => {
                            return {
                                id: selectBoxEntry,
                                name: selectBoxEntry,
                            };
                        })}
                        helpDocument={entry.helpDocument}
                        id={entry.field}
                        label={entry.value}
                        required={entry.required}
                    />
                    <p className="text-sm text-gray-500 tracking-wider">{entry.description}</p>
                </div>
            );
        case "fileUpload":
            try {
                width =
                    entry.hideWhenFilled && data[entry?.field]?.multipleFiles?.length > 0
                        ? " hidden "
                        : " w-full lg:w-1/2 min-[1048px]:w-1/3 min-[2000px]:w-1/4 inline-block md:pr-5 ";
                let fileUpload;
                let alertUsers: any[] = [];
                try {
                    fileUpload = JSON.parse(entry.value);
                    alertUsers = fileUpload?.alertUsers;
                } catch (err) {
                    fileUpload = {
                        label: entry.value,
                    };
                    alertUsers = [];
                }

                const replaceTemplatesInMessage = (message: string) => {
                    const firstNameTemplate = "{{firstName}}";
                    const firstName = user.firstName || "";
                    const lastNameTemplate = "{{lastName}}";
                    const lastName = user.lastName || "";
                    const emailTemplate = "{{email}}";
                    const email = user.email || "";
                    const companyNameTemplate = "{{companyName}}";
                    const cName = companyName || "";
                    const companyIdTemplate = "{{companyId}}";
                    const cId = companyId?.toString() || "";

                    return message
                        .replace(firstNameTemplate, firstName)
                        .replace(lastNameTemplate, lastName)
                        .replace(emailTemplate, email)
                        .replace(companyIdTemplate, cId)
                        .replace(companyNameTemplate, cName);
                };

                return (
                    <div className={"text-left md:text-center " + width + " "}>
                        <FileUploadWidget
                            entry={entry}
                            fileDetails={data?.[entry.field]}
                            helpDocument={entry.helpDocument}
                            name={entry.field}
                            label={fileUpload.label || ""}
                            setFileDetails={(fileDetails: FileUploadWithMultiple) => {
                                onChange(fileDetails);
                                // only alert on upload
                                // Get the last file's `dateUploaded`
                                const lastFile = fileDetails.multipleFiles[fileDetails.multipleFiles.length - 1];

                                if (lastFile) {
                                    const dateUploaded = new Date(lastFile.dateUploaded).getTime();
                                    const now = Date.now();

                                    // Time difference in milliseconds
                                    const timeDifference = now - dateUploaded;

                                    // Check if the time difference is less than or equal to 2 minutes (2 * 60 * 1000 ms)
                                    if (timeDifference <= 0.01 * 60 * 1000 && fileDetails.multipleFiles.length > 0) {
                                        alertUsers?.map((au: AlertUser) => {
                                            if (!au) {
                                                return;
                                            }

                                            if (au?.alertUserType === "user") {
                                                if (au?.alertTypes?.inApp) {
                                                    createAlert({
                                                        authToken,
                                                        data: {
                                                            userId: au.userId,
                                                            channel: "app",
                                                            title: au.alertTitle,
                                                            body: replaceTemplatesInMessage(au.alertMessage ?? ""),
                                                            link: replaceTemplatesInMessage(au.alertLink ?? ""),
                                                        },
                                                    });
                                                }

                                                if (au?.alertTypes?.email) {
                                                    createAlert({
                                                        authToken,
                                                        data: {
                                                            userId: au.userId,
                                                            channel: "email",
                                                            title: au.alertTitle,
                                                            body: replaceTemplatesInMessage(au.alertMessage ?? ""),
                                                            link: replaceTemplatesInMessage(au.alertLink ?? ""),
                                                        },
                                                    });
                                                }
                                            } else if (au?.alertUserType === "roleGroup") {
                                                if (au?.alertTypes?.inApp) {
                                                    createAlert({
                                                        authToken,
                                                        data: {
                                                            roleGroupId: au.roleGroupId,
                                                            channel: "app",
                                                            title: au.alertTitle,
                                                            body: replaceTemplatesInMessage(au.alertMessage ?? ""),
                                                            link: replaceTemplatesInMessage(au.alertLink ?? ""),
                                                        },
                                                    });
                                                }

                                                if (au?.alertTypes?.email) {
                                                    createAlert({
                                                        authToken,
                                                        data: {
                                                            roleGroupId: au.roleGroupId,
                                                            channel: "email",
                                                            title: au.alertTitle,
                                                            body: replaceTemplatesInMessage(au.alertMessage ?? ""),
                                                            link: replaceTemplatesInMessage(au.alertLink ?? ""),
                                                        },
                                                    });
                                                }
                                            }
                                        });
                                    }
                                }
                            }}
                            required={entry.required}
                            fileKey={getFileKey ? getFileKey(entry) : undefined}
                        />
                        <p className="text-xs mb-3 text-gray-500 tracking-wider">{entry.description}</p>
                    </div>
                );
            } catch (err) {
                console.log(err);
                console.log(entry);
                return <></>;
            }
        case "PCMPIntro":
            return <PCMPIntro />;
        case "PCMPIntro2":
            return <PCMPIntro2 />;
        case "PCMPTypeSelector":
            return (
                <div className="w-full mx-auto border rounded-xl overflow-hidden mb-10 mt-5">
                    <div className="">
                        <table className="w-full ">
                            <tbody>
                                <tr
                                    className="hover:bg-gray-200 cursor-pointer border-b hover:shadow-inner border-gray-300 "
                                    onClick={() => {
                                        // open(); // TODO: Open the PCMP page
                                        onChange("finch");
                                        setIsLoading(true);
                                        open();
                                    }}
                                    // style={{ borderBottom: "1px solid #ccc" }}
                                >
                                    <td className="w-[90%] p-4 h-5">
                                        <div>
                                            <div
                                                style={{
                                                    fontSize: 20,
                                                    fontWeight: 500,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: 5,
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <IconPlugConnected />
                                                </div>
                                                <div>Connect Payroll</div>
                                                <div
                                                    className="bg-green-500"
                                                    style={{
                                                        fontSize: 13,
                                                        borderRadius: 14,
                                                        color: "white",
                                                        padding: "0px 8px 0px 8px",
                                                    }}
                                                >
                                                    Recommended
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    color: "gray",
                                                    fontSize: 15,
                                                }}
                                            >
                                                <div>
                                                    Securely connect directly to your payroll provider - no effort from
                                                    your team.
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        gap: 4,
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    <div>
                                                        <IconClock size={15} />
                                                    </div>
                                                    <div>takes just a few seconds</div>
                                                </div>
                                                {/* <div>No effort required from your team.</div> */}
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        {isLoading ? (
                                            <div>
                                                <Loader3 noMargin noHeight />
                                            </div>
                                        ) : (
                                            <IconArrowRight size={28} />
                                        )}
                                    </td>
                                </tr>
                                <tr
                                    className="hover:bg-gray-200 cursor-pointer hover:shadow-inner border-b border-gray-300"
                                    onClick={() => {
                                        onChange("upload");
                                        console.info({ processflowId });
                                        // setTimeout(() => {
                                        // onClick("nextStep");
                                        // }, 2000);
                                        window.localStorage.setItem(
                                            "position-50",
                                            JSON.stringify({
                                                [processflowId ?? 1]: { processflowStageId: 240, processflowId: 609 },
                                            }),
                                        );
                                        setTimeout(() => {
                                            window.location.href = "/processflow-wizard/28";
                                        }, 2000);
                                    }}
                                >
                                    <td className="w-[90%] p-4 h-5">
                                        <div>
                                            <div
                                                style={{
                                                    fontSize: 20,
                                                    fontWeight: 500,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: 5,
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <IconUpload />
                                                </div>
                                                <div>Upload Payroll</div>
                                            </div>
                                            <div
                                                style={{
                                                    color: "gray",
                                                    fontSize: 15,
                                                }}
                                            >
                                                <div>Upload your payroll files from your provider</div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        gap: 4,
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    <div>
                                                        <IconClock size={15} />
                                                    </div>
                                                    <div>
                                                        Our team will handle the Census processing and completion, which
                                                        may take 5-7 days.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        <IconArrowRight size={28} />
                                    </td>
                                </tr>
                                <tr
                                    className="hover:bg-gray-200 cursor-pointer hover:shadow-inner"
                                    onClick={() => {
                                        onChange("manual");
                                        window.localStorage.setItem(
                                            "position-50",
                                            JSON.stringify({
                                                [processflowId ?? 1]: { processflowStageId: 240, processflowId: 608 },
                                            }),
                                        );
                                        setTimeout(() => {
                                            window.location.href = "/processflow-wizard/28";
                                        }, 2000);
                                    }}
                                >
                                    <td className="w-[90%] p-4 h-5">
                                        <div>
                                            <div
                                                style={{
                                                    fontSize: 20,
                                                    fontWeight: 500,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: 5,
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <IconEdit />
                                                </div>
                                                <div>Manually Create Payroll</div>
                                            </div>
                                            <div
                                                style={{
                                                    color: "gray",
                                                    fontSize: 15,
                                                }}
                                            >
                                                <div>Manually create your payroll online</div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        gap: 4,
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    <div>
                                                        <IconClock size={15} />
                                                    </div>
                                                    <div>
                                                        Your company will manage the entire Census process. This is the
                                                        most time-intensive step.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        <IconArrowRight size={28} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        case "button": {
            const button = JSON.parse(entry.value);
            const disableIfContractUnsignedButton =
                allEntries.some((item: any) => item.type === "contractPicker") &&
                !Object.values(data).some(
                    (value) => typeof value === "string" && value?.includes('<div id="contract"'),
                ); // if allEntries contains type: "contractPicker" then data must contain <div id="contract"

            const emptyAndNotHiddenFields =
                pfIsOptional === 0
                    ? (allEntries || []).map((item: any) => {
                          const id = item?.id;
                          if (
                              [undefined, null, "", " "].includes(data[id]) &&
                              item.required === true &&
                              [
                                  "nextStep",
                                  "nextStepAndSendSlackMessage",
                                  "nextStage",
                                  "createAccount",
                                  "createAccountAndLogin",
                                  "createAccountLoginAndRedirect",
                                  "createAccountSendConfirmation",
                                  "login",
                              ].includes(button.action) &&
                              !progressData?.entriesByField[id]?.isHidden
                          ) {
                              return { id, empty: true };
                          } else {
                              return { id, empty: false };
                          }
                      })
                    : [];

            const disableIfPfIsNotOptional = emptyAndNotHiddenFields.some((v: any) => v.empty === true);
            const disableButton = !isAdmin && (disableIfContractUnsignedButton || disableIfPfIsNotOptional);

            return (
                <div className={" inline-block mr-5 mb-5" + " "}>
                    <ButtonNeoGen
                        icon={
                            (button.action === "nextStep" && "fas fa-chevron-right") ||
                            (button.action === "nextStepAndAlert" && "fas fa-chevron-right") ||
                            (button.action === "previousStep" && "fas fa-chevron-left") ||
                            (button.action === "nextStage" && "fas fa-chevron-right") ||
                            (button.action === "previousStage" && "fas fa-chevron-left") ||
                            (button.action === "submit" && "fal fa-check") ||
                            (button.action === "save" && "fal fa-save") ||
                            (button.action === "cancel" && "fal fa-times") ||
                            (button.action === "reset" && "fal fa-undo") ||
                            (button.action === "custom" && button.icon)
                        }
                        type={button.colorType !== "custom" ? button.colorType : undefined}
                        disabled={disableButton}
                        size={button.size}
                        onClick={() => {
                            // TODO: Change this to an atom
                            localStorage.setItem("button", JSON.stringify(button));
                            onClick(button.action);
                        }}
                        iconAlign={["previousStep", "previousStage"].includes(button.action) ? "left" : "right"}
                        text={button.label}
                        tooltipText={
                            disableIfPfIsNotOptional
                                ? `Fields: ${emptyAndNotHiddenFields
                                      .filter((v: any) => v.empty === true)
                                      .map((v: any) => v.id)
                                      .join(", ")} needs to be filled in.`
                                : undefined
                        }
                    />
                </div>
            );
        }
        case "contractPicker": {
            return <ContractPicker addHtml={true} />;
        }
        case "contractPickerWithoutHTML": {
            return <ContractPicker addHtml={false} />;
        }
        case "wysiwyg": {
            // An HTML string

            // In the HTML sections you can click a button to insert a field's value
            // Basically the way it works is that you have a button that inserts the '
            // field name inside double curly braces. When we are displaying the HTML
            // we replace the double curly braces with the actual value of the field.
            // This could be the value the customer entered or it could be a calculation
            // of other fields.
            //
            // Replace {{fieldName}} with the value of the field with the same name
            // across all the questions in the current progress flow group.
            // console.info("Drawing: ",entry)

            // eslint-disable-next-line no-case-declarations
            const html = entry.value
                .replace(/\[\[(.*?)\]\]/g, (_match: string, fieldFound: string) => {
                    // Replace [[filename]] with a link to the video
                    return `
                    <div className={" " + width + " mb-5"}>
                    <video  controls>
                        <source
                            src="${FILE_API_URL}/files/${fieldFound}" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                </div>
                        `;
                })
                .replace(/{{(.*?)}}/g, (_match: string, fieldFound: string) => {
                    // Look for the field value in the current step. If it's not there then
                    // either the customer hasn't entered it yet or it's not in the current
                    // step. So we look for it in the previous steps.
                    // The other option is that the field is a calculation, in which case
                    // we need to look for the field values to calculate it.
                    // The val variable will hold the value of the field and its details.
                    const val: Entry = data?.[fieldFound];

                    // If the field type is a state, return the long form state name.
                    if (val?.type === "state") {
                        return states.find((s: any) => s.id === val.fieldValue)?.name ?? "";
                    }

                    // If the field type is a checkbox, return it as Yes or No
                    if (val?.type === "checkbox") {
                        return val.fieldValue ? "Yes" : "No";
                    }

                    // If we didn't find the field value it might just have not been filled out yet.
                    // We should know from the field type if it's a calculation or not.

                    // First look through all entries and see if we can find the field name.
                    const field: Entry | undefined = (allEntries ?? []).find((e: any) => {
                        return e.id === fieldFound;
                    });

                    if (!val && field?.type === "valueCalculator") {
                        try {
                            let total = 0;
                            const foundFull = allEntries?.find((p: any) => p.id === fieldFound);
                            const found = foundFull.value;

                            if (found) {
                                for (const calculation of JSON.parse(found)) {
                                    let val1 = 0;
                                    let val2 = 0;
                                    if (calculation.field1 === "**num") {
                                        val1 = calculation.field1number;
                                    } else {
                                        val1 = data?.[calculation.field1]?.toString().replace(/\D/g, "");
                                    }
                                    if (calculation.field2 === "**num") {
                                        val2 = calculation.field2number;
                                    } else {
                                        val2 = data?.[calculation.field2]?.toString().replace(/\D/g, "");
                                    }
                                    const opResult = calculate(val1, val2, calculation.operator);
                                    total += opResult;
                                }
                            }
                            return (Number.isNaN(total) ? 0 : total).toLocaleString();
                        } catch (e) {
                            return "(<></>)";
                        }
                    }
                    return val?.fieldValue ?? "";
                })
                .replace(/\n/g, "<br />");
            return (
                <div key={entry.id} className={" " + (entry.widthOf300Cols ?? "w-full") + " mb-5"}>
                    <div
                        className="ql-snow ql-read-only !font-normal !text-sm !tracking-wider !leading-normal !text-gray-500 !not-italic !no-underline "
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                    {entry.helpDocument && (
                        <>
                            <PopoverComponent document={entry.helpDocument} />
                        </>
                    )}
                </div>
            );
        }
        case "datePicker": {
            return (
                <div className={" " + width + " "}>
                    <CalendarWidget
                        onChange={(e: Date) => {
                            onChange(e);
                        }}
                        value={data?.[entry.field] && new Date(data[entry.field])}
                        label={entry.value}
                        description={entry.description || ""}
                        isRequired={entry.required}
                    />
                </div>
            );
        }
        case "einNumber": {
            return (
                <div className={" " + width + " self-start"} title={entry.field}>
                    <EinNumberInput
                        setValue={(e: string) => {
                            onChange(e);
                        }}
                        required={entry.required}
                        disabled={entry.disabled}
                        value={data?.[entry.field] ?? entry.defaultVal ?? ""}
                        name={entry.field}
                        label={entry.value}
                        errors={entryErrors}
                    />
                </div>
            );
        }
        case "zipCode": {
            return (
                <>
                    <div className={" " + width + " self-start"} title={entry.field}>
                        <ZipCodeInput
                            setValue={(e: string) => {
                                onChange(e);
                            }}
                            required={entry.required}
                            disabled={entry.disabled}
                            value={data?.[entry.field] ?? entry.defaultVal ?? ""}
                            name={entry.field}
                            label={entry.value}
                            errors={entryErrors}
                        />
                    </div>
                </>
            );
        }
        case "newSignature": {
            // console.log({ id: entry.id, data: data[entry.id] });
            let url = "";
            let document = "";
            let type = "";
            if (entry.id === "BAA Signature") {
                console.log("BAA Signature");
                document = "Business Associate Agreement";
                type = "BAA";
                url = `https://api.clearerc.com/contracts/create?fieldName=BAA%20Signature&contractType=BAA&processflowId=${processflowId}`;
            } else if (entry.id === "MSA Signature") {
                document = "Management Service Agreement";
                console.log("MSA Signature");
                type = "MSA";
                url = `https://api.clearerc.com/contracts/create?fieldName=MSA%20Signature&contractType=MSA&processflowId=${processflowId}`;
            } else if (entry.id === "ACH Signature") {
                console.log("ACH Signature");
                document = "ACH Authorization";
                type = "ACH";
                url = `https://api.clearerc.com/contracts/create?fieldName=ACH%20Signature&contractType=ACH&processflowId=${processflowId}`;
            } else {
                throw new Error("Invalid signature type");
            }
            if (data[entry.id]) {
                // Signed
                console.log({ url });
                return (
                    <div className="mb-5">
                        <div className="flex mb-5 text-center gap-5">
                            {/* <ButtonNeoGen
                                icon="fal fa-download"
                                onClick={() => {
                                    window.open(url, "_blank");
                                }}
                            >
                                Download {document}
                            </ButtonNeoGen> */}
                            <ButtonNeoGen
                                icon="fal fa-envelope"
                                onClick={() => {
                                    // window.open(url, "_blank");
                                    setContractType(type);
                                    setShowEmailContract(true);
                                }}
                            >
                                Email {document}
                            </ButtonNeoGen>
                        </div>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                            <div style={{ height: "750px" }}>
                                <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} />
                            </div>
                        </Worker>
                        {/* <iframe
                            src={"https://docs.google.com/gview?url=" + encodeURIComponent(url) + "&embedded=true"}
                            style={{ width: "100%", height: "500px" }}
                            frameBorder="0"
                        ></iframe> */}
                        {/* <NewSignature onChange={onChange} currentValue={data[entry.id]} isAdmin={isAdmin} /> */}
                        <ModalDialog
                            show={showEmailContract}
                            close={() => {
                                setShowEmailContract(false);
                            }}
                            title={`Email ${document}`}
                            okAction={async () => {
                                setEmailLoading(true);

                                const url = window.location.hostname;
                                const body = JSON.stringify({
                                    site: url,
                                    to: email,
                                    userId: user.id,
                                    type,
                                    processFlowId: processflowId,
                                });
                                console.log(body);
                                const rawResponse = await fetch(API_URL + "/contracts/email", {
                                    method: "POST",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                    },
                                    body: body,
                                });
                                // const content = await rawResponse.json();

                                console.log(rawResponse);
                                SwalNeogenFire({
                                    title: "Email Sent",
                                    text: "The " + document + " has been sent to " + email,
                                    icon: "success",
                                    confirmButtonText: "Ok",
                                    timer: 5000,
                                });
                                setShowEmailContract(false);
                                setEmailLoading(false);
                            }}
                        >
                            {emailLoading ? (
                                <>
                                    <Loader3 />
                                    <br />
                                    <div className="mb-5 text-center">Please Wait, Sending Email...</div>
                                </>
                            ) : (
                                <InputControlled
                                    label={"Email to send " + document + " to:"}
                                    value={email}
                                    setValue={(v) => setEmail(v)}
                                />
                            )}
                        </ModalDialog>
                    </div>
                );
            }
            // Unsigned
            return (
                <>
                    {/* <iframe
                        src={"https://docs.google.com/gview?url=" + encodeURIComponent(url) + "&embedded=true"}
                        style={{ width: "100%", height: "500px" }}
                        frameBorder="0"
                    ></iframe> */}

                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                        <div style={{ height: "750px" }}>
                            <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} />
                        </div>
                    </Worker>
                    {/* <PrintPre>{entry}</PrintPre> */}
                    {/* <PrintPre>{"Current: " + data[entry.id]}</PrintPre> */}
                    <NewSignature onChange={onChange} currentValue={data[entry.id]} isAdmin={isAdmin} />
                </>
            );
        }
        case "embeddedPDF": {
            const url = entry.value;
            return (
                <>
                    {/* <iframe
                        src={"https://docs.google.com/gview?url=" + encodeURIComponent(url) + "&embedded=true"}
                        style={{ width: "100%", height: "500px" }}
                        frameBorder="0"
                    ></iframe> */}
                    <div className="flex mb-5 text-center gap-5">
                        {/* <ButtonNeoGen
                                icon="fal fa-download"
                                onClick={() => {
                                    window.open(url, "_blank");
                                }}
                            >
                                Download {document}
                            </ButtonNeoGen> */}
                        <ButtonNeoGen
                            icon="fal fa-envelope"
                            onClick={() => {
                                // window.open(url, "_blank");
                                setShowEmailContract(true);
                            }}
                        >
                            Email Document
                        </ButtonNeoGen>
                    </div>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                        <div style={{ height: "750px" }}>
                            <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} />
                        </div>
                    </Worker>
                    {/* <PrintPre>{entry}</PrintPre> */}
                    {/* <PrintPre>{"Current: " + data[entry.id]}</PrintPre> */}
                    <ModalDialog
                        show={showEmailContract}
                        close={() => {
                            setShowEmailContract(false);
                        }}
                        title={`Email Document`}
                        okAction={async () => {
                            setEmailLoading(true);

                            const site = window.location.hostname;
                            const body = JSON.stringify({
                                site: site,
                                to: email,
                                userId: user.id,
                                type: url,
                                processFlowId: processflowId,
                            });
                            console.log(body);
                            const rawResponse = await fetch(API_URL + "/contracts/email", {
                                method: "POST",
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                },
                                body: body,
                            });
                            // const content = await rawResponse.json();

                            console.log(rawResponse);
                            SwalNeogenFire({
                                title: "Email Sent",
                                text: "The Document has been sent to " + email,
                                icon: "success",
                                confirmButtonText: "Ok",
                                timer: 5000,
                            });
                            setShowEmailContract(false);
                            setEmailLoading(false);
                        }}
                    >
                        {emailLoading ? (
                            <>
                                <Loader3 />
                                <br />
                                <div className="mb-5 text-center">Please Wait, Sending Email...</div>
                            </>
                        ) : (
                            <InputControlled
                                label={"Email to send pdf to:"}
                                value={email}
                                setValue={(v) => setEmail(v)}
                            />
                        )}
                    </ModalDialog>
                </>
            );
        }
        case "companyGroupManager": {
            return (
                <>
                    {internalCompany?.group === null ? (
                        <>
                            {!showCreateCompanyGroup && (
                                <ButtonNeoGen onClick={() => setShowCreateCompanyGroup(true)}>
                                    Create Company Group
                                </ButtonNeoGen>
                            )}
                        </>
                    ) : (
                        <div className="mb-5">
                            <div className="flex gap-3 mb-5">
                                <ButtonNeoGen onClick={() => setShowAddnewCompany(true)}>Add New Company</ButtonNeoGen>
                                <ButtonNeoGen>Add Existing Company</ButtonNeoGen>
                            </div>
                            {/* <PrintPre>{internalCompany}</PrintPre> */}
                            <TableNeogen
                                entries={
                                    companyQuery.data
                                        ?.filter((c: any) => c.group === internalCompany?.group)
                                        .map((c: any) => ({ id: c.id, Name: c.name })) ?? []
                                }
                                actions={[
                                    // {
                                    //     label: "Edit",
                                    //     onClick: (id: number) => {
                                    //         console.log("Edit", id);
                                    //     },
                                    // },
                                    {
                                        label: "Delete",
                                        type: "danger",
                                        icon: "fal fa-trash",
                                        onClick: (id: number) => {
                                            if (id === companyId) {
                                                SwalNeogenFire({
                                                    title: "Error",
                                                    text: "You cannot delete the company you are currently working on",
                                                    icon: "error",
                                                    confirmButtonText: "Ok",
                                                    timer: 5000,
                                                });
                                                return;
                                            } else {
                                                SwalNeogenFire({
                                                    title: "Confirm Deletion",
                                                    text: "Are you sure you want to remove this company from the group?  The company will still exist",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonText: "Yes, delete it!",
                                                    cancelButtonText: "Cancel",
                                                }).then((result: any) => {
                                                    if (result.isConfirmed) {
                                                        // Perform the deletion logic here
                                                        alert("Deleting company with id:" + id);
                                                        // Remove the company from the group
                                                        companyService.update(id, { group: null });
                                                        queryCache.invalidateQueries(["companies"]);
                                                        queryCache.invalidateQueries(["companyGroups"]);
                                                        // ...
                                                    }
                                                });
                                            }
                                        },
                                    },
                                ]}
                            />
                        </div>
                    )}
                    {showAddNewCompany && (
                        <>
                            <InputControlled
                                label={"Company Name"}
                                value={companyName}
                                setValue={(v) => setCompanyName(v)}
                            />
                            <ButtonNeoGen
                                onClick={async () => {
                                    console.log("createCompany", companyName);
                                    const response = await companyService.create({
                                        ownedById: internalCompany?.ownedById,

                                        name: companyName,
                                        group: internalCompany?.group,
                                    });
                                    const userCompanyEntries = await userCompanyService.getFilteredWhere({
                                        and: [{ companyId: internalCompany?.id }, { roleGroupId: 7 }],
                                    });
                                    if (userCompanyEntries) {
                                        for (const userCompanyEntry of userCompanyEntries.data) {
                                            if (userCompanyEntry.id) {
                                                delete userCompanyEntry.id;
                                            }
                                            if (response) {
                                                await userCompanyService.create({
                                                    ...userCompanyEntry,
                                                    companyId: response.data?.id,
                                                    roleGroupId: 7,
                                                });
                                            }
                                        }
                                    }
                                    queryCache.invalidateQueries(["companies"]);
                                    setShowAddnewCompany(false);
                                }}
                            >
                                Company
                            </ButtonNeoGen>
                        </>
                    )}

                    {showCreateCompanyGroup && (
                        <>
                            <InputControlled
                                label={"Company Group Name"}
                                value={companyGroupName}
                                setValue={(v) => setCompanyGroupName(v)}
                            />
                            <ButtonNeoGen
                                onClick={async () => {
                                    console.log("createCompanyGroup", companyGroupName);
                                    const response = await companyGroupService.create({ name: companyGroupName });
                                    // Update the current company to have a group id of the new group
                                    if (response) {
                                        await companyService.update(companyId, { group: response.data.id });
                                    }
                                    queryCache.invalidateQueries(["companies"]);
                                    setShowCreateCompanyGroup(false);
                                }}
                            >
                                Create Group
                            </ButtonNeoGen>
                        </>
                    )}
                </>
            );
            break;
        }
        case "invoices": {
            return (
                <div className={" " + width + " "}>
                    <Invoices companyId={companyId} data={data} onChange={onChange} />
                </div>
            );
        }
        case "daterangePicker": {
            const value = data?.[entry.field]
                ? { startDate: new Date(data[entry.field].startDate), endDate: new Date(data[entry.field].endDate) }
                : { startDate: new Date(), endDate: new Date() };
            return (
                <div className={" " + width + " self-start"}>
                    <div className="flex">
                        <span className="text-sm text-gray-500 tracking-wider">{entry.value}</span>
                        {entry.required && (
                            <span className="inline-block text-lg leading-none text-red-500 font-extralight pl-[1px] mr-[1px] mt-[2px]">
                                *
                            </span>
                        )}
                    </div>

                    <Datepicker
                        onChange={(e: any) => {
                            onChange(e);
                        }}
                        value={value}
                        // label={entry.value}
                        // description={entry.description || ""}
                        displayFormat={"MM/DD/YYYY"}
                    />
                </div>
            );
        }
        default:
            return <div></div>;
    }
};

export const Entries = ({
    changeCompanyAndProcessflow,
    entries,
    entryErrors,
    onChange,
    onClick,
    data,
    noMargin,
    drawWithFlex,
    getFileKey,
    companyId,
    pfIsOptional,
    progressData,
    processflowId,
}: {
    changeCompanyAndProcessflow: any;
    entries: Entry[];
    entryErrors?: Record<string, string[]>;
    onChange: ({ entry, value }: { entry: Entry; value: any }) => void;
    onClick: OnClick;
    data: Record<string, any>;
    noMargin?: boolean;
    drawWithFlex?: boolean;
    getFileKey?: (entry: Entry) => string;
    companyId?: number;
    pfIsOptional?: number;
    progressData?: ProcessFlowProgressData;
    processflowId?: number;
}) => {
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";

    return (
        <div className={drawWithFlex ? "grid-cols-2 w-full overflow-hidden grid " : ""}>
            {entries.map((entry) => {
                return (
                    <span key={entry.id} className={drawWithFlex ? " " : ""}>
                        <RenderEntry
                            processflowId={processflowId}
                            entry={entry}
                            entryErrors={entryErrors?.[entry.field]}
                            data={data}
                            onChange={(value) => onChange({ entry, value })}
                            onChangeWithEntry={(entry, value) => onChange({ entry, value })}
                            onClick={onClick}
                            allEntries={entries}
                            noMargin={noMargin}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            getFileKey={getFileKey}
                            companyId={companyId}
                            pfIsOptional={pfIsOptional}
                            progressData={progressData}
                            changeCompanyAndProcessflow={changeCompanyAndProcessflow}
                        />
                    </span>
                );
            })}
        </div>
    );
};
