import { Dispatch, SetStateAction } from "react";
import ModalDialog from "../../../../../layout/modal-dialog";
import { TeamMember } from "../domain/deal";
import { TBody, THead, Table, Td, Tr } from "../../../../../layout/table";
import ButtonNeoGen from "../../../../../layout/button-neogen";
import { Currency } from "../../../../../billing/components";

export const TeamModal = ({
    companyId,
    companyName,
    setShowTeamModalForCompanyId,
    team,
}: {
    companyId: number;
    companyName: string | undefined;
    setShowTeamModalForCompanyId: Dispatch<SetStateAction<number | undefined>>;
    team: TeamMember[] | undefined;
}) => {
    return (
        <ModalDialog
            title={`Team for ${companyName}`}
            show={true}
            showCancel={false}
            showOk={false}
            close={() => setShowTeamModalForCompanyId(undefined)}
        >
            <div className="flex justify-between mb-3">
                <div>
                    <p className="text-sm">Total commission for this deal: $30</p>
                    <p className="text-sm">My portion of profit share: $25</p>
                    <p className="text-sm">My team&apos;s portion of profit share: $5</p>
                </div>
                <div className="flex gap-2">
                    <ButtonNeoGen>Add Team Member</ButtonNeoGen>
                    <ButtonNeoGen>Edit Profit Shares</ButtonNeoGen>
                </div>
            </div>
            <Table>
                <THead>
                    <Tr>
                        <Td>Name</Td>
                        <Td>Role</Td>
                        <Td>Profit Share</Td>
                    </Tr>
                </THead>
                <TBody>
                    {team?.map((member) => (
                        <Tr key={member.id}>
                            <Td>{member.name}</Td>
                            <Td>{member.role}</Td>
                            <Td>
                                <Currency amount={member.profitShare} />
                            </Td>
                        </Tr>
                    ))}
                </TBody>
            </Table>
        </ModalDialog>
    );
};
