import { IconUsers, IconTax, IconLock } from "@tabler/icons-react";
import React from "react";
import ButtonNeoGen from "../../layout/button-neogen";
import LogoBox from "../../layout/LogoBox";

function PCMPIntro2() {
    return (
        <div>
            <h2 style={{ textAlign: "center", paddingBottom: 20 }}>
                <LogoBox whiteBackground={true} />
            </h2>
            <div style={{ paddingBottom: 10 }}>
                <h1 style={{ textAlign: "center", fontWeight: 600, fontSize: 42, lineHeight: 1 }}>
                    Get Quoted In Seconds
                </h1>
            </div>
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 20,
                    paddingBottom: 20,
                }}
            >
                <div style={{ width: "100%", textAlign: "center", color: "gray", fontSize: 16 }}>
                    SureAdvisors makes it quick and easy to see how much your business and employees could save with a
                    PCMP Program.
                </div>
            </div>
        </div>
    );
}

export default PCMPIntro2;
