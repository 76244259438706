import React from "react";

function ProductSelection() {
    return (
        <div className="bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl  dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner min-h-32 grid grid-cols-1 content-center shadow mt-5 p-5">
            <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                <li className="me-2">
                    <a
                        href="#"
                        className="inline-block px-4 py-3 text-white bg-blue-600 rounded-lg active"
                        aria-current="page"
                    >
                        Wellness Program
                    </a>
                </li>
                <li className="me-2">
                    <a
                        href="#"
                        className="inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
                    >
                        R&D Credits
                    </a>
                </li>
                <li className="me-2">
                    <a
                        href="#"
                        className="inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
                    >
                        ERC Repair
                    </a>
                </li>
                <li className="me-2">
                    <a
                        href="#"
                        className="inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
                    >
                        Energy Credits
                    </a>
                </li>
                <li className="me-2">
                    <a
                        href="#"
                        className="inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
                    >
                        WOTC Credits
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default ProductSelection;
