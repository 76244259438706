import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import { useCompanies } from "../../../companies/hooks/use-companies";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
    ProcessflowProgressData,
    getProcessflowProgresses,
} from "../../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import { useUserCompanies } from "../../../companies/hooks/use-user-companies";
import MyCustomers from "../my-customers";
import { RoleGroup } from "../../../role-groups/domain/role-group";
import { getDataFromProgressData } from "../../../jason-proof-of-concept/wizard/wizard-container";
import { getW2Employees } from "../../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { useProcessflowProgresses } from "../../../companies/hooks/use-processflow-progresses";

export const TaxAttorneyContent = ({
    darkMode,
    currentUser,
    users,
    activeRole,
}: {
    darkMode: boolean;
    currentUser: ClearERCUser;
    users: ClearERCUser[];
    activeRole: undefined | null | RoleGroup;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-token";
    const userCompaniesQuery = useUserCompanies({
        authToken,
        filters: { where: { ercUserId: currentUser.id, roleGroupId: 15 } },
    });
    const userCompanies = useMemo(() => userCompaniesQuery.data || [], [userCompaniesQuery.data]);

    const companiesQuery = useCompanies({
        authToken,
        filters: { where: { id: { inq: userCompanies.map((uc) => uc.companyId) } } },
    });
    const companies = useMemo(() => companiesQuery.data || [], [companiesQuery.data]);

    const progressesQuery = useProcessflowProgresses({ authToken, filters: { group: 7 } });
    const processflowProgresses = useMemo(() => progressesQuery.data || [], [progressesQuery.data]);

    const rows = useMemo(
        () =>
            companies.map((company) => {
                const processflowProgress = processflowProgresses.find(
                    (p) => company.ownedById && company.ownedById === p.userId,
                );
                const status = processflowProgress?.status ?? "";
                const progressPercentage = Math.round((processflowProgress?.data?.percentageComplete || 0) * 100);
                const data = getDataFromProgressData(processflowProgress?.data || ({} as any));
                const w2Employees = getW2Employees({ data });
                const row = {
                    processflowProgress,
                    progressPercentage,
                    company,
                    status,
                    w2Employees,
                };

                return row;
            }),
        [companies, processflowProgresses],
    );

    return (rows || []).length > 0 ? (
        <div className="mt-5">
            <MyCustomers users={users || []} activeRole={activeRole} rows={rows} />
        </div>
    ) : (
        <p className="mt-3">No clients assigned to you</p>
    );
};
