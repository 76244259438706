import DashboardPageHeader from "./dashboard-page-header";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router";
import authService from "../../services/auth.service";
import userAtom from "../../atoms/userAtom";
import { SuperUserContent } from "./dashboard-content/superUserContent";
import { RoleGroup } from "../../role-groups/domain/role-group";
import { AffiliateContent } from "./dashboard-content/affiliateContent";
import { CPAContent } from "./dashboard-content/cpaContent";
import { AccountManagerContent } from "./dashboard-content/accountManagerContent";
import { DefaultContent } from "./dashboard-content/defaultContent";
import { ClientContent } from "./dashboard-content/clientContent";
import { useQuery } from "@tanstack/react-query";
import usersService from "../../services/users.service";
import { TaxAttorneyContent } from "./dashboard-content/taxAttorneyContent";
import { AgentContent } from "./dashboard-content/agentContent";
import PrintPre from "../../layout/print-pre";
import { ReferrerContent } from "./dashboard-content/referrerContent";
import Paymaster from "./paymaster";
import { PaymasterContent } from "./dashboard-content/paymasterContent";
import { SureAdvisorAffiliateContent } from "./dashboard-content/sureAdvisorAffiliateContent";
import TabbedTopSection from "./dashboard-content/tabbed-top-section";
import ProductSelection from "./dashboard-content/product-selection";

type DashboardProps = {
    setSideBarOpen: (isOpen: boolean) => void;
    sideBarOpen: boolean;
    darkMode: boolean;
};

export default function PCMPDashboard(props: DashboardProps): JSX.Element {
    const [user, setUser] = useState(authService.getCurrentUser());
    const [currentUser] = useRecoilState(userAtom);
    const [activeRole, setActiveRole] = useState<undefined | null | RoleGroup>(undefined);
    const navigate = useNavigate();

    // Necessary for user impersonation
    const userRoles = user?.user?.roleGroups || user?.roleGroups;

    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data;
        }
    });

    useEffect(() => {
        const currentActiveRole = localStorage.getItem("dashboard-active-role");
        if (currentActiveRole) {
            const role = userRoles?.find((r: RoleGroup) => r.name === currentActiveRole);
            setActiveRole(role);
        } else {
            if (!activeRole && userRoles && userRoles.length > 0) {
                console.error(userRoles[0]);
                setActiveRole(userRoles[0]);
                localStorage.setItem("dashboard-active-role", userRoles[0].name);
            }
        }
    }, [activeRole, userRoles]);

    const roleNameToContentMapping = {
        "Super User": (
            <SuperUserContent
                darkMode={props.darkMode}
                users={usersQuery.data || []}
                usersQueryIsSuccess={usersQuery.isSuccess}
                usersQueryLoading={usersQuery.isLoading}
                activeRole={activeRole}
            />
        ),
        Accountant: (
            <CPAContent
                darkMode={props.darkMode}
                currentUser={currentUser}
                users={usersQuery.data || []}
                activeRole={activeRole}
            />
        ),
        Affiliate: (
            <AffiliateContent
                darkMode={props.darkMode}
                users={usersQuery.data || []}
                usersQueryIsSuccess={usersQuery.isSuccess}
                usersQueryLoading={usersQuery.isLoading}
                activeRole={activeRole}
            />
        ),
        "Affiliate in Training": (
            <AffiliateContent
                darkMode={props.darkMode}
                users={usersQuery.data || []}
                usersQueryIsSuccess={usersQuery.isSuccess}
                usersQueryLoading={usersQuery.isLoading}
                activeRole={activeRole}
            />
        ),
        Agent: (
            <AgentContent
                user={user}
                darkMode={props.darkMode}
                users={usersQuery.data || []}
                usersQueryIsSuccess={usersQuery.isSuccess}
                usersQueryLoading={usersQuery.isLoading}
                activeRole={activeRole}
            />
        ),
        "Clear ERC Account Manager": (
            <AccountManagerContent
                darkMode={props.darkMode}
                currentUser={currentUser}
                users={usersQuery.data || []}
                activeRole={activeRole}
            />
        ),
        "Doc collector": (
            <AccountManagerContent
                darkMode={props.darkMode}
                currentUser={currentUser}
                users={usersQuery.data || []}
                activeRole={activeRole}
            />
        ),
        "ERC Client": <ClientContent darkMode={props.darkMode} />,
        "ERC repair Client": <ClientContent darkMode={props.darkMode} />,
        "Tax Payer Advocate Client": <ClientContent darkMode={props.darkMode} />,
        "R&D Client": <ClientContent darkMode={props.darkMode} />,
        "R&D Client ": <ClientContent darkMode={props.darkMode} />,
        "R & D Client": <ClientContent darkMode={props.darkMode} />,
        "Substantiation Client ": <ClientContent darkMode={props.darkMode} />,
        "Tax Advocate Accountant ": <ClientContent darkMode={props.darkMode} />,
        "Track & Trace": <ClientContent darkMode={props.darkMode} />,
        "Transcript Client  ": <ClientContent darkMode={props.darkMode} />,
        WOTC: <ClientContent darkMode={props.darkMode} />,
        "Wellness Programs": <ClientContent darkMode={props.darkMode} />,
        "Cost Segregation": <ClientContent darkMode={props.darkMode} />,
        "Internal CPA": (
            <CPAContent
                darkMode={props.darkMode}
                currentUser={currentUser}
                users={usersQuery.data || []}
                activeRole={activeRole}
            />
        ),
        "Internal doc collector": (
            <AccountManagerContent
                darkMode={props.darkMode}
                currentUser={currentUser}
                users={usersQuery.data || []}
                activeRole={activeRole}
            />
        ),
        "Tax attorney": (
            <TaxAttorneyContent
                darkMode={props.darkMode}
                currentUser={currentUser}
                users={usersQuery.data || []}
                activeRole={activeRole}
            />
        ),
        Referrer: (
            <ReferrerContent
                darkMode={props.darkMode}
                activeRole={activeRole}
                usersQuery={usersQuery}
                currentUser={currentUser}
            />
        ),
        Paymaster: (
            <PaymasterContent
                darkMode={props.darkMode}
                activeRole={activeRole}
                usersQuery={usersQuery}
                currentUser={currentUser}
            />
        ),
        "SureAdvisor Affiliate": (
            <SureAdvisorAffiliateContent
                darkMode={props.darkMode}
                activeRole={activeRole}
                usersQuery={usersQuery}
                currentUser={currentUser}
            />
        ),
    };

    useEffect(() => {
        if (user) {
            if ((user?.user?.roleGroups || []).find((r: any) => r.id === 12) && user?.user?.roleGroups?.length === 1) {
                if (window.location.hostname === "login.ercrepair.com" || window.location.hostname === "localhost") {
                    navigate("/processflow-wizard/29");
                } else {
                    navigate("/processflow-wizard/11");
                }
            } else {
                const localRole = localStorage.getItem("dashboard-active-role");
                if (localRole) {
                    const role = userRoles?.find((r: RoleGroup) => r.name === localRole);
                    setActiveRole(role);
                } else {
                    const newRole = user?.user?.roleGroups?.[0] || userRoles?.[0];
                    if (newRole) {
                        setActiveRole(newRole);
                        localStorage.setItem("dashboard-active-role", newRole.name);
                    }
                }
            }
        }
    }, [user, navigate, userRoles]);

    return (
        <>
            <div className="flex-1 focus:outline-none w-pw ">
                <main className="flex-1 relative pb-8 z-0">
                    <DashboardPageHeader
                        darkMode={props.darkMode}
                        activeRole={activeRole}
                        userRoles={userRoles}
                        setActiveRole={(r: RoleGroup) => {
                            setActiveRole(r);
                            localStorage.setItem("dashboard-active-role", r?.name);
                        }}
                    />
                    {/* @ts-ignore */}
                    {/* {activeRole && roleNameToContentMapping[activeRole.name] ? (
                        
                        roleNameToContentMapping[activeRole.name]
                    ) : activeRole ? (
                        <DefaultContent
                            darkMode={props.darkMode}
                            users={usersQuery.data || []}
                            activeRole={activeRole}
                        />
                    ) : ( */}
                    <>
                        <ProductSelection />
                        <TabbedTopSection />
                    </>
                    {/* // )} */}
                </main>
            </div>
        </>
    );
}
