import React from "react";
import PageDescription from "../../layout/page-description";
import PcmpCensus from "../pcmp/pcmp-census";
import { useQuery } from "@tanstack/react-query";
import companyService from "../../services/company.service";
import { Company } from "../../companies/domain/company";
import Loader3 from "../utilities/Loader3";
import ButtonNeoGen from "../../layout/button-neogen";
import UserCompanyPicker from "../../layout/user-company-picker";
import PcmpShortCensus from "../pcmp/pcmp-short-census";

function PcmpTestShort() {
    const [companySelected, setCompanySelected] = React.useState<Company | -1>(-1);
    const [pickerOpen, setPickerOpen] = React.useState(false);
    const companiesQuery = useQuery(["companies"], async () => {
        const response = await companyService.getAll();
        if (response) {
            return response.data;
        }
    });
    if (companiesQuery.isLoading) {
        return (
            <div>
                <Loader3 />
            </div>
        );
    }
    return (
        <>
            <PageDescription title="Short Census" description="This is the short census page" />
            <UserCompanyPicker
                open={pickerOpen}
                setOpen={function (open: boolean): void {
                    setPickerOpen(open);
                }}
                companyList={companiesQuery.data ?? []}
                showCompanies={true}
                showUsers={false}
                label="Select Company"
                setCompany={(c) => {
                    setCompanySelected(c);
                    setPickerOpen(false);
                }}
            />
            <ButtonNeoGen
                onClick={() => {
                    setCompanySelected(-1);
                }}
                type="info"
            >
                Company: {companySelected === -1 ? "All Companies" : companySelected.name}
                <i className="fas fa-times ml-2"></i>
            </ButtonNeoGen>
            <div className="mb-5 flex gap-5">
                <ButtonNeoGen
                    onClick={() => {
                        setPickerOpen(true);
                    }}
                    icon="fas fa-plus"
                >
                    Select Company
                </ButtonNeoGen>
            </div>
            Company: {companySelected === -1 ? "All Companies" : companySelected.name}
            <PcmpShortCensus company={companySelected} />
        </>
    );
}

export default PcmpTestShort;
