import React, { useEffect } from "react";
import ModalDialog from "../../../../layout/modal-dialog";
import PrintPre from "../../../../layout/print-pre";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import userAtom from "../../../../atoms/userAtom";
import interactionsService from "../../../../services/interactions.service";
import TextAreaNeoGenControlled from "../../../../layout/text-area-controlled";
import { set } from "lodash";
import { use } from "i18next";

function ChatModal({ show, close, whoTo }: { show: boolean; close: () => void; whoTo: any }) {
    const [user] = useRecoilState(userAtom);
    const queryCache = useQueryClient();
    const [newMessage, setNewMessage] = React.useState("");
    const messagesQuery = useQuery(["messages", user.id, whoTo?.id], async () => {
        const response = await interactionsService.getFilteredWhere({
            or: [
                // Note the field names really are snake case in the model
                { and: [{ interaction_from: user?.id }, { interaction_to: whoTo?.id }] },
                { and: [{ interaction_from: whoTo?.id }, { interaction_to: user?.id }] },
            ],
        });
        if (response) {
            return response.data.sort((a, b) => new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime());
        }
    });
    async function sendMessage() {
        await interactionsService.create({
            interaction_from: user.id,
            interaction_to: whoTo.id,
            activity: newMessage,
        });
        setNewMessage("");
        await queryCache.invalidateQueries(["messages", user.id, whoTo.id]);
        await messagesQuery.refetch();
        const objDiv = document.getElementById("status");
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight;
        }
    }
    useEffect(() => {
        const objDiv = document.getElementById("status");
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight;
        }
    }, [show]);
    if (!show) return null;
    if (messagesQuery.isLoading) return <div>Loading...</div>;
    return (
        <>
            <ModalDialog
                title={"Chat with " + whoTo?.name}
                close={close}
                show={show}
                size="md"
                okAction={() => sendMessage()}
                showCancel={false}
                okText="Send"
            >
                <div className="max-h-64 overflow-y-auto" id="status">
                    {/* <PrintPre>{messagesQuery.data}</PrintPre> */}
                    {messagesQuery.data?.map((message: any) => (
                        <div key={message.id} className="text-sm ">
                            {message.interaction_from === user.id ? (
                                <div className="flex justify-end ">
                                    <div
                                        key={message.id}
                                        className="bg-blue-500 rounded my-2 text-white p-2 text-right "
                                    >
                                        {new Date(message.dateCreated).toLocaleString()} From Me
                                        <div>{message.activity}</div>
                                        {/* <PrintPre>{message.activity}</PrintPre> */}
                                    </div>
                                </div>
                            ) : (
                                <div className="flex justify-start">
                                    <div key={message.id} className="bg-gray-500 rounded my-2 text-white p-2 ">
                                        {new Date(message.dateCreated).toLocaleString()}
                                        <div>{message.activity}</div>
                                        {/* <PrintPre>{message.activity}</PrintPre> */}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <TextAreaNeoGenControlled
                    label={"New Message"}
                    value={newMessage}
                    setValue={function (e: string): void {
                        setNewMessage(e);
                    }}
                />
            </ModalDialog>
        </>
    );
}

export default ChatModal;
