/* eslint-disable react/no-unescaped-entities */
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { z } from "zod";
import userAtom from "../../atoms/userAtom";
import { EmailField } from "../../layout/form/email-field";
import { Form } from "../../layout/form/form";
import { PasswordField } from "../../layout/form/password-field";
import { TextField } from "../../layout/form/text-field";
import { useForm } from "../../hooks/useForm";
import ButtonNeoGen from "../../layout/button-neogen";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import Logo from "./sure-advisors-logo.png";
import { PhoneNumberField } from "../../layout/form/phone-number-field";
import { useMutation } from "@tanstack/react-query";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import authService from "../../services/auth.service";
import { createUser } from "../../jason-proof-of-concept/users/actions/create-user";
import { Divider } from "../../typescript/divider";
import { useState } from "react";

const VerticalDivider = () => {
    return (
        <div className="relative w-0.5 h-screen mx-auto bg-gray-300">
            <div className="absolute top-0 left-[-5px] w-5 h-5 bg-gray-300 bg-opacity-25 transform -rotate-45 opacity-25"></div>
            <div className="absolute top-0 left-[-5px] w-5 h-5 bg-gray-300 bg-opacity-50 transform -rotate-45 opacity-50"></div>
            <div className="absolute bottom-0 left-[-5px] w-5 h-5 bg-gray-300 bg-opacity-0 transform rotate-45 opacity-0"></div>
            <div className="absolute bottom-0 left-[-5px] w-5 h-5 bg-gray-300 bg-opacity-25 transform rotate-45 opacity-25"></div>
        </div>
    );
};

const schema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    phone: z.string(),
    password: z.string().min(8),
    confirmPassword: z.string().min(8),
    companyName: z.string(),
});

type Data = z.infer<typeof schema>;

export const AffiliateSignUpForm = ({}: any) => {
    const [loggedInUser, setUser] = useRecoilState(userAtom);
    const authToken = getAuthTokenNoThrow() || "no-token";
    const form = useForm({ schema });
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [utmCode, setUtmCode] = useState(searchParams.get("utmCode") || "");

    const mutation = useMutation({
        mutationFn: async (data: Record<string, any>) => {
            const result = await createUser({ authToken, data });
            return result;
        },
    });

    const handleSubmit = async (data: Data) => {
        await authService.login("jasrusable@gmail.com", "123123123");
        navigate("/dashboard?affiliateOnboarding=true");
    };

    return (
        <div style={{ display: "flex", flex: 1, height: "100vh", background: "white" }}>
            <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                    <div style={{ width: "520px", marginTop: "20%" }}>
                        <h1 className="text-[42px]" style={{ marginBottom: 48 }}>
                            Join Our Affiliate Program
                        </h1>
                        <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
                            <div>
                                <div style={{ fontSize: 22, fontWeight: 600 }}>Earn $35 per referral</div>
                                <div style={{ color: "gray", fontSize: 14 }}>
                                    Earn up to $35 per client that you refer, we'll pay directly into your PayPal or
                                    Wise account.
                                </div>
                            </div>
                            <div>
                                <div style={{ fontSize: 22, fontWeight: 600 }}>Manage Your Deals</div>
                                <div style={{ color: "gray", fontSize: 14 }}>
                                    Effortlessly manage all your affiliate deals and team members in one place.
                                </div>
                            </div>
                            <div>
                                <div style={{ fontSize: 22, fontWeight: 600 }}>Invite Your Team</div>
                                <div style={{ color: "gray", fontSize: 14 }}>
                                    Add team members to help manage your deals and sub affiliates.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flex: 1, justifyContent: "center", alignContent: "center" }}>
                    <div
                        className="p-8 bg-white dark:bg-slate-800 rounded-[25px] m-10 w-md lg:w-[550px] h-[600px]"
                        style={{
                            boxShadow: "0px 0px 30px 5px lightgray",
                            border: "1px solid lightgray",
                            marginTop: "12%",
                        }}
                    >
                        <div className="flex justify-center mb-3">
                            <img src={Logo} width={180} style={{ margin: "auto", borderRadius: 10 }} />
                        </div>
                        <h1 className="text-center text-[28px]">Get Started Now</h1>
                        <div className="flex justify-center mt-1">
                            <p className="text-gray-400 text-sm w-[75%] text-center">
                                Refer and Earn with Sure Advisors
                            </p>
                        </div>
                        <div className="mt-10">
                            <Form onSubmit={form.handleSubmit(handleSubmit)}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
                                    <div style={{ flex: 1 }}>
                                        <TextField label="First Name" {...form.getFieldProps("firstName")} isRequired />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <TextField label="Last Name" {...form.getFieldProps("lastName")} isRequired />
                                    </div>
                                </div>
                                <EmailField
                                    label="Email Address"
                                    autoComplete="new-username"
                                    {...form.getFieldProps("email")}
                                    isRequired
                                />
                                <PhoneNumberField label="Phone Number" {...form.getFieldProps("phone")} />
                                <PasswordField
                                    autoComplete="new-password"
                                    label="Set A Password"
                                    {...form.getFieldProps("password")}
                                    isRequired
                                />
                                <ButtonNeoGen type="submit" className="w-full mt-6" size="lg">
                                    Get Started
                                </ButtonNeoGen>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
