export type PcmpCensusType = {
    id?: number;
    companyId: number;
    dateAdded?: Date;
    externalId?: string;
    firstName: string;
    lastName: string;
    ssn: string;
    stateEmployeeFilesIn: string;
    paySchedule: string;
    exemptFromSs?: "Y" | "N";
    grossWagesPerPayPeriod: number;
    employeeContributionMajorMedicalTotalPp: "Y" | "N";
    f_401kContributionTotalPp: number;
    additionalPreTaxDeductionsTotalPp: number;
    postTaxDeductionsTotalPp: number;
    federal2020W4Used: "Y" | "N";
    federalMaritalFilingStatus: "S" | "M";
    f_2020W4Box2cTwoJobs?: "Y" | "N";
    f_2020W4FederalDependentsAmount?: number;
    federalExemptionsClaimed: number;
    stateMaritalFilingStatus: "S" | "M";
    stateExemptionsClaimed: number;
    stateDependentExemptionsClaimed?: string; // (AL,GA,LA,PR Only)
    witholdingRate?: number; // (AZ Only)
    exemptionAmountClaimed?: number; // (MS Only)
    spouseWorks?: "Y" | "N"; // (MO, MT Only)
    dob?: Date;
    gender?: "M" | "F";
    hireDate?: Date;
    email?: string;
    homePhone?: string;
    businessPhone?: string;
    streetAddress?: string;
    streetAddress2?: string;
    city?: string;
    stateOfResidence?: string;
    zip?: string;
    subscriberId?: string;
    relationshipStatus?: string;
    effectiveDate?: Date;
    groupName?: string;
    employerLocationDivision?: string;
    companyDepartment?: string;
    companyDepartmentCode?: string;
    employeeUsername?: string;
    carrier?: string;
    prAllowancesBasedOnDeductions?: string;
    prYoungAdult?: "Y" | "N";
    prVeteranExemption?: "Y" | "N";
    prNonResident?: "Y" | "N";
    medicalTier?: string;
};

import APIService from "./api.service";

class PcmpCensus extends APIService<PcmpCensusType> {
    constructor() {
        super("pcmp-censuses");
    }
}

export default new PcmpCensus();
