export type PcmpShortCensusType = {
    id?: number;
    companyId?: number;
    lastName: string;
    firstName: string;
    stateEmployeeFilesIn: string;
    paySchedule: string;
    grossWagesPerPayPeriod: number;
    employeeContributionMajorMedicalTotalPp: number;
    federal2020W4Used: "Y" | "N";
    federalMaritalFilingStatus: "S" | "M";
    federalExemptionsClaimed: number;
    stateMaritalFilingStatus: "S" | "M";
    stateExemptionsClaimed: number;
    hireDate: Date;
};

import APIService from "./api.service";

class PcmpShortCensus extends APIService<PcmpShortCensusType> {
    constructor() {
        super("pcmp-short-censuses");
    }
}

export default new PcmpShortCensus();
