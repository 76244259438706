import React, { useEffect } from "react";
import ButtonNeoGen from "../../layout/button-neogen";
import InputControlled from "../../layout/input-controlled";

const signatures = [
    { name: "Signature 1", id: "sig1" },
    // { name: "Signature 2", id: "sig2" },
    // { name: "Signature 3", id: "sig3" },
    // { name: "Signature 4", id: "sig4" },
    { name: "Signature 5", id: "sig5" },
    { name: "Signature 6", id: "sig6" },
    { name: "Signature 7", id: "sig7" },
    { name: "Signature 8", id: "sig8" },
    { name: "Signature 9", id: "sig9" },
    // { name: "Signature 10", id: "sig10" },
];
function NewSignature({
    currentValue,
    onChange,
    isAdmin,
}: {
    currentValue: string;
    onChange: (value: string) => void;
    isAdmin: boolean;
}) {
    const [font, setFont] = React.useState("sig1");
    const [input, setInput] = React.useState("");
    const [saved, setSaved] = React.useState(false);
    const [dateSigned, setDateSigned] = React.useState("");
    console.log(currentValue);
    useEffect(() => {
        if (currentValue) {
            const [font, input, date] = currentValue.split("!!");
            setFont(font);
            setInput(input);
            setSaved(true);
            setDateSigned(date);
        }
    }, [currentValue]);
    if (saved) {
        return (
            <div>
                <div style={{ fontFamily: font }} className="text-4xl mb-5">
                    {input}
                </div>
                Signing Date: {new Date(parseInt(dateSigned)).toLocaleString()}
                {isAdmin && (
                    <ButtonNeoGen
                        onClick={() => {
                            setSaved(false);
                            setInput("");
                        }}
                    >
                        Edit Signature (Admin)
                    </ButtonNeoGen>
                )}
            </div>
        );
    }
    return (
        <div className="mt-5">
            <InputControlled value={input} onChange={(e) => setInput(e)} label="Your Name:" />
            {input.length === 0 ? (
                <div className="text-red-400 mb-5">Please enter your name above</div>
            ) : (
                <>
                    <div className="grid grid-cols-2 gap-5">
                        <div
                            style={{ fontFamily: font }}
                            className="text-4xl inline-block border border-gray-300 p-5 m-auto rounded"
                        >
                            {input}
                        </div>
                        <ButtonNeoGen
                            className="mt-5"
                            onClick={() => {
                                onChange(font + "!!" + input + "!!" + new Date().getTime());
                                setSaved(true);
                            }}
                        >
                            Save Signature
                        </ButtonNeoGen>
                    </div>
                </>
            )}
            <div className="text-gray-400 mb-5">Please Select a Signature Type:</div>
            <div className="grid grid-cols-3 gap-5 text-center mb-5">
                {signatures.map((sig) => (
                    <ButtonNeoGen key={sig.id} onClick={() => setFont(sig.id)} className="w-48" type="outline-primary">
                        <span style={{ fontFamily: sig.id }}>{input.length > 0 ? input : "Your Name"}</span>
                    </ButtonNeoGen>
                ))}
            </div>
        </div>
    );
}

export default NewSignature;
