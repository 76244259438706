import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import { useQuery } from "@tanstack/react-query";
import { getUserCompanies } from "../../../user-companies/actions/get-user-companies";
import { useMemo, useState } from "react";
import { useCompanies } from "../../../companies/hooks/use-companies";
import { AccountManagerStats } from "./accountManagerStats";
import { getCompanies } from "../../../companies/actions/get-companies";
import {
    ProcessflowProgressData,
    getProcessflowProgresses,
} from "../../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import MyCustomers from "../my-customers";
import { RoleGroup } from "../../../role-groups/domain/role-group";
import { getDataFromProgressData } from "../../../jason-proof-of-concept/wizard/wizard-container";
import { getW2Employees } from "../../../jason-proof-of-concept/other/actions/getEstimatedPayout";

export const AccountManagerContent = ({
    darkMode,
    currentUser,
    users,
    activeRole,
}: {
    darkMode: boolean;
    currentUser: ClearERCUser;
    users: ClearERCUser[];
    activeRole: undefined | null | RoleGroup;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-token";
    const userCompaniesQuery = useQuery(["docCollector"], async () => {
        const userCompanies = await getUserCompanies({
            authToken,
            filters: { where: { ercUserId: currentUser.id, roleGroupId: 14 } },
        });
        return userCompanies;
    });
    const userCompanies = useMemo(() => userCompaniesQuery.data || [], [userCompaniesQuery.data]);

    const companiesQuery = useCompanies({
        authToken,
        filters: { where: { id: { inq: userCompanies.map((uc) => uc.companyId) } } },
    });

    const userCustomerDataQuery = useQuery(["user-customer-data", { authToken }], async () => {
        // Get companies owned by this user
        const companies = await getCompanies({ authToken });

        // Get the progress for the owners
        let progresses: ProcessflowProgressData[] = [];
        try {
            progresses = await getProcessflowProgresses({ authToken, filters: { group: 7 } });
        } catch (e) {
            console.log(e);
            progresses = [];
        }
        return { progresses, companies };
    });

    const processflowProgresses = useMemo(
        () => userCustomerDataQuery.data?.progresses || [],
        [userCustomerDataQuery.data?.progresses],
    );

    const rows = useMemo(
        () =>
            companiesQuery.data?.map((company) => {
                const processflowProgress = processflowProgresses.find(
                    (p) => company.ownedById && company.ownedById === p.userId,
                );
                const status = processflowProgress?.status ?? "";
                const progressPercentage = Math.round((processflowProgress?.data?.percentageComplete || 0) * 100);
                const data = getDataFromProgressData(processflowProgress?.data || ({} as any));
                const w2Employees = getW2Employees({ data });
                const row = {
                    processflowProgress,
                    progressPercentage,
                    company,
                    status,
                    w2Employees,
                };

                return row;
            }),
        [companiesQuery, processflowProgresses],
    );

    return (
        <>
            <AccountManagerStats companies={rows} />
            {rows && rows?.length > 0 && <MyCustomers users={users} activeRole={activeRole} rows={rows} />}
        </>
    );
};
