import { z } from "zod";
import ModalDialog from "../../../../layout/modal-dialog";
import { PayoutRow } from "../paymasterContent";
import { ClearERCUser } from "../../../../typings/api/clear-erc-user";
import { TBody, THead, Table, Td, Tr } from "../../../../layout/table";
import { Currency } from "../../../../billing/components";
import { DateDisplay } from "../../../../layout/date-display";

const schema = z.object({
    amount: z.number(),
    date: z.date(),
    notes: z.string().nullish(),
    from: z.string().nullish(),
    to: z.string().nullish(),
    forCompanyId: z.number().nullish(),
    description: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const PayoutsSummaryModal = ({
    payoutsToView,
    currentUser,
    onClose,
}: {
    payoutsToView: PayoutRow;
    currentUser: ClearERCUser;
    onClose: () => any;
}) => {
    return (
        <ModalDialog
            show
            title={`Payout History for ${payoutsToView.userName} (${payoutsToView.companyName})`}
            close={onClose}
            showOk={false}
            showCancel={false}
            size="md"
        >
            {/* <p className="text-center mb-6 text-gray-400">Create a payout from cheque sent by the IRS.</p> */}
            <p className="font-bold text-center underline my-2">Payment History</p>
            {payoutsToView.payments.length > 0 ? (
                <Table>
                    <THead>
                        <Tr>
                            <Td>Date</Td>
                            <Td>Amount</Td>
                            <Td>Description</Td>
                            <Td>Notes</Td>
                        </Tr>
                    </THead>
                    <TBody>
                        {payoutsToView.payments.map((payment) => (
                            <Tr key={payment.id}>
                                <Td>
                                    <DateDisplay date={payment.date} />
                                </Td>
                                <Td>
                                    <Currency amount={payment.amount} />
                                </Td>
                                <Td>{payment.description ?? "-"}</Td>
                                <Td>{payment.notes ?? "-"}</Td>
                            </Tr>
                        ))}
                    </TBody>
                </Table>
            ) : (
                <p className="text-center">No payments yet</p>
            )}
        </ModalDialog>
    );
};
