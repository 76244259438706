import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { AffiliateContract } from "../domain/affiliate-contract";
import { affiliateContractSchema } from "./get-affiliate-contract";

export const updateAffiliateContract = async ({
    authToken,
    id,
    data,
}: {
    authToken?: string;
    id?: string;
    data: Partial<AffiliateContract>;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: `/affiliate-contracts/${id}`,
        authToken,
        data,
    });

    const affiliateContract = parseSchema(affiliateContractSchema, response.data);

    return affiliateContract;
};
