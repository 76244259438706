import { Dispatch, SetStateAction } from "react";
import ModalDialog from "../../../../../layout/modal-dialog";
import { DealNotes } from "../domain/deal";
import { DateDisplay } from "../../../../../layout/date-display";
import { Table, Td, Tr } from "../../../../../layout/table";

export const NotesModal = ({
    companyId,
    companyName,
    setShowNotesModalForCompanyId,
    notes,
}: {
    companyId: number;
    companyName: string | undefined;
    setShowNotesModalForCompanyId: Dispatch<SetStateAction<number | undefined>>;
    notes: DealNotes[] | undefined;
}) => {
    return (
        <ModalDialog
            title={`Notes for ${companyName}`}
            show={true}
            showCancel={false}
            showOk={false}
            close={() => setShowNotesModalForCompanyId(undefined)}
        >
            <div>
                <Table>
                    {notes?.map((note) => (
                        <Tr key={note.id} style={{ borderBottom: "1px solid lightgray" }}>
                            <Td style={{ width: "30%" }}>
                                <p style={{ fontSize: 12, fontWeight: 600 }}>{note.author}</p>
                                <p style={{ fontSize: 12, color: "gray" }}>
                                    <DateDisplay date={new Date(note.createdAt)} />
                                </p>
                            </Td>
                            <Td>{note.content}</Td>
                        </Tr>
                    ))}
                </Table>
            </div>
        </ModalDialog>
    );
};
