import { RoleGroup } from "../../../role-groups/domain/role-group";
import { Section, Stat } from "../../../affiliate-billing/affiliate-deals-page";
import { Currency } from "../../../billing/components";
import { TBody, THead, Table, Td, Tr } from "../../../layout/table";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import { useAuth } from "../../../auth/use-auth";
import { useMemo, useState } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { useCompanies } from "../../../companies/hooks/use-companies";
import { useUserCompanies } from "../../../companies/hooks/use-user-companies";
import ButtonNeoGen from "../../../layout/button-neogen";
import ModalDialog from "../../../layout/modal-dialog";
import { Company } from "../../../companies/domain/company";
import OptionsDropDown from "../../../layout/options-dropdown";
import { usePayments } from "../../../payments/hooks/use-payments";
import { set } from "lodash";
import { CreatePaymasterPaymentModal } from "./modals/createPaymasterPaymentModal";
import { Payment } from "../../../payments/domain/payment";
import { PayoutsSummaryModal } from "./modals/PayoutsSummaryModal";
import { DateDisplay } from "../../../layout/date-display";
import { updatePayment } from "../../../payments/actions/update-payment";

export type BankingDetails = {
    bankName: string | null | undefined;
    accountNumber: number | null | undefined;
    routingNumber: number | null | undefined;
    clientAddress: string | null | undefined;
    companyName: string | null | undefined;
};

export type PayoutRow = {
    companyId: number;
    companyName: string | null | undefined;
    userName: string | null | undefined;
    userId: string | null | undefined;
    role: string;
    payoutTotal: number;
    payoutOutstanding: number;
    bankingDetails?: BankingDetails;
    payments: Payment[];
};

export const PaymasterContent = ({
    darkMode,
    activeRole,
    usersQuery,
    currentUser,
}: {
    darkMode: boolean;
    activeRole: undefined | null | RoleGroup;
    usersQuery: UseQueryResult<ClearERCUser[] | undefined, unknown>;
    currentUser: ClearERCUser;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    // const [sortUsers, setSortUsers] = useState<"asc" | "desc">("desc");
    // const [sortPayoutsDue, setSortPayoutsDue] = useState<"asc" | "desc">("desc");
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);
    const [outstandingPayout, setOutstandingPayout] = useState(0);
    const [completedPayoutAmount, setCompletedPayoutAmount] = useState(0);
    const [showBankDetailsForCompany, setShowBankDetailsForCompany] = useState<BankingDetails | undefined>(undefined);
    const [userToPay, setUserToPay] = useState<PayoutRow | undefined>(undefined);
    const [payoutsToView, setPayoutsToView] = useState<PayoutRow | undefined>(undefined);
    const [showAcknowledgePaymentModal, setShowAcknowledgePaymentModal] = useState<string | undefined>(undefined);

    // Fetch user companies for paymaster
    const userCompaniesQuery = useUserCompanies({
        authToken,
        filters: {
            where: {
                roleGroupId: 18, // Paymaster ID
                ercUserId: currentUser.id,
            },
        },
    });

    const userCompanies = useMemo(() => userCompaniesQuery.data || [], [userCompaniesQuery.data]);

    // Fetch companies assigned to paymaster
    const companiesQuery = useCompanies({
        authToken,
        filters: { where: { id: { inq: (userCompanies || []).map((uc) => uc.companyId) } } },
    });
    const companies = useMemo(() => companiesQuery.data || [], [companiesQuery.data]);

    // Fetch any lockbox payments for paymaster companies
    const paymentsQuery = usePayments({
        authToken,
        filters: { where: { companyId: { inq: companies.map((c) => c.id) } } },
    });
    const payments = useMemo(() => paymentsQuery.data || [], [paymentsQuery.data]);

    // Recorded payouts from lockbox to paymaster
    const lockboxPayments = useMemo(() => payments.filter((p) => p.from === "Lockbox"), [payments]);

    const paymasterPayoutPayments = useMemo(() => payments.filter((p) => p.from?.includes("Paymaster")), [payments]);

    const pendingPaymentsFromLockbox = useMemo(
        () => lockboxPayments.filter((p) => !p.receivedByPaymasterAt),
        [lockboxPayments],
    );

    const receivedPaymentsFromLockbox = useMemo(
        () => lockboxPayments.filter((p) => p.receivedByPaymasterAt),
        [lockboxPayments],
    );

    const rows = useMemo(() => {
        return companies.reduce((acc, company) => {
            const paymasterCommissionPercentage =
                userCompanies.find((uc) => uc.companyId === company.id)?.totalFeePercent || 1;

            const ercRepairCommissionPercentage = 25;

            const totalReceivedForClient = receivedPaymentsFromLockbox.reduce(
                (acc, row) => (row.companyId === company.id ? acc + row.amount : acc),
                0,
            );

            const paymentsToClient = paymasterPayoutPayments.filter(
                (p) => p.companyId === company.id && p.toUserId === company.ownedById,
            );

            const paymentsToPaymaster = paymasterPayoutPayments.filter(
                (p) => p.companyId === company.id && p.toUserId === currentUser.id,
            );

            const paymentsToERCRepair = paymasterPayoutPayments.filter(
                (p) => p.companyId === company.id && p.toClearErc === true,
            );

            const sumPaymentsToERCRepair = paymentsToERCRepair?.reduce((acc, cur) => acc + cur.amount, 0);
            const sumPaymentsToPaymaster = paymentsToPaymaster?.reduce((acc, cur) => acc + cur.amount, 0);

            const dueToERCRepair =
                totalReceivedForClient * (ercRepairCommissionPercentage / 100) - sumPaymentsToERCRepair;
            const dueToPaymaster =
                totalReceivedForClient * (paymasterCommissionPercentage / 100) - sumPaymentsToPaymaster;
            const dueToClient =
                totalReceivedForClient -
                dueToPaymaster -
                dueToERCRepair -
                sumPaymentsToERCRepair -
                sumPaymentsToPaymaster;

            const ownerPayout = {
                companyId: company.id,
                companyName: company.name,
                userName: company.ownedBy?.firstName + " " + company.ownedBy?.lastName,
                userId: company.ownedBy?.id,
                role: "Owner",
                payoutOutstanding: dueToClient,
                payoutTotal: totalReceivedForClient - dueToPaymaster,
                payments: paymentsToClient,
                bankingDetails: {
                    bankName: company.bankName,
                    companyName: company.name,
                    accountNumber: company.accountNumber,
                    routingNumber: company.routingNumber,
                    clientAddress: company.clientAddress,
                },
            };
            const paymasterPayout = {
                companyId: company.id,
                companyName: company.name,
                userName: currentUser?.firstName + " " + currentUser?.lastName + " (Self)",
                userId: currentUser?.id,
                role: "Paymaster",
                payoutTotal: dueToPaymaster,
                payments: paymentsToPaymaster,
                payoutOutstanding: dueToPaymaster,
                bankingDetails: undefined,
            };
            const ercRepairPayout = {
                companyId: company.id,
                companyName: company.name,
                userName: "Jason Birkett",
                userId: users.find((u) => u.email === "jason@neogen.ai")?.id,
                role: "ERC Company",
                payoutTotal: dueToERCRepair,
                payments: paymentsToERCRepair,
                payoutOutstanding: dueToERCRepair,
                bankingDetails: undefined,
            };
            return [...acc, ownerPayout, paymasterPayout, ercRepairPayout];
        }, [] as PayoutRow[]);
    }, [companies, receivedPaymentsFromLockbox]).sort((a, b) => {
        return b.payoutOutstanding - a.payoutOutstanding;
    });

    const sumTotalReceivedFromLockboxPayouts = receivedPaymentsFromLockbox.reduce((acc, row) => acc + row.amount, 0);
    const sumTotalPaidOutByPaymaster = paymasterPayoutPayments.reduce((acc, row) => acc + row.amount, 0);

    useMemo(() => setCompletedPayoutAmount(sumTotalPaidOutByPaymaster), [sumTotalPaidOutByPaymaster]);
    useMemo(
        () => setOutstandingPayout(sumTotalReceivedFromLockboxPayouts - sumTotalPaidOutByPaymaster),
        [sumTotalReceivedFromLockboxPayouts, sumTotalPaidOutByPaymaster],
    );

    return (
        <>
            {showAcknowledgePaymentModal && (
                <ModalDialog
                    show
                    title={`Acknowledge payment received`}
                    close={() => setShowAcknowledgePaymentModal(undefined)}
                    showOk={false}
                    showCancel={false}
                    size="xs"
                >
                    <div className="text-center mb-4">Are you sure you want to acknowledge this payment?</div>
                    <div className="flex gap-4 justify-center">
                        <ButtonNeoGen type="outline" onClick={() => setShowAcknowledgePaymentModal(undefined)}>
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen
                            onClick={async () => {
                                await updatePayment({
                                    authToken,
                                    id: showAcknowledgePaymentModal,
                                    data: { receivedByPaymasterAt: new Date() },
                                });
                                setShowAcknowledgePaymentModal(undefined);
                                paymentsQuery.refetch();
                            }}
                        >
                            Acknowledge
                        </ButtonNeoGen>
                    </div>
                </ModalDialog>
            )}
            {userToPay && (
                <CreatePaymasterPaymentModal
                    userToPay={userToPay}
                    currentUser={currentUser}
                    onClose={() => {
                        setUserToPay(undefined);
                    }}
                    onPaymentCaptured={(payment) => {
                        setUserToPay(undefined);
                        paymentsQuery.refetch();
                    }}
                />
            )}
            {payoutsToView && (
                <PayoutsSummaryModal
                    payoutsToView={payoutsToView}
                    currentUser={currentUser}
                    onClose={() => {
                        setPayoutsToView(undefined);
                    }}
                />
            )}
            {showBankDetailsForCompany && (
                <ModalDialog
                    show
                    title={`Bank Details for ${showBankDetailsForCompany?.companyName}`}
                    close={() => setShowBankDetailsForCompany(undefined)}
                    showOk={false}
                    showCancel={false}
                    size="sm"
                >
                    <Table>
                        <Tr>
                            <Td>Bank Name</Td>
                            <Td>{showBankDetailsForCompany?.bankName ?? "-"}</Td>
                        </Tr>
                        <Tr>
                            <Td>Account Number</Td>
                            <Td>{showBankDetailsForCompany?.accountNumber ?? "-"}</Td>
                        </Tr>
                        <Tr>
                            <Td>Routing Number</Td>
                            <Td>{showBankDetailsForCompany?.routingNumber ?? "-"}</Td>
                        </Tr>
                        <Tr>
                            <Td>Client Address</Td>
                            <Td>{showBankDetailsForCompany?.clientAddress ?? "-"}</Td>
                        </Tr>
                    </Table>
                    <p className="text-center mt-4">
                        If you would like to request or confirm these banking details, please contact{" "}
                        <a
                            className="underline text-blue-600 font-semibold"
                            href={`mailto:jason@ercrepair.com?subject=Banking%20Details%20Update%20Request%20for%20${showBankDetailsForCompany.companyName}%20on%20ERC%20Repair&body=Hi%20Jason%2C%0D%0A%0D%0APlease%20could%20you%20update%20the%20banking%20details%20of%20${showBankDetailsForCompany.companyName}%20on%20ERC%20Repair%3F%20%0D%0A%0D%0AThank%20you%2C%0D%0A${currentUser.firstName}%20${currentUser.lastName}`}
                        >
                            jason@ercrepair.com
                        </a>
                    </p>
                </ModalDialog>
            )}
            <div className="mt-5 flex flex-col gap-5">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 20,
                        flex: 1,
                        justifyContent: "space-between",
                    }}
                >
                    <Section
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                        }}
                        className="w-full"
                    >
                        <Stat
                            title="Total Balance Available For Payout"
                            value={<Currency amount={outstandingPayout} />}
                        />
                        <Stat title="Completed Payouts" value={<Currency amount={completedPayoutAmount} />} />
                    </Section>
                </div>
                <div>Processing Payments from ERC Repair Lockbox</div>
                {pendingPaymentsFromLockbox.length > 0 ? (
                    <Table>
                        <THead>
                            <Tr>
                                <Td>Client</Td>
                                <Td>Date Sent</Td>
                                <Td>Cheque Number</Td>
                                <Td>Total Sent</Td>
                                <Td></Td>
                            </Tr>
                        </THead>
                        <TBody>
                            {pendingPaymentsFromLockbox?.map((row) => (
                                <Tr key={row.chequeNumber}>
                                    <Td>{companies.find((c) => c.id === row.companyId)?.name || "-"}</Td>
                                    <Td>{row.date && <DateDisplay date={row.date} />}</Td>
                                    <Td>{row.chequeNumber}</Td>
                                    <Td>
                                        <Currency amount={row.amount} />
                                    </Td>
                                    <Td>
                                        <ButtonNeoGen onClick={() => setShowAcknowledgePaymentModal(row.id)}>
                                            Acknowledge Payment Received
                                        </ButtonNeoGen>
                                    </Td>
                                </Tr>
                            ))}
                        </TBody>
                    </Table>
                ) : (
                    <div className="text-center">No payments in progress</div>
                )}
                <div>Payments Due</div>
                {rows.length > 0 ? (
                    <Table>
                        <THead>
                            <Tr>
                                <Td style={{ display: "flex", gap: 10 }}>
                                    Client{" "}
                                    {/* <button
                                        className={sortUsers === "asc" ? "fa fa-caret-down" : "fa fa-caret-up"}
                                        onClick={() => setSortUsers(sortUsers === "asc" ? "desc" : "asc")}
                                    /> */}
                                </Td>
                                <Td>User</Td>
                                <Td>Role</Td>
                                <Td style={{ display: "flex", gap: 10 }}>
                                    Payout Due{" "}
                                    {/* <button
                                        className={sortPayoutsDue === "asc" ? "fa fa-caret-down" : "fa fa-caret-up"}
                                        onClick={() => setSortPayoutsDue(sortPayoutsDue === "asc" ? "desc" : "asc")}
                                    /> */}
                                </Td>
                                <Td>Banking Details</Td>
                                <Td></Td>
                            </Tr>
                        </THead>
                        <TBody>
                            {rows?.map((row) => (
                                <Tr key={row.companyId}>
                                    <Td>{row.companyName}</Td>
                                    <Td>{row.userName}</Td>
                                    <Td>{row.role}</Td>
                                    <Td>
                                        <Currency amount={row.payoutOutstanding} />
                                    </Td>
                                    <Td>
                                        {row.role === "Owner" ? (
                                            <ButtonNeoGen
                                                onClick={() => setShowBankDetailsForCompany(row.bankingDetails)}
                                                type="outline"
                                            >
                                                View
                                            </ButtonNeoGen>
                                        ) : (
                                            "-"
                                        )}
                                    </Td>
                                    <Td>
                                        <div>
                                            <OptionsDropDown
                                                options={[
                                                    {
                                                        label: "Record Payment",
                                                        action: () => setUserToPay(row),
                                                    },
                                                    {
                                                        label: "View Payments",
                                                        action: () => setPayoutsToView(row),
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </Td>
                                </Tr>
                            ))}
                        </TBody>
                    </Table>
                ) : (
                    <p className="text-center">No data yet</p>
                )}
            </div>
        </>
    );
};
