import React, { useCallback, useEffect } from "react";
import ModalDialog from "../../../layout/modal-dialog";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { PcmpCensusType } from "../../../services/pcmp-census.service";
import InputControlled from "../../../layout/input-controlled";
import SelectNeoGen from "../../../layout/select-neogen";
import { states } from "../../../states";
import ZipCodeInput from "../../../layout/form/zipcode-input";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { Label } from "../../../layout/label";
import { CurrencyInput } from "../../../layout/form/currency-input";
import { NumberInput } from "../../../layout/form/number-input";
import pcmpShortCensusService, { PcmpShortCensusType } from "../../../services/pcmp-short-census.service";
import { useQueryClient } from "@tanstack/react-query";

function AddEditShortCensus({
    show,
    close,
    existingData,
    companyId,
}: {
    show: boolean;
    close: () => void;
    existingData?: PcmpShortCensusType;
    companyId: number;
}) {
    const [internalData, setInternalData] = React.useState<any>();
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [paySchedule, setPaySchedule] = React.useState("Weekly");
    const [grossWagesPerPayPeriod, setGrossWagesPerPayPeriod] = React.useState(0);
    const [federal2020W4Used, setFederal2020W4Used] = React.useState<"Y" | "N">("N");
    const [federalMaritalFilingStatus, setFederalMaritalFilingStatus] = React.useState<"S" | "M">("S");
    const [federalExemptionsClaimed, setFederalExemptionsClaimed] = React.useState<number>(0);
    const [stateMaritalFilingStatus, setStateMaritalFilingStatus] = React.useState<"S" | "M">("S");
    const [stateExemptionsClaimed, setStateExemptionsClaimed] = React.useState<number>(0);
    const [hireDate, setHireDate] = React.useState<Date | null>(null);
    const queryCache = useQueryClient();
    // const [email, setEmail] = React.useState("");
    // const [ssn, setSSN] = React.useState("");
    // const [streetAddress, setStreetAddress] = React.useState("");
    // const [streetAddress_2, setStreetAddress_2] = React.useState("");
    // const [city, setCity] = React.useState("");
    const [stateEmployeeFilesIn, setStateEmployeeFilesIn] = React.useState("");
    // const [zip, setZip] = React.useState("");
    // const [homePhone, setHomePhone] = React.useState("");
    // const [dob, setDob] = React.useState<Date | null>(null);
    const [dataSet, setDataSet] = React.useState(false);
    // const [gender, setGender] = React.useState<undefined | "M" | "F">(undefined);
    // const [exemptFromSS, setExemptFromSS] = React.useState<"Y" | "N">("N");
    const [employeeContributionMajorMedicalTotalPp, setEmployeeContributionMajorMedicalTotalPp] = React.useState(0);
    const setData = useCallback((data: any) => {
        setInternalData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
        console.log(data);
    }, []);
    useEffect(() => {
        if (existingData && !dataSet) {
            setFirstName(existingData.firstName);
            setLastName(existingData.lastName);
            setPaySchedule(existingData.paySchedule);
            setGrossWagesPerPayPeriod(existingData.grossWagesPerPayPeriod);
            setFederal2020W4Used(existingData.federal2020W4Used);
            setFederalMaritalFilingStatus(existingData.federalMaritalFilingStatus);
            setFederalExemptionsClaimed(existingData.federalExemptionsClaimed);
            setStateMaritalFilingStatus(existingData.stateMaritalFilingStatus);
            setStateExemptionsClaimed(existingData.stateExemptionsClaimed);
            // setEmail(existingData.email);
            // setSSN(existingData.ssn);
            // setDob(existingData.dob);
            // setGender(existingData.gender);
            setDataSet(true);
            setHireDate(existingData.hireDate);
            // setStreetAddress(existingData.streetAddress);
            // setStreetAddress_2(existingData.streetAddress_2);
            // setCity(existingData.city);
            setStateEmployeeFilesIn(existingData.stateEmployeeFilesIn);
            // setZip(existingData.zip);
            // setHomePhone(existingData.homePhone);
            // setExemptFromSS(existingData.exemptFromSS);
            setEmployeeContributionMajorMedicalTotalPp(existingData.employeeContributionMajorMedicalTotalPp);
            return;
        }
        setData({
            firstName,
            lastName,
            stateEmployeeFilesIn,
            paySchedule,
            grossWagesPerPayPeriod,
            employeeContributionMajorMedicalTotalPp,
            federal2020W4Used,
            federalMaritalFilingStatus,
            federalExemptionsClaimed,
            stateMaritalFilingStatus,
            stateExemptionsClaimed,
            companyId,
            hireDate,
        });
    }, [
        companyId,
        dataSet,
        employeeContributionMajorMedicalTotalPp,
        existingData,
        federal2020W4Used,
        federalExemptionsClaimed,
        federalMaritalFilingStatus,
        firstName,
        grossWagesPerPayPeriod,
        hireDate,
        lastName,
        paySchedule,
        setData,
        stateEmployeeFilesIn,
        stateExemptionsClaimed,
        stateMaritalFilingStatus,
    ]);

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(" ");
    }
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    return (
        <ModalDialog
            size="xl"
            close={() => close()}
            show={show}
            title={existingData ? "Edit Short Census Entry" : "Add Short Census Entry"}
            okAction={async () => {
                console.log(internalData);
                if (existingData) {
                    alert("Edit");
                    close();
                }
                if (!existingData) {
                    // alert(JSON.stringify(internalData));
                    const response = await pcmpShortCensusService.create(internalData);
                    await queryCache.invalidateQueries(["pcmpShortCensus", "Mapped", companyId]);
                    close();
                }
            }}
        >
            <>
                <div className="grid grid-cols-3 gap-x-5">
                    <InputControlled
                        required={true}
                        value={firstName}
                        label="First Name"
                        onChange={(v) => setFirstName(v)}
                    />
                    <InputControlled
                        required={true}
                        value={lastName}
                        label="Last Name"
                        onChange={(v) => setLastName(v)}
                    />
                    {/* <InputControlled type="email" value={email} label="Email" onChange={(v) => setEmail(v)} /> */}

                    <SelectNeoGen
                        label="State Employee Files In"
                        options={states.map((s) => ({ id: s.code, name: s.name }))}
                        value={stateEmployeeFilesIn}
                        onChange={(v: any) => setStateEmployeeFilesIn(v as string)}
                    />
                </div>
                <div className="grid grid-cols-3 gap-x-5">
                    <SelectNeoGen
                        value={paySchedule}
                        onChange={(v: any) => setPaySchedule(v as string)}
                        label="Pay Schedule"
                        options={[
                            { id: "Weekly", name: "Weekly" },
                            { id: "Bi-Weekly", name: "Bi-Weekly" },
                            { id: "Monthly", name: "Monthly" },
                        ]}
                    />

                    <div>
                        <Label text="Gross Wages per Pay Period" isRequired={false} />
                        <CurrencyInput
                            value={grossWagesPerPayPeriod}
                            // label="Gross Wages/Pay Period"
                            onChange={(v) => {
                                console.log(v.currentTarget.value.replace(/[^.0-9]/g, ""));
                                setGrossWagesPerPayPeriod(Number(v.currentTarget.value.replace(/[^.0-9]/g, "")));
                            }}
                        />
                    </div>
                    <div className="mb-5">
                        <Label text="Employee Major Medical Pp" isRequired={false} />
                        <CurrencyInput
                            value={employeeContributionMajorMedicalTotalPp}
                            onChange={(v) => {
                                console.log(v.currentTarget.value.replace(/[^.0-9]/g, ""));
                                setEmployeeContributionMajorMedicalTotalPp(
                                    Number(v.currentTarget.value.replace(/[^.0-9]/g, "")),
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="mb-5 grid grid-cols-3 gap-x-5">
                    <SelectNeoGen
                        value={federal2020W4Used}
                        onChange={(v: any) => setFederal2020W4Used(v as "Y" | "N")}
                        label="Federal 2020 W4 Used"
                        options={[
                            { id: "Y", name: "Yes" },
                            { id: "N", name: "No" },
                        ]}
                    />
                    <SelectNeoGen
                        value={federalMaritalFilingStatus}
                        onChange={(v: any) => setFederalMaritalFilingStatus(v as "S" | "M")}
                        label="Federal Marital/Filing Status"
                        options={[
                            { id: "S", name: "Single" },
                            { id: "M", name: "Married" },
                        ]}
                    />
                    <div>
                        <Label text="Federal Exemptions Claimed" isRequired={false} />
                        <NumberInput
                            value={federalExemptionsClaimed}
                            onChange={(v) => setFederalExemptionsClaimed(Number(v.currentTarget.value))}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-x-5">
                    <div className="">
                        <SelectNeoGen
                            value={stateMaritalFilingStatus}
                            onChange={(v: any) => setStateMaritalFilingStatus(v as "S" | "M")}
                            label="State Marital Filing Status"
                            options={[
                                { id: "S", name: "Single" },
                                { id: "M", name: "Married" },
                            ]}
                        />
                    </div>
                    <div className="">
                        <Label text="State Exemptions Claimed" isRequired={false} />
                        <NumberInput
                            value={stateExemptionsClaimed}
                            onChange={(v) => {
                                console.log(v.currentTarget.value.replace(/[^.0-9]/g, ""));
                                setStateExemptionsClaimed(Number(v.currentTarget.value.replace(/[^.0-9]/g, "")));
                            }}
                        />
                    </div>
                    <div className="">
                        <Label text="Hire Date" isRequired={false} />
                        <Datepicker
                            asSingle={true}
                            displayFormat="MM/DD/YYYY"
                            value={{ startDate: hireDate, endDate: hireDate }}
                            onChange={function (value: DateValueType, e?: HTMLInputElement | null | undefined): void {
                                console.log(value);
                                if (value?.startDate) {
                                    const startDate = value.startDate.toString();
                                    const dateParts = startDate?.split("-");
                                    const jsDate = new Date(Date.parse(startDate.replace(/-/g, "/")));
                                    setHireDate(jsDate);
                                    console.log(jsDate);
                                }
                            }}
                        />
                    </div>
                </div>
                {/* <div className="grid grid-cols-3 gap-x-5">
                    <InputControlled
                        required={true}
                        value={ssn}
                        label="Social Security Number"
                        onChange={(v) => setSSN(v)}
                    />

                    <div className="mb-5">
                        <SelectNeoGen
                            value={gender}
                            onChange={(v: any) => setGender(v as "M" | "F")}
                            label="Gender"
                            options={[
                                { id: "M", name: "Male" },
                                { id: "F", name: "Female" },
                            ]}
                        />
                    </div>

                    <div className="mb-5">
                        <Label text="Date of Birth" isRequired={false} />
                        <Datepicker
                            asSingle={true}
                            displayFormat="MM/DD/YYYY"
                            value={{ startDate: dob, endDate: dob }}
                            onChange={function (value: DateValueType, e?: HTMLInputElement | null | undefined): void {
                                console.log(value);
                                if (value?.startDate) {
                                    const startDate = value.startDate.toString();
                                    const dateParts = startDate?.split("-");
                                    const jsDate = new Date(Date.parse(startDate.replace(/-/g, "/")));
                                    setDob(jsDate);
                                    console.log(jsDate);
                                }
                            }}
                        />
                    </div>
                </div> */}
                {/* <InputControlled
                    value={streetAddress}
                    label="Street Address Line 1"
                    onChange={(v) => setStreetAddress(v)}
                />
                <InputControlled
                    value={streetAddress_2}
                    label="Street Address Line 2"
                    onChange={(v) => setStreetAddress_2(v)}
                />
                <ZipCodeInput
                    value={zip}
                    setValue={(z: any) => {
                        setZip(z);
                        console.log(z);
                    }}
                    label="Zipcode"
                    required={false}
                    name={undefined}
                    disabled={undefined}
                    errors={undefined}
                    setCity={setCity}
                    setState={setState}
                />
                <InputControlled value={homePhone} label="Home Phone" onChange={(v) => setHomePhone(v)} />
                <div className="grid grid-cols-2 gap-x-5">
                    <div className="mb-5">
                        <SelectNeoGen
                            value={exemptFromSS}
                            onChange={(v: any) => setExemptFromSS(v as "Y" | "N")}
                            label="Exempt from Social Security"
                            options={[
                                { id: "Y", name: "Yes" },
                                { id: "N", name: "No" },
                            ]}
                        />
                    </div>
                    <div className="mb-5">
                        <SelectNeoGen
                            required={true}
                            value={employeeContributionMajorMedicalTotalPp}
                            onChange={(v: any) => setEmployeeContributionMajorMedicalTotalPp(v as "Y" | "N")}
                            label="Employee Contribution Major Medical Total PP"
                            options={[
                                { id: "Y", name: "Yes" },
                                { id: "N", name: "No" },
                            ]}
                        />
                    </div>
                </div> */}
            </>
            {/* ); */}
        </ModalDialog>
    );
}

export default AddEditShortCensus;
