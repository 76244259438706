import React, { useState } from "react";
import ButtonNeoGen from "../../../layout/button-neogen";
import { useQuery } from "@tanstack/react-query";
import { getCompanies } from "../../../companies/actions/get-companies";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import fileService from "../../../services/file.service";
import PrintPre from "../../../layout/print-pre";
import { Document, Page } from "react-pdf";
import { BuildingOfficeIcon, CreditCardIcon, UserIcon, UsersIcon } from "@heroicons/react/20/solid";
import TableNeogen from "../../../layout/table-neogen";
import SwalNeogenFire from "../../../layout/swal-neogen";
import { id } from "date-fns/locale";
import { Tr } from "../../../layout/table";

const FILE_API_URL = process.env.REACT_APP_FILE_SERVER ?? process.env.REACT_APP_API_URL ?? "https://api.clearerc.com/";
const tabs = [
    { name: "Updates", href: "#", icon: UserIcon, current: true, id: 1 },
    { name: "Package Information", href: "#", icon: BuildingOfficeIcon, current: false, id: 2 },
    // { name: "How to read", href: "#", icon: UsersIcon, current: false, id: 3 },
    // { name: "Billing", href: "#", icon: CreditCardIcon, current: false },
];
function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

function Transcripts() {
    const [currentTab, setCurrentTab] = useState(1);
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const authToken = getAuthTokenNoThrow() || "no-token";

    const taFilesQuery = useQuery({
        queryFn: async () => {
            const companies = await getCompanies({ authToken });
            const response = await fileService.getFiles({
                where: { companyId: companies?.[0].id, notes: "cpa-transcript" },
            });

            return response;
        },
        queryKey: ["cpa-transcript"],
    });
    // });
    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
        setPageNumber(1);
    }
    const taFiles = taFilesQuery.data;
    return (
        <>
            {/* <div>TaxPayerAdvocate</div> */}
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        defaultValue={tabs?.find((tab) => tab.current)?.name}
                    >
                        {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <a
                                    key={tab.name}
                                    href={tab.href}
                                    className={classNames(
                                        tab.current
                                            ? "border-indigo-500 text-indigo-600"
                                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                        "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium",
                                    )}
                                    onClick={() => {
                                        tabs.forEach((t) => (t.current = false));
                                        tab.current = true;
                                        setCurrentTab(tab.id);
                                    }}
                                    aria-current={tab.current ? "page" : undefined}
                                >
                                    <tab.icon
                                        className={classNames(
                                            tab.current ? "text-indigo-500" : "text-gray-400 group-hover:text-gray-500",
                                            "-ml-0.5 mr-2 h-5 w-5",
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span>{tab.name}</span>
                                </a>
                            ))}
                        </nav>
                    </div>
                    <div className="mt-5">
                        {/* <TableNeogen entries={taFiles?.pageItems ?? []} />
                        {taFiles?.pageItems?.map((file: any) => {
                            return (
                                <div key={file.id}>
                                    <ButtonNeoGen onClick={() => window.open(FILE_API_URL + "/files/" + file.filename)}>
                                        {file.originalFilename}
                                    </ButtonNeoGen>
                                </div>
                            );
                        })} */}
                        {currentTab === 1 && (
                            <TableNeogen
                                entries={(taFiles?.pageItems ?? []).map((file) => ({
                                    id: file.id,
                                    name: file.originalFilename || "-",
                                    type: file.key?.entryField || "-",
                                    // isArchived: file.isArchived,
                                    uploadedBy: file.uploaded_by || "-",
                                    // company: companies.find((c) => c.value === file.companyId)?.label || "-",
                                    dateUploaded: file.dateUploaded || "-",
                                }))}
                                headers={["File Name", "Date Uploaded"]}
                                formatters={[
                                    {
                                        field: "uploadedBy",
                                        type: "User",
                                    },
                                    {
                                        field: "dateUploaded",
                                        type: "DateTime",
                                    },
                                ]}
                                ignoreFields={[
                                    "key",
                                    "isArchived",
                                    "encoding",
                                    "documentTypeId",
                                    "notes",
                                    "type",
                                    "filename",
                                    "mimetype",
                                    "uploadedBy",
                                ]}
                                actionsAreDropDown={false}
                                actions={[
                                    {
                                        label: "Download",
                                        icon: "fas fa-download",
                                        onClick: (entry) => {
                                            const file = taFiles?.pageItems?.find((file) => file.id === entry);
                                            if (file) {
                                                fileService
                                                    .downloadFile(file.filename, file.originalFilename)
                                                    .catch((error) => {
                                                        console.error(error);
                                                        SwalNeogenFire({
                                                            title: "Error",
                                                            text:
                                                                "There was an error downloading the file: " +
                                                                error.response.statusText,
                                                            icon: "error",
                                                            showConfirmButton: false,
                                                            timer: 3500,
                                                        });
                                                    });
                                            } else {
                                                // alert("Not found");
                                                // console.error({ entry, taFiles?.pageItems });
                                            }
                                        },
                                    },
                                ]}
                            />
                        )}
                        {currentTab === 2 && (
                            <>
                                <div className="flex flex-row items-center gap-2 w-full place-content-center ">
                                    <ButtonNeoGen
                                        disabled={pageNumber === 1}
                                        onClick={() => {
                                            const pn = numPages && pageNumber > 1 ? pageNumber - 1 : pageNumber;
                                            setPageNumber(pn);
                                        }}
                                    >
                                        Back
                                    </ButtonNeoGen>
                                    Page {pageNumber} of {numPages}
                                    <ButtonNeoGen
                                        disabled={pageNumber === numPages}
                                        onClick={() => {
                                            const pn = numPages && pageNumber < numPages ? pageNumber + 1 : pageNumber;
                                            setPageNumber(pn);
                                        }}
                                    >
                                        Next
                                    </ButtonNeoGen>
                                </div>
                                <Document
                                    file={FILE_API_URL + "/files/erc-document-742e6866-ac89-4fe5-b5e3-185a63fb0103.pdf"}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    className={"text-center flex flex-col items-center"}
                                >
                                    <Page pageNumber={pageNumber} />
                                </Document>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* <PrintPre>{taFiles}</PrintPre> */}
        </>
    );
}

export default Transcripts;
