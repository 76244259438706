import { RoleGroup } from "../../../role-groups/domain/role-group";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import { useAuth } from "../../../auth/use-auth";
import { useEffect, useMemo, useState } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { useCompanies } from "../../../companies/hooks/use-companies";
import { useUserCompanies } from "../../../companies/hooks/use-user-companies";
import { useLocation } from "react-router-dom";
import { SignContractsModal } from "../../../sure-advisors-affiliates/components/sign-contracts-modal";
import { DealsSection } from "./sureAdvisorsAffiliateContent/components/dealsSection";
import { AffiliateTabs } from "./sureAdvisorsAffiliateContent/components/affiliateTabs";
import { TeamSection } from "./sureAdvisorsAffiliateContent/components/teamSection";
import { Deal } from "./sureAdvisorsAffiliateContent/domain/deal";
import { BillingAndHistorySection } from "./sureAdvisorsAffiliateContent/components/billingAndHistorySection";
import { TeamMember } from "./sureAdvisorsAffiliateContent/domain/team";
import { BillingRow } from "./sureAdvisorsAffiliateContent/domain/billing";

export const SureAdvisorAffiliateContent = ({
    darkMode,
    activeRole,
    usersQuery,
    currentUser,
}: {
    darkMode: boolean;
    activeRole: undefined | null | RoleGroup;
    usersQuery: UseQueryResult<ClearERCUser[] | undefined, unknown>;
    currentUser: ClearERCUser;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);
    const [showContractModal, setShowContractModal] = useState(false);
    const [activeTab, setActiveTab] = useState<"Deals" | "Team" | "Billing & History">("Deals");

    // // Fetch user companies for paymaster
    // const userCompaniesQuery = useUserCompanies({
    //     authToken,
    //     filters: {
    //         where: {
    //             roleGroupId: 18, // Paymaster ID
    //             ercUserId: currentUser.id,
    //         },
    //     },
    // });

    // const userCompanies = useMemo(() => userCompaniesQuery.data || [], [userCompaniesQuery.data]);

    // // Fetch companies assigned to paymaster
    // const companiesQuery = useCompanies({
    //     authToken,
    //     filters: { where: { id: { inq: (userCompanies || []).map((uc) => uc.companyId) } } },
    // });
    // const companies = useMemo(() => companiesQuery.data || [], [companiesQuery.data]);

    const dealRows: Deal[] = useMemo(() => {
        return [
            {
                companyId: 1,
                companyName: "ABC Corp",
                createdAt: "2023-01-01",
                status: "Active",
                notes: [],
                totalProfitShare: 30,
                usersProfitShare: 30,
                team: [],
            },
            {
                companyId: 2,
                companyName: "XYZ Corp",
                createdAt: "2024-01-01",
                status: "Active",
                notes: [
                    {
                        id: 1,
                        content:
                            "This is a note. This is a note. This is a note. This is a note. This is a note. This is a note. This is a note. This is a note. This is a note. This is a note. This is a note. This is a note. This is a note. This is a note. This is a note. This is a note. ",
                        author: "Jason",
                        createdAt: "2024-01-01",
                    },
                    {
                        id: 2,
                        content:
                            "This is also a note. This is also a note. This is also a note. This is also a note. This is also a note. This is also a note. This is also a note. This is also a note. This is also a note. This is also a note. This is also a note. This is also a note. This is also a note. This is also a note. This is also a note. This is also a note. ",
                        author: "Nicol",
                        createdAt: "2024-01-02",
                    },
                ],
                totalProfitShare: 30,
                usersProfitShare: 25,
                team: [{ id: 1, name: "John Doe", role: "Referrer", profitShare: 5 }],
            },
        ];
    }, []);

    const teamRows: TeamMember[] = useMemo(() => {
        return [
            {
                userId: "1",
                name: "John Doe",
                emailAddress: "john@example.com",
                phoneNumber: "123-456-7890",
                deals: [],
                commissionEarned: 0,
            },
            {
                userId: "2",
                name: "Jane Mackay",
                emailAddress: "jane@example.com",
                phoneNumber: "123-456-9999",
                deals: [
                    {
                        companyId: 1,
                        companyName: "ABC Corp",
                        createdAt: "2024-01-01",
                        status: "Active",
                        notes: [{ id: 1, content: "This is a note", author: "Jason", createdAt: "2024-01-01" }],
                        usersProfitShare: 30,
                    },
                    {
                        companyId: 1,
                        companyName: "XYZ Corp",
                        createdAt: "2024-01-01",
                        status: "Active",
                        notes: [{ id: 1, content: "This is a note", author: "Jason", createdAt: "2024-01-01" }],
                        usersProfitShare: 5,
                    },
                ],
                commissionEarned: 35,
            },
        ];
    }, []);

    const billingRows: BillingRow[] = useMemo(() => {
        return [
            {
                companyId: 1,
                companyName: "ABC Corp",
                createdAt: "2024-02-01",
                dealStatus: "Active",
                details: "Payment pending",
                usersProfitShare: 30,
            },
            {
                companyId: 2,
                companyName: "XYZ Corp",
                createdAt: "2024-01-22",
                dealStatus: "Closing",
                details: "Payment processing",
                usersProfitShare: 30,
            },
            {
                companyId: 3,
                companyName: "Co Corp",
                createdAt: "2024-01-01",
                dealStatus: "Complete",
                details: "Payment processed",
                usersProfitShare: 30,
            },
            {
                companyId: 4,
                companyName: "My company",
                createdAt: "2024-01-04",
                dealStatus: "Complete",
                details: "Payment processed",
                usersProfitShare: 15,
            },
        ];
    }, []);

    const location = useLocation();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const isAffiliateOnboarding = params.get("affiliateOnboarding") === "true";
        setShowContractModal(isAffiliateOnboarding);
    }, [location]);

    return (
        <>
            {showContractModal && <SignContractsModal onClose={() => setShowContractModal(false)} />}
            <div className="bg-yellow-100 border-[1px] border-yellow-400 p-2 rounded-md my-2">
                Your access is restricted until you complete the required Affiliate Training. Please schedule your
                training <a className="underline">here</a>.
            </div>
            {activeTab === "Billing & History" && (
                <div className="bg-yellow-100 border-[1px] border-yellow-400 p-2 rounded-md my-2">
                    We cannot pay your commissions until you have completed your W9 form. Click{" "}
                    <a className="underline">here</a> to complete now.
                </div>
            )}

            <AffiliateTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            {activeTab === "Deals" ? (
                <DealsSection dealRows={dealRows} />
            ) : activeTab === "Team" ? (
                <TeamSection teamRows={teamRows} />
            ) : (
                <BillingAndHistorySection billingRows={billingRows} />
            )}
        </>
    );
};
