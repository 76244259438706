import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";

export const affiliateContractSchema = z.object({
    id: z.string(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date().nullish(),
    userId: z.string(),
    clearErcUserId: z.string().nullish(),
    clearErcUserEmailedSignedContractAt: z.coerce.date().nullish(),
    userEmailedSignedContractAt: z.coerce.date().nullish(),
    userSignedContractAt: z.coerce.date().nullish(),
    contract: z.string(),
});

export const getAffiliateContract = async ({ authToken, userId }: { authToken?: string; userId: string }) => {
    const response = await makeRequest({
        method: "get",
        path: "/affiliate-contracts?userId=" + userId,
        authToken,
    });

    if (!response.data) return null;
    const affiliateContract = parseSchema(affiliateContractSchema, response.data);

    return affiliateContract;
};
