import { useState } from "react";
import { Currency } from "../../../../../billing/components";
import ButtonNeoGen from "../../../../../layout/button-neogen";
import SearchField from "../../../../../layout/search-field";
import { TBody, THead, Table, Td, Tr } from "../../../../../layout/table";
import { DealsModal } from "./dealsModal";
import { TeamMember } from "../domain/team";

export const TeamSection = ({ teamRows }: { teamRows: TeamMember[] }) => {
    const [showDealsModalForUserId, setShowDealsModalForUserId] = useState<string | undefined>(undefined);

    return (
        <>
            {showDealsModalForUserId && (
                <DealsModal
                    userId={showDealsModalForUserId}
                    userName={teamRows.find((row: any) => row.userId === showDealsModalForUserId)?.name}
                    setShowDealsModalForUserId={setShowDealsModalForUserId}
                    deals={teamRows.find((row: any) => row.userId === showDealsModalForUserId)?.deals || []}
                />
            )}
            <div className="flex justify-end gap-2 mb-4">
                <SearchField
                    placeholder="Search Team Members"
                    search=""
                    setSearch={() => {
                        console.log("search");
                    }}
                />
                <ButtonNeoGen>Add Team Member</ButtonNeoGen>
            </div>
            <Table>
                <THead>
                    <Tr>
                        <Td>Name</Td>
                        <Td>Email Address</Td>
                        <Td>Phone Number</Td>
                        <Td>No. Deals</Td>
                        <Td>Commission Earned</Td>
                        <Td></Td>
                    </Tr>
                </THead>
                <TBody>
                    {teamRows?.map((row: any) => (
                        <Tr key={row.userId}>
                            <Td>{row.name}</Td>
                            <Td>{row.emailAddress}</Td>
                            <Td>{row.phoneNumber}</Td>
                            <Td>
                                {row.deals.length > 0 ? (
                                    <button
                                        onClick={() => setShowDealsModalForUserId(row.userId)}
                                        className="text-indigo-800 underline font-bold"
                                    >
                                        {row.deals.length} {row.deals.length > 1 ? "Deals" : "Deal"}
                                    </button>
                                ) : (
                                    0
                                )}
                            </Td>
                            <Td>
                                <Currency amount={row.commissionEarned} />
                            </Td>
                            <Td>
                                <ButtonNeoGen>Edit</ButtonNeoGen>
                            </Td>
                        </Tr>
                    ))}
                </TBody>
            </Table>
        </>
    );
};
