import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import { RoleGroup } from "../../../role-groups/domain/role-group";
import KanbanBoard from "../../../layout/components/KanbanBoard";

export const AgentContent = ({
    user,
}: {
    user: ClearERCUser;
    darkMode: boolean;
    users: ClearERCUser[] | undefined;
    usersQueryIsSuccess: boolean;
    usersQueryLoading: boolean;
    activeRole: undefined | null | RoleGroup;
}) => {
    return (
        <>
            <KanbanBoard userId={user.id} />
        </>
    );
};
