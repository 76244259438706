import { Dispatch, SetStateAction } from "react";
import ModalDialog from "../../../../../layout/modal-dialog";
import { Deal, DealNotes } from "../domain/deal";
import { DateDisplay } from "../../../../../layout/date-display";
import { TBody, THead, Table, Td, Tr } from "../../../../../layout/table";
import { Currency } from "../../../../../billing/components";

export const DealsModal = ({
    userId,
    userName,
    setShowDealsModalForUserId,
    deals,
}: {
    userId: string;
    userName: string | undefined;
    setShowDealsModalForUserId: Dispatch<SetStateAction<string | undefined>>;
    deals: Deal[] | undefined;
}) => {
    return (
        <ModalDialog
            title={`Deals for ${userName}`}
            show={true}
            showCancel={false}
            showOk={false}
            close={() => setShowDealsModalForUserId(undefined)}
        >
            <Table>
                <THead>
                    <Tr>
                        <Td>Company Name</Td>
                        <Td>Created At</Td>
                        <Td>Status</Td>
                        <Td>Profit Share</Td>
                    </Tr>
                </THead>
                <TBody>
                    {deals?.map((deal) => (
                        <Tr key={deal.companyId}>
                            <Td>{deal.companyName}</Td>
                            <Td>
                                <DateDisplay date={new Date(deal.createdAt)} />
                            </Td>
                            <Td>{deal.status}</Td>
                            <Td>
                                <Currency amount={deal.usersProfitShare || 0} />
                            </Td>
                        </Tr>
                    ))}
                </TBody>
            </Table>
        </ModalDialog>
    );
};
