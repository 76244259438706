import ActionsPanel from "../../../layout/actions-panel";
import PageDescription from "../../../layout/page-description";

import {
    AcademicCapIcon,
    BanknotesIcon,
    CheckBadgeIcon,
    ClockIcon,
    ReceiptRefundIcon,
    UsersIcon,
} from "@heroicons/react/24/outline";
import PushToRemote from "./modals/push-to-remote";
import { useEffect, useState } from "react";
import packageJson from "../../../../package.json";
import * as Sentry from "@sentry/react";
const actions = [
    {
        name: "Appointments",
        description: "Gives you an overview of all appointments",
        bg: "bg-yellow-100 bordwer border-yellow-500",
        // action: (id: number) => {
        //     alert(`${packageJson.name} ${packageJson.version}`);
        //     console.log("Sentry", Sentry);
        // },
        href: "/admin/appointments",
        id: 2222221,

        icon: "fas fa-calendar fa-arrow",
        iconForeground: "text-yellow-700 dark:text-yellow-300",
        iconBackground: "bg-yellow-100 border border-yellow-500 bg-yellow-50 dark:bg-yellow-900",
    },
    {
        name: "My Appointments",
        description: "Gives you an overview of all appointments",
        bg: "bg-yellow-100 bordwer border-yellow-500",
        // action: (id: number) => {
        //     alert(`${packageJson.name} ${packageJson.version}`);
        //     console.log("Sentry", Sentry);
        // },
        href: "/my-appointments",
        id: 22222221,

        icon: "fas fa-calendar fa-arrow",
        iconForeground: "text-yellow-700 dark:text-yellow-300",
        iconBackground: "bg-yellow-100 border border-yellow-500 bg-yellow-50 dark:bg-yellow-900",
    },
    {
        name: "Push customer to Monday",
        description: "Lets you select a customer and push them to Monday.",
        bg: "bg-teal-100 bordwer border-teal-500",
        action: (id: number) => {
            console.log("Push customer to Monda2y: " + id);
        },
        id: 1,

        icon: "fas fa-calendar fa-arrow",
        iconForeground: "text-teal-700 dark:text-teal-300",
        iconBackground: "bg-teal-100 border border-teal-500 bg-teal-50 dark:bg-teal-900",
    },
    {
        name: "Push customer to Slack",
        description: "Lets you select a customer and push them to Slack.",
        bg: "bg-teal-500",
        id: 2,
        action: (id: number) => {
            console.log("Push customer to Monday: " + id);
        },
        icon: "fa-brands fa-slack",
        iconForeground: "text-indigo-700 dark:text-indigo-300",
        iconBackground: "bg-indigo-100 border border-indigo-500  dark:bg-indigo-900",
    },
    {
        name: "Manage menus",
        description: "Lets you manage the menus.",
        bg: "bg-orange-500",
        id: 3,
        href: "/menu-admin",
        icon: "fas fa-bars",
        iconForeground: "text-orange-700 dark:text-orange-300",
        iconBackground: "bg-orange-100 border border-orange-500  dark:bg-orange-900",
    },
    {
        name: "Compare deal statuses with Monday",
        description: "Lets you compare the deal statuses in Monday with the deal statuses in the system.",
        bg: "bg-red-500",
        id: 4,
        href: "/admin/tools/compare-deals-monday",
        icon: "fas fa-handshake",
        iconForeground: "text-red-700 dark:text-red-300",
        iconBackground: "bg-red-100 border border-red-500  dark:bg-red-900",
    },
    {
        name: "Fix Missing Processflow Progress",
        description: "Allows you to compare multiple processflows and fix missing progress.",
        bg: "bg-blue-500",
        id: 8,
        href: "/admin/tools/fix-progress",
        icon: "fas fa-bug",
        iconForeground: "text-blue-700 dark:text-blue-300",
        iconBackground: "bg-blue-100 border border-blue-500  dark:bg-blue-900",
    },
    {
        name: "Get my auth token",
        description: "Retrieve your auth token for storybook",
        bg: "bg-blue-500",
        id: 9,
        href: "/admin/tools/get-auth-token",
        icon: "fas fa-code",
        iconForeground: "text-blue-700 dark:text-blue-300",
        iconBackground: "bg-blue-100 border border-blue-500  dark:bg-blue-900",
    },
    {
        name: "Stages",
        description: "Show and edit stages",
        bg: "bg-blue-500",
        id: 10,
        href: "/admin/stages",
        icon: "fas fa-boxes-stacked",
        iconForeground: "text-blue-700 dark:text-blue-300",
        iconBackground: "bg-blue-100 border border-blue-500  dark:bg-blue-900",
    },
    {
        name: "Mandates",
        description: "Upload and manage mandates",
        bg: "bg-green-500",
        id: 11,
        href: "/admin/mandates",
        icon: "fas fa-file-lines",
        iconForeground: "text-green-700 dark:text-green-300",
        iconBackground: "bg-green-100 border border-green-500  dark:bg-green-900",
    },
    {
        name: "Industries",
        description: "Manage industries",
        bg: "bg-red-500",
        id: 12,
        href: "/admin/industries",
        icon: "fas fa-building",
        iconForeground: "text-red-700 dark:text-red-300",
        iconBackground: "bg-red-100 border border-red-500  dark:bg-red-900",
    },
    {
        name: "Delete Processflow Progress",
        description: "Manage industries",
        bg: "bg-red-500",
        id: 13,
        href: "/admin/delete-pp",
        icon: "fal fa-trash",
        iconForeground: "text-yellow-700 dark:text-yellow-300",
        iconBackground: "bg-yellow-100 border border-yellow-500  dark:bg-yellow-900",
    },
    {
        name: "Date Range Picker Test",
        description: "Test the date range picker",
        bg: "bg-red-500",
        id: 14,
        href: "/admin/daterange-picker-test",
        icon: "fal fa-calendar",
        iconForeground: "text-blue-700 dark:text-blue-300",
        iconBackground: "bg-blue-100 border border-blue-500  dark:bg-blue-900",
    },
    {
        name: "Table Tester",
        description: "Test the tables",
        bg: "bg-blue-500",
        id: 15,
        href: "/admin/tables",
        icon: "fas fa-table-cells",
        iconForeground: "text-blue-700 dark:text-blue-300",
        iconBackground: "bg-blue-100 border border-blue-500  dark:bg-blue-900",
    },
    {
        name: "ERC Repair",
        description: "Kanban board for ERC repair",
        bg: "bg-red-500",
        id: 16,
        href: "/kanban",
        icon: "fal fa-table-columns",
        iconForeground: "text-yellow-700 dark:text-yellow-300",
        iconBackground: "bg-yellow-100 border border-yellow-500  dark:bg-yellow-900",
    },
    {
        name: "Line Items",
        description: "Billing Line Items",
        bg: "bg-green-500",
        id: 17,
        href: "/line-items",
        icon: "fal fa-bars",
        iconForeground: "text-green-700 dark:text-green-300",
        iconBackground: "bg-green-100 border border-green-500  dark:bg-green-900",
    },
    {
        name: "Transactions",
        description: "Transactions",
        bg: "bg-red-500",
        id: 18,
        href: "/transactions",
        icon: "fal fa-cart-shopping",
        iconForeground: "text-red-700 dark:text-red-300",
        iconBackground: "bg-red-100 border border-red-500  dark:bg-red-900",
    },
    {
        name: "Advertising",
        description: "Advertising",
        bg: "bg-blue-500",
        id: 17,
        href: "/advertising",
        icon: "fal fa-newspaper",
        iconForeground: "text-blue-700 dark:text-blue-300",
        iconBackground: "bg-blue-100 border border-blue-500  dark:bg-blue-900",
    },
    {
        name: "Paymaster",
        description: "Affiliate Paymaster Dash",
        bg: "bg-green-500",
        id: 17,
        href: "/paymaster",
        icon: "fal fa-receipt",
        iconForeground: "text-green-700 dark:text-green-300",
        iconBackground: "bg-green-100 border border-green-500  dark:bg-green-900",
    },
    {
        name: "Bullet Points",
        description: "Section to Manage Bullet Points for Customer Files",
        bg: "bg-yellow-500",
        id: 19,
        href: "/admin/bullet-points",
        icon: "fal fa-circle-dot",
        iconForeground: "text-yellow-700 dark:text-yellow-300",
        iconBackground: "bg-yellow-100 border border-yellow-500  dark:bg-yellow-900",
    },
    {
        name: "Provider Dashboard",
        description: "A dashboard for providers to manage their customers and check on the status of them",
        bg: "bg-blue-500",
        id: 20,
        href: "/providers",
        icon: "fal fa-gauge-high",
        iconForeground: "text-blue-700 dark:text-blue-300",
        iconBackground: "bg-blue-100 border border-blue-500  dark:bg-blue-900",
    },
    {
        name: "Temp Role Group Tester",
        description: "Allows adding and removing temporary role groups",
        bg: "bg-green-500",
        id: 21,
        href: "/temp-role-group-tester",
        icon: "fas fa-list-check",
        iconForeground: "text-green-700 dark:text-green-300",
        iconBackground: "bg-green-100 border border-green-500  dark:bg-green-900",
    },
    {
        name: "Leads",
        description: "A section showing leads that have not yet been converted to customers",
        bg: "bg-red-500",
        id: 22,
        href: "/admin/leads",
        icon: "fas fa-list-check",
        iconForeground: "text-red-700 dark:text-red-300",
        iconBackground: "bg-red-100 border border-red-500  dark:bg-red-900",
    },
    {
        name: "PCMP Census",
        description: "PCMP Census Test",
        bg: "bg-red-500",
        id: 23,
        href: "/admin/pcmp",
        icon: "fas fa-list-check",
        iconForeground: "text-red-700 dark:text-red-300",
        iconBackground: "bg-red-100 border border-red-500  dark:bg-red-900",
    },
    {
        name: "PCMP Short Census",
        description: "PCMP Short Census Test",
        bg: "bg-red-500",
        id: 24,
        href: "/admin/pcmp-short",
        icon: "fas fa-list-check",
        iconForeground: "text-red-700 dark:text-red-300",
        iconBackground: "bg-red-100 border border-red-500  dark:bg-red-900",
    },
    {
        name: "PCMP POC",
        description: "PCMP Finch Integration Proof Of Concept",
        bg: "bg-red-500",
        id: 25,
        href: "/poc",
        icon: "fas fa-list-check",
        iconForeground: "text-red-700 dark:text-red-300",
        iconBackground: "bg-red-100 border border-red-500  dark:bg-red-900",
    },
    {
        name: "Font Test",
        description: "Test signature fonts",
        bg: "bg-red-500",
        id: 26,
        href: "/font-test",
        icon: "fas fa-list-check",
        iconForeground: "text-red-700 dark:text-red-300",
        iconBackground: "bg-red-100 border border-red-500  dark:bg-red-900",
    },
    {
        name: "PCMP Dash Test",
        description: "Test PCMP Dash",
        bg: "bg-red-500",
        id: 27,
        href: "/pcmp-dash",
        icon: "fas fa-list-check",
        iconForeground: "text-red-700 dark:text-red-300",
        iconBackground: "bg-red-100 border border-red-500  dark:bg-red-900",
    },
];

// function classNames(...classes: any[]) {
// return classes.filter(Boolean).join(" ");
// }

export default function AdminTools() {
    const [show, setShow] = useState(false);
    const [type, setType] = useState<"slack" | "monday">("slack");
    useEffect(() => {
        actions.forEach(function (part, index, actions) {
            if (actions[index]["id"] === 2) {
                //push to slack

                actions[index]["action"] = () => {
                    // alert("hoi");
                    setType("slack");
                    setShow(true);
                };
            }

            if (actions[index]["id"] === 1) {
                //push to Monday

                actions[index]["action"] = () => {
                    // alert("hoi");
                    setType("monday");
                    setShow(true);
                };
            }
        }, actions as any); // use arr as this
    }, []);
    return (
        <>
            <PageDescription
                title="Admin Tools"
                description="Tools for managing the site and its integration with 3rd party sites"
            />
            <ActionsPanel actions={actions} />
            {/* {show ? "Is Showing" : "Not Showing"} */}
            {/* {type} */}
            <PushToRemote close={() => setShow(false)} show={show} type={type} />
        </>
    );
}
