export default function namers(stage: string, output: string) {
    // global tagweight;
    let title = "";
    let color = "";
    let order = 0;

    switch (stage) {
        case "Doc Collection in Progress":
            color = "indigo";
            order = 28;
            break;

        case "Doc Collection Rescheduled":
            color = "blue";
            order = 29;
            break;

        case "Doc Collection Scheduled":
            color = "sky";
            order = 30;
            break;

        case "Doc Collection Complete":
            color = "orange";
            order = 19;
            break;

        case "Deal needs saving":
        case "Deal Needs Saving":
            color = "amber";
            order = 8;
            break;

        case "Needs Substantiation":
            color = "orange";
            order = 20;
            break;

        case "With Tax Attorney":
            color = "orange";
            order = 3;
            break;

        case "Taxes Need to be Filed":
            color = "lime";
            order = 6;
            break;

        case "Ready for CPA":
            color = "orange";
            order = 6;
            break;

        case "Sent to CPA":
            color = "orange";
            order = 5;
            break;

        case "CPA comp Working on contracts":
        case "CPA in progress":
            color = "orange";
            order = 4;
            break;

        case "Deal Completed":
            color = "green";
            order = 0;
            break;

        case "Follow Up":
            color = "cyan";
            order = 50;
            break;

        case "Dead Lead":
            color = "slate";
            order = 99;
            break;

        case "Old Lead":
            color = "gray";
            order = 91;
            break;

        case "Unqualified":
            color = "purple";
            order = 90;
            break;

        case "Contact Attempted":
            color = "yellow";
            order = 78;
            break;

        case "Appointment Scheduled (Closer)":
            color = "yellow";
            order = 77;
            break;

        case "Appointment Rescheduled (Closer)":
            color = "yellow";
            order = 76;
            break;

        case "Replaced Bad Lead":
            color = "green";
            order = 79;
            break;

        case "New Lead":
            color = "lime";
            order = 80;
            break;
        case "":
            color = "slate";
            order = 81;
            break;

        default:
            title = "Unknown: " + stage;
            color = "red";
            order = 82;
            break;
    }
    // return { title, color, order };

    if (output == "order") return order;

    if (output == "title") return title;

    if (output == "color") return color;

    if (output == "sql") {
        // create an update statement for the table to add color
        return "UPDATE `client_statuses` SET `color` = '" + "x" + "' WHERE `client_statuses`.`id` = " + stage + ";";
    }
}
