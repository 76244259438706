import APIService from "./api.service";

export type AppointmentPayload = {
    uid: string;
    team: {
        name: string;
        members: any[];
    };
    type: string;
    price: number | null;
    title: string;
    length: number;
    status: string;
    endTime: string;
    iCalUID: string;
    currency: string;
    location: string;
    metadata?: {
        videoCallUrl?: string;
    };
    attendees: {
        name: string;
        email: string;
        language: {
            locale: string;
        };
        timeZone: string;
        utcOffset: number;
    }[];
    bookingId: number;
    organizer: {
        id: number;
        name: string;
        email: string;
        language: {
            locale: string;
        };
        timeZone: string;
        username: string;
        utcOffset: number;
        timeFormat: string;
    };
    responses: {
        name: {
            label: string;
            value: string;
        };
        email: {
            label: string;
            value: string;
        };
        guests: {
            label: string;
            value: any[];
        };
        location: {
            label: string;
            value: {
                value: string;
                optionValue: string;
            };
        };
    };
    startTime: string;
    eventTitle: string;
    cancelledBy: string;
    eventTypeId: number;
    customInputs: Record<string, any>;
    iCalSequence: number;
    eventDescription: string | null;
    seatsPerTimeSlot: number | null;
    cancellationReason: string;
    seatsShowAttendees: boolean | null;
    destinationCalendar: {
        id: number;
        userId: number;
        externalId: string;
        eventTypeId: number | null;
        integration: string;
        credentialId: number;
        primaryEmail: string;
    }[];
    userFieldsResponses: Record<string, any>;
    requiresConfirmation: boolean | null;
    rescheduleId?: number;
};

export type Appointment = {
    id?: number;
    createdAt: Date;
    destinationCalendarEmail?: string;
    email?: string;
    payload: AppointmentPayload;
    triggerEvent: string;
    startsAt: Date;
    endsAt: Date;
    bookingId: number;
};

class AppointmentService extends APIService<Appointment> {
    constructor() {
        super("/appointments");
    }
}

export default new AppointmentService();
