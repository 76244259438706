import { useState } from "react";

const CaratTop = () => (
    <svg width="20" height="10" viewBox="0 0 234 98" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M113.5 98L233.913 0.5H0.0864258L113.5 98Z" fill="#333" />
    </svg>
);

const CaratBottom = () => (
    <svg width="20" height="10" viewBox="0 0 234 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M116 0L233.913 69.5H0.0864258L116 0Z" fill="#333" />
    </svg>
);

export const Tooltip = ({ body }: { body: string }) => {
    const [isHover, setIsHover] = useState(false);
    return (
        <div className="relative">
            {isHover && (
                <div className="absolute top-6 -left-8 z-50">
                    <div className="relative ml-8">
                        <CaratBottom />
                    </div>
                    <div className="relative bg-[#333] -mt-1 w-[12rem] h-[auto] rounded-lg p-2">
                        <div className="whitespace-normal text-xxxs text-white">{body}</div>
                    </div>
                </div>
            )}
            <div
                onMouseOver={() => setIsHover(true)}
                onMouseOut={() => setIsHover(false)}
                className="bg-purple-800 text-white text-xs rounded-full px-[0.3rem]  cursor-pointer"
            >
                ?
            </div>
        </div>
    );
};
