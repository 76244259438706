import { z } from "zod";
import { makeRequest } from "../../jason-proof-of-concept/shared/utils";
import { paymentSchema } from "../domain/payment";
import { getPayment } from "./get-payment";

const updatePaymentSchema = paymentSchema.omit({ id: true, createdAt: true, updatedAt: true }).partial();

export type UpdatePaymentData = z.infer<typeof updatePaymentSchema>;

export const updatePayment = async ({
    authToken,
    id,
    data,
}: {
    authToken: string;
    id: string;
    data: UpdatePaymentData;
}) => {
    await makeRequest({
        method: "patch",
        path: `/payments/${id}`,
        authToken,
        data,
    });
    const payment = await getPayment({ authToken, id });
    return payment;
};
