import React from "react";
import SelectNeoGen from "../../../layout/select-neogen";
import { Input } from "@headlessui/react";
import InputControlled from "../../../layout/input-controlled";
import ButtonNeoGen from "../../../layout/button-neogen";

const signatures = [
    { name: "Signature 1", id: "sig1" },
    { name: "Signature 2", id: "sig2" },
    { name: "Signature 3", id: "sig3" },
    { name: "Signature 4", id: "sig4" },
    { name: "Signature 5", id: "sig5" },
    { name: "Signature 6", id: "sig6" },
    { name: "Signature 7", id: "sig7" },
    { name: "Signature 8", id: "sig8" },
    { name: "Signature 9", id: "sig9" },
    { name: "Signature 10", id: "sig10" },
];

function FontTest() {
    const [font, setFont] = React.useState("sig1");
    const [input, setInput] = React.useState("John Smith");
    return (
        <div>
            {/* <SelectNeoGen
                options={[
                    { name: "Signature 1", id: "sig1" },
                    { name: "Signature 2", id: "sig2" },
                    { name: "Signature 3", id: "sig3" },
                    { name: "Signature 4", id: "sig4" },
                    { name: "Signature 5", id: "sig5" },
                    { name: "Signature 6", id: "sig6" },
                    { name: "Signature 7", id: "sig7" },
                    { name: "Signature 8", id: "sig8" },
                    { name: "Signature 9", id: "sig9" },
                    { name: "Signature 10", id: "sig10" },
                ]}
                value={font}
                onChange={(value) => setFont(value.toString())}
            /> */}
            <div className="grid grid-cols-5 gap-5 text-center">
                {signatures.map((sig) => (
                    <ButtonNeoGen key={sig.id} onClick={() => setFont(sig.id)} className="w-48">
                        <span style={{ fontFamily: sig.id }}>{input}</span>
                    </ButtonNeoGen>
                ))}
            </div>

            <InputControlled value={input} onChange={(e) => setInput(e)} />
            <div style={{ fontFamily: font }} className="text-4xl">
                {input}
            </div>
        </div>
    );
}

export default FontTest;
