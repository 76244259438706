import { useState } from "react";
import { Currency } from "../../../../../billing/components";
import ButtonNeoGen from "../../../../../layout/button-neogen";
import { DateDisplay } from "../../../../../layout/date-display";
import OptionsDropDown from "../../../../../layout/options-dropdown";
import SearchField from "../../../../../layout/search-field";
import { TBody, THead, Table, Td, Tr } from "../../../../../layout/table";
import { Deal } from "../domain/deal";
import { NotesModal } from "./notesModal";
import { TeamModal } from "./teamModal";

export const DealsSection = ({ dealRows }: { dealRows: Deal[] }) => {
    const [showTeamModalForCompanyId, setShowTeamModalForCompanyId] = useState<number | undefined>(undefined);
    const [showNotesModalForCompanyId, setShowNotesModalForCompanyId] = useState<number | undefined>(undefined);
    return (
        <>
            {showNotesModalForCompanyId && (
                <NotesModal
                    companyName={dealRows.find((row) => row.companyId === showNotesModalForCompanyId)?.companyName}
                    companyId={showNotesModalForCompanyId}
                    setShowNotesModalForCompanyId={setShowNotesModalForCompanyId}
                    notes={dealRows.find((row) => row.companyId === showNotesModalForCompanyId)?.notes}
                />
            )}
            {showTeamModalForCompanyId && (
                <TeamModal
                    companyName={dealRows.find((row) => row.companyId === showTeamModalForCompanyId)?.companyName}
                    companyId={showTeamModalForCompanyId}
                    setShowTeamModalForCompanyId={setShowTeamModalForCompanyId}
                    team={dealRows.find((row) => row.companyId === showTeamModalForCompanyId)?.team}
                />
            )}
            <div className="flex justify-end gap-2 mb-4">
                <SearchField
                    placeholder="Search Deals"
                    search=""
                    setSearch={() => {
                        console.log("search");
                    }}
                />
                <ButtonNeoGen>New Deal</ButtonNeoGen>
            </div>
            <Table>
                <THead>
                    <Tr>
                        <Td>Company</Td>
                        <Td>Created At</Td>
                        <Td>Status</Td>
                        <Td>Notes </Td>
                        <Td>Profit Share</Td>
                        <Td>Team</Td>
                        <Td></Td>
                    </Tr>
                </THead>
                <TBody>
                    {dealRows?.map((row) => (
                        <Tr key={row.companyId}>
                            <Td>{row.companyName}</Td>
                            <Td>
                                <DateDisplay date={new Date(row.createdAt)} />
                            </Td>
                            <Td>{row.status}</Td>
                            <Td>
                                {row.notes && row.notes.length > 0 ? (
                                    <ButtonNeoGen onClick={() => setShowNotesModalForCompanyId(row.companyId)}>
                                        View
                                    </ButtonNeoGen>
                                ) : (
                                    "No notes"
                                )}
                            </Td>
                            <Td>
                                <Currency amount={row.usersProfitShare || 0} />
                                {(row.usersProfitShare || 0) < (row.totalProfitShare || 0) && (
                                    <div className="text-xs text-gray-500">
                                        <div className="text-gray-500">
                                            Total profit share: <Currency amount={row.totalProfitShare || 0} />
                                        </div>

                                        <div className="text-gray-500">
                                            My team&apos;s share:{" "}
                                            <Currency
                                                amount={(row.totalProfitShare || 0) - (row.usersProfitShare || 0)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Td>
                            <Td>
                                {row.team && row.team.length > 0 ? (
                                    <ButtonNeoGen onClick={() => setShowTeamModalForCompanyId(row.companyId)}>
                                        View
                                    </ButtonNeoGen>
                                ) : (
                                    "No team"
                                )}
                            </Td>
                            <Td>
                                <div>
                                    <OptionsDropDown
                                        options={[
                                            {
                                                label: "Add Customer",
                                                action: () => {
                                                    console.log("Add Customer");
                                                },
                                            },
                                            {
                                                label: "Edit Deal",
                                                action: () => {
                                                    console.log("Edit Deal");
                                                },
                                            },
                                            {
                                                label: "Fill Processflow",
                                                action: () => {
                                                    console.log("Fill Processflow");
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </Td>
                        </Tr>
                    ))}
                </TBody>
            </Table>
        </>
    );
};
