import React, { useEffect, useState } from "react";
import ModalDialog from "../../../layout/modal-dialog";
import { Document, Page } from "react-pdf";
import ButtonNeoGen from "../../../layout/button-neogen";
import { getCompanies } from "../../../companies/actions/get-companies";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import invoiceLineItemsService from "../../../services/invoice-line-items.service";
import TaxPayerAdvocate from "./taxPayerAdvocate";
import Transcripts from "./transcripts";
import PrintPre from "../../../layout/print-pre";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import stagesService from "../../../services/stages.service";
import companyProductStagesService from "../../../services/company-product-stages.service";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import PageDescription from "../../../layout/page-description";
import { Files } from "../../../companies/components/files";
import { useCompany } from "../../../companies/hooks/use-company";
import { hr } from "date-fns/locale";
import { getProcessflowProgresses } from "../../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
const packages = [
    {
        title: "Free ERC Evaluation",
        icon: "fal fa-check-circle",
        active: false,
        bg: "green",
        filename: "/files/erc-document-bcf37d48-c39a-40f6-bf15-0e61a0369fb2.pdf",
        // lineItemIds: [25],
        position: { processflowStageId: 221, processflowId: 352 },
        tempRoleGroupID: 31,
        stage: 1,
    },

    {
        title: "ERC Track & Trace",
        icon: "fal fa-location-dot",
        active: false,
        bg: "red",
        filename: "/files/erc-document-fd79c853-14c0-4dcb-9b4b-f65e571aa7af.pdf",
        // lineItemIds: [26],
        activeItem: <Transcripts />,
        position: { processflowStageId: 237, processflowId: 566 },
        tempRoleGroupID: 46,
        stage: 9,
    },
    {
        title: "Tax Advocacy Service",
        icon: "fal fa-headset",
        active: false,
        bg: "yellow",
        // lineItemIds: [24, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37],
        filename: "/files/erc-document-b57e28a3-a0f0-478c-b6ce-a3eed86d56ca.pdf",
        activeItem: <TaxPayerAdvocate />,
        position: { processflowStageId: 216, processflowId: 506 },
        tempRoleGroupID: 40,
        stage: 2,
    },

    {
        title: "ERC Substantiation",
        icon: "fal fa-gavel",
        active: false,
        bg: "purple",
        filename: "/files//erc-document-f374011d-61a3-47b3-a05e-0bd76538c8cd.pdf",
        tempRoleGroupID: 44,
        position: { processflowStageId: 236, processflowId: 565 },
        stage: 10,
    },
    {
        title: "ERC New Application",
        icon: "fal fa-magnifying-glass",
        active: false,
        bg: "blue",
        filename: "/files/erc-document-a5d5c079-bfb2-4d5e-bbfb-fa3312729a07.jpeg",
        href: "/processflow-wizard/7",
        tempRoleGroupID: 6,
        position: { processflowStageId: 54, processflowId: 19 },
        stage: 3,
    },
    {
        title: "R&D Credits",
        icon: "fal fa-vial",
        active: false,
        bg: "slate",
        filename: "/files/erc-document-7fe1b086-05fc-4022-8273-37d0e825a327.pdf",
        // lineItemIds: [25],
        tempRoleGroupID: 47,
        position: { processflowStageId: 225, processflowId: 533 },
        stage: 4,
    },

    {
        title: "WOTC Credits",
        icon: "fal fa-handshake-angle",
        active: false,
        bg: "purple",
        filename: "/files/erc-document-166aacf2-3aeb-4050-96e8-e2df599bbf35.pdf",
        // lineItemIds: [26],
        // activeItem: <Transcripts />,
        tempRoleGroupID: 48,
        position: { processflowStageId: 238, processflowId: 567 },
        stage: 5,
    },
    {
        title: "Energy Credits",
        icon: "fal fa-bolt",
        active: false,
        bg: "blue",
        // lineItemIds: [24, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37],
        filename: "/files/erc-document-4a6b20de-48b7-4014-8789-160de91f59ef.pdf",
        // activeItem: <TaxPayerAdvocate />,
        tempRoleGroupID: 49,
        position: { processflowStageId: 239, processflowId: 568 },
        stage: 6,
    },

    {
        title: "Wellness Programs",
        icon: "fal fa-briefcase-medical",
        active: false,
        bg: "green",
        filename: "/files/erc-document-2a15e3d0-e6f4-444c-a9be-bf381bacc253.pdf",
        tempRoleGroupID: 50,
        position: { processflowStageId: 252, processflowId: 616 },
        stage: 7,
    },
    {
        title: "Cost Segregation",
        icon: "fal fa-industry",
        active: false,
        bg: "red",
        filename: "/files/erc-document-f3ec7371-ee47-4f04-8508-41c105dbaa86.jpeg",
        tempRoleGroupID: 51,
        position: { processflowStageId: 241, processflowId: 570 },
        stage: 8,
    },
    // {
    //     title: "Other Tax Credits",
    //     icon: "fal fa-money-check-dollar",
    //     active: false,
    //     bg: "red",
    // },
];
const tabs = [
    { name: "Program Details", href: "#", current: true },
    { name: "Application", href: "#", current: false },
    { name: "Missing Items", href: "#", current: false },
    { name: "Status", href: "#", current: false },
    { name: "Files", href: "#", current: false },
];
function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}
const FILE_API_URL = process.env.REACT_APP_FILE_SERVER ?? process.env.REACT_APP_API_URL ?? "https://api.clearerc.com/";
function Packages({ companyId }: { companyId: number }) {
    const [currentTab, setCurrentTab] = useState("Program Details");
    const [showFileUpload, setShowFileUpload] = React.useState(false);
    const [file, setFile] = React.useState("");
    const [active, setActive] = React.useState(false);
    const [activeItem, setActiveItem] = React.useState<any>();
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    // const [packages, setPackages] = useState<any[]>([]);
    const authToken = getAuthTokenNoThrow() || "no-token";
    const [user] = useRecoilState(userAtom);
    const queryCache = useQueryClient();
    const companyQuery = useCompany({ authToken, id: companyId });
    const company = companyQuery.data;

    const usersProgressQuery = useQuery(["processflowProgresses", { userId: user.id }], async () => {
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { group: 28 },
        });
        return { usersProgress };
    });

    const allStagesQuery = useQuery(["stages"], async () => {
        const response = await stagesService.getAll();
        return response?.data ?? [];
    });

    const myStagesQuery = useQuery(["companyProductStages", companyId], async () => {
        const response = await companyProductStagesService.getFilteredWhere({ companyId });
        return response?.data ?? [];
    });

    // useEffect(() => {
    // set current tab to the one that was clicked on

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
        setPageNumber(1);
    }
    // useEffect(() => {
    //     const companies = getCompanies({ authToken }).then(async (response) => {
    //         // console.log("packagesAvailable", packagesAvailable);
    //         // console.log({ response });
    //         // Go through each company
    //         // for (const company of response) {
    //         //     // Go through each package and check if the user has the line item
    //         //     // If they do, set the package to active
    //         //     // If they don't, set the package to inactive
    //         //     // If the package has no line items, set the package to inactive
    //         //     // console.log("Checking company id:" + company.id);
    //         //     for (let i = 0; i < packagesAvailable.length; i++) {
    //         //         const packageItem = packagesAvailable[i];
    //         //         packageItem.active = true;
    //         //         // if (packageItem.lineItemIds) {
    //         //         //     let found = false;
    //         //         //     for (const lineItemId of packageItem.lineItemIds) {
    //         //         //         // console.log("Checking line item id:" + lineItemId);
    //         //         //         if (await invoiceLineItemsService.checkForPresence(company.id, lineItemId)) {
    //         //         //             // console.log("Found line item id:" + lineItemId);
    //         //         //             found = true;
    //         //         //             break;
    //         //         //         }
    //         //         //     }
    //         //         //     packageItem.active = found;
    //         //         // } else {
    //         //         //     packageItem.active = false;
    //         //         // }
    //         //     }
    //         //     // console.log("packagesAvailable", packagesAvailable);
    // setPackages(packagesAvailable);
    //         // }
    //     });
    // }, [authToken]);
    const activePackage = packages.find((p) => p.title === activeItem?.title);
    const renderContent = () => {
        switch (currentTab) {
            case "Program Details":
                const stage = myStagesQuery.data?.find((s) => s.stageId === activePackage?.stage);
                if (!stage) {
                    companyProductStagesService
                        .create({
                            companyId,
                            stageId: activePackage?.stage,
                            lastUpdated: new Date(),
                            updatedByUser: user.id,
                        })
                        .then((response) => {
                            // console.log({ response });
                            queryCache.invalidateQueries(["companyProductStages", companyId]);
                        });
                }

                if (file === "") {
                    return <div className="m-10 text-center">Information coming soon</div>;
                }
                return (
                    <>
                        <div className="mt-5 lg:flex lg:flex-row items-center gap-2 w-full place-content-center ">
                            <ButtonNeoGen
                                disabled={pageNumber === 1}
                                onClick={() => {
                                    const pn = numPages && pageNumber > 1 ? pageNumber - 1 : pageNumber;
                                    setPageNumber(pn);
                                }}
                            >
                                Back
                            </ButtonNeoGen>
                            Page {pageNumber} of {numPages}
                            <ButtonNeoGen
                                disabled={pageNumber === numPages}
                                onClick={() => {
                                    const pn = numPages && pageNumber < numPages ? pageNumber + 1 : pageNumber;
                                    setPageNumber(pn);
                                }}
                            >
                                Next
                            </ButtonNeoGen>
                        </div>
                        <div className="mt-5 text-center">
                            {file.substring(file.length - 4) === ".pdf" ? (
                                <>
                                    <Document
                                        file={file}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        className={"text-center flex flex-col items-center"}
                                    >
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                </>
                            ) : (
                                <img src={file} alt="file" />
                            )}
                        </div>
                    </>
                );
                break;
            case "Application":
                const packageNow = packages.find((p) => p.title === activeItem.title);
                // console.log(currentTab.)
                if (packageNow?.position) {
                    // Get the customer's processflow progress ID

                    console.error("usersProgress", usersProgressQuery.data);
                    if (usersProgressQuery.data) {
                        const processflowId = usersProgressQuery.data?.usersProgress?.id;
                        window.localStorage.setItem(
                            "position-" + packageNow?.tempRoleGroupID,
                            JSON.stringify({ [processflowId]: packageNow?.position }),
                        );
                    }
                }
                window.localStorage.setItem("tempRoleGroup", packageNow?.tempRoleGroupID.toString() ?? "-1");
                if (packageNow?.href) {
                    window.location.href = packageNow?.href;
                } else {
                    window.location.href = "/processflow-wizard/28";
                }
                return (
                    <div className="mt-5">
                        <PageDescription
                            title="Application"
                            description="This section will load the application for this package"
                        />
                    </div>
                );
                break;
            case "Missing Items":
                return (
                    <div className="mt-5">
                        <PageDescription
                            title="Missing Items"
                            description="This section displays any missing items required to complete this package"
                        />
                    </div>
                );
                break;
            case "Status":
                return (
                    <div className="mt-5">
                        <PageDescription
                            title="Status"
                            description="This section displays the current stage of this package"
                        />
                        <div className="overflow-hidden rounded-lg bg-white dark:bg-gray-900 dark:border-2 dark:border-gray-700 px-4 py-5 shadow sm:p-6">
                            <dt className="truncate text-md font-medium text-gray-500 dark:text-indigo-400">
                                Current Status:
                            </dt>
                            <dd className="mt-1 text-3xl font-semibold tracking-wider text-indigo-700 dark:text-green-500">
                                {
                                    allStagesQuery.data?.find(
                                        (as) =>
                                            as.id ===
                                            myStagesQuery.data?.find((s) => s.stageId === activePackage?.stage)
                                                ?.stageId,
                                    )?.name
                                }
                            </dd>
                            <dt className="truncate text-md font-medium text-gray-500 dark:text-indigo-400">
                                {
                                    allStagesQuery.data?.find(
                                        (as) =>
                                            as.id ===
                                            myStagesQuery.data?.find((s) => s.stageId === activePackage?.stage)
                                                ?.stageId,
                                    )?.description
                                }
                            </dt>
                        </div>
                        {/* <PrintPre>{myStagesQuery.data?.find((s) => s.stageId === activePackage?.stage)}</PrintPre> */}
                        {/* <PrintPre>{allStagesQuery.data}</PrintPre> */}
                    </div>
                );
                break;
            case "Files":
                return (
                    <div className="mt-5">
                        <PageDescription
                            title="Files"
                            description="This section displays any files that are associated with this package"
                        />
                        <Files companyId={companyId} />
                    </div>
                );
                break;
        }
    };
    if (myStagesQuery.isLoading || allStagesQuery.isLoading || usersProgressQuery.isLoading)
        return <div>Loading...</div>;
    return (
        <div className="grid lg:grid-cols-10 gap-5 sm:grid-cols-1 md:grid-cols-2">
            {packages.map((packageItem) => {
                return (
                    <div key={packageItem.title} className="col-span-2">
                        {/* <div className="relative w-full ">
                            <div className="absolute top-2 right-2 h-10 w-20">
                                <div
                                    className={
                                        "bg-white dark:bg-gray-800 dark:text-gray-400 p-0 pt-1 rounded-full text-center " +
                                        (packageItem.active ? "border border-green-500" : "")
                                    }
                                >
                                    {packageItem.active && <i className="fas fa-check-circle text-green-600 " />}
                                    <span
                                        className={
                                            "text-xs " +
                                            (packageItem.active ? "ml-1" : "") +
                                            " inline-block align-text-top"
                                        }
                                    >
                                        {packageItem.active ? "Active" : "Available"}
                                    </span>
                                </div>
                            </div>
                        </div> */}
                        <div
                            className={
                                "text-gray-700 flex border p-5 bg-" +
                                packageItem.bg +
                                "-300 bg-gradient-to-br " +
                                " from-" +
                                packageItem.bg +
                                "-200 " +
                                " dark:from-" +
                                packageItem.bg +
                                "-900 " +
                                +"hover:bg-" +
                                packageItem.bg +
                                "-100 dark:bg-" +
                                packageItem.bg +
                                "-600 dark:hover:bg-" +
                                packageItem.bg +
                                "-800 border-white border-2 dark:border dark:border-gray-600 dark:text-gray-400 cursor-pointer text-center h-48 m-0 rounded-xl hover:shadow-none shadow-lg dark:shadow-sm hover:border-blue-500"
                            }
                            onClick={() => {
                                console.log("clicked");
                                // window.open(FILE_API_URL + packageItem.filename, "_blank");
                                setCurrentTab("");
                                setCurrentTab("Program Details");
                                tabs.map((tab) => {
                                    tab.current = false;
                                });
                                tabs[0].current = true;
                                setActiveItem(packageItem);
                                if (packageItem.filename) {
                                    setFile(FILE_API_URL + packageItem.filename);
                                    setActive(packageItem.active);
                                    setShowFileUpload(true);
                                } else {
                                    // alert("Information coming soon");
                                    setFile("");
                                    setActive(packageItem.active);
                                    setShowFileUpload(true);
                                }
                            }}
                        >
                            <div className="dark:text-gray-400 mt-auto card-body align-items-center d-flex justify-content-center flex-column my-auto text-center mx-auto ">
                                <div className={"flex-grow"}>
                                    <div className="mb-3 mt-8 my-auto text-5xl dark:text-green-400">
                                        <i className={"fa-thin " + packageItem.icon + " "} />
                                    </div>
                                </div>
                                <div className=" text-xl font-light dark:text-green-400 mt-5">{packageItem.title}</div>
                            </div>
                        </div>
                        {/* <RecordingButton title={"Free ERC Repair Evaluation"} icon={"fal fa-check-circle"} /> */}
                    </div>
                );
            })}
            {showFileUpload && (
                <ModalDialog
                    size={active ? "lg" : "xl"}
                    title={""}
                    close={() => setShowFileUpload(false)}
                    show={true}
                    showOk={false}
                    showCancel={false}
                >
                    {/* <PrintPre>{active}</PrintPre> */}
                    <div className="">
                        {active ? (
                            <>{activeItem}</>
                        ) : (
                            <>
                                <div>
                                    <div className="sm:hidden">
                                        <label htmlFor="tabs" className="sr-only">
                                            Select a tab
                                        </label>
                                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                        <select
                                            id="tabs"
                                            name="tabs"
                                            defaultValue={tabs?.find((tab) => tab.current)?.name}
                                            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            onChange={(e) => {
                                                const tab = tabs.find((tab) => tab.name === e.target.value);
                                                if (!tab) return;
                                                // alert("clicked on " + tab.name);
                                                for (const tabEach of tabs) {
                                                    tabEach.current = false;
                                                }
                                                tab.current = true;
                                                setCurrentTab(tab.name);
                                            }}
                                        >
                                            {tabs.map((tab) => (
                                                <option key={tab.name}>{tab.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="hidden sm:block">
                                        <div className="border-b border-gray-200">
                                            <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                                                {tabs.map((tab) => (
                                                    <button
                                                        key={tab.name}
                                                        // href={tab.href}
                                                        aria-current={tab.current ? "page" : undefined}
                                                        onClick={() => {
                                                            // alert("clicked on " + tab.name);
                                                            for (const tabEach of tabs) {
                                                                tabEach.current = false;
                                                            }
                                                            tab.current = true;
                                                            setCurrentTab(tab.name);
                                                        }}
                                                        className={classNames(
                                                            tab.current
                                                                ? "border-indigo-500 text-indigo-600"
                                                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                                            "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium",
                                                        )}
                                                    >
                                                        {tab.name}
                                                    </button>
                                                ))}
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                {renderContent()}
                            </>
                        )}
                    </div>
                </ModalDialog>
            )}
        </div>
    );
}

export default Packages;
